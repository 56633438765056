import { React, _, bind } from '../../Imports';
import { ILoginState, IUserManagementServiceInjectedProps, UserManagementService } from '../../state/UserManagementFreezer';
import { IForgotPasswordServiceInjectedProps, ForgotPasswordService } from '../../state/ForgotPasswordFreezer';
import { CreateUserRequest, UpdateUserRequest, AdminUserResponse } from '$Generated/api';
import {
    TableBody,
    TableCell,
    TableHead,
    Table,
    TableRow,
    TableContainer,
    TablePagination,
    TableSortLabel,
    Card,
    IconButton,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
} from 'MaterialUIComponents';
import { CreateUserDialog } from '$Components/UserManagement/CreateUserDialog';
import { EditUserDialog } from '$Components/UserManagement/EditUserDialog';
import { ConfirmDeleteDialog } from '$Components/UserManagement/ConfirmDeleteDialog';
import { ConfirmResetPasswordDialog } from '$Components/UserManagement/ConfirmResetPasswordDialog';
import { PageHeader } from '$Components/Shared/PageHeader';
import { FilterBar } from '$Components/UserManagement/FilterBar';
import * as InfiniteScroll from 'react-infinite-scroller';
import { Edit, CloseIcon, RefreshIcon } from 'MaterialUIIcons';
import { RolesEnum, VerifyRole } from 'externals/VerifyRole';

interface IUserManagementBaseProps {
    /*no props*/
}

type IUserManagementProps = IUserManagementBaseProps & IUserManagementServiceInjectedProps & IForgotPasswordServiceInjectedProps;

interface IUserManagementState {
    sort: string;
    sortOrder: 'asc' | 'desc';
    rowsPerPage: number;
    page: number;
    showCreateDialog: boolean;
    showEditDialog: boolean;
    showConfirmDeleteDialog: boolean;
    showConfirmResetPasswordDialog: boolean;
    editUser: AdminUserResponse;
    createUserError: string | undefined;
    hasError: boolean;
    usersList: AdminUserResponse[] | undefined;
}

const styles = require('./UserManagement.scss') as {
    main: string;
    card: string;
};

const rowHeaders = [
    {
        id: 'firstName',
        label: 'First Name',
    },
    {
        id: 'lastName',
        label: 'Last Name',
    },
    {
        id: 'email',
        label: 'Email Address',
    },
    {
        id: 'lastLogin',
        label: 'Last Login',
    },
    {
        id: 'actions',
        label: 'Actions',
    },
];

class _UserManagementPage extends React.Component<IUserManagementProps, IUserManagementState> {
    state: IUserManagementState = {
        sort: 'firstName',
        sortOrder: 'asc',
        rowsPerPage: 10,
        page: 0,
        showCreateDialog: false,
        showEditDialog: false,
        showConfirmDeleteDialog: false,
        showConfirmResetPasswordDialog: false,
        editUser: {
            id: '',
            userName: '',
            normalizedUserName: '',
            email: '',
            normalizedEmail: '',
            emailConfirmed: false,
            passwordHash: '',
            securityStamp: '',
            concurrencyStamp: '',
            phoneNumber: '',
            phoneNumberConfirmed: false,
            twoFactorEnabled: false,
            lockoutEnd: undefined,
            lockoutEnabled: false,
            accessFailedCount: 0,
            firstName: '',
            lastName: '',
            lastLogin: undefined,
            isActive: false,
            role: '',
        } as AdminUserResponse,
        createUserError: undefined,
        hasError: false,
        usersList: undefined,
    };

    async componentDidMount(): Promise<void> {
        await this.getUsersList();
    }

    @bind
    async getUsersList(): Promise<void> {
        await this.handleChangePage(null, 0);
        await this.props.userManagement.getUsers();
        this.setState({ usersList: undefined });
        const { getUserResult } = this.props.userManagement.getState();

        const propertySorter = (user: any): string => user[this.state.sort].toLowerCase();
        const orderedResults = _.orderBy(getUserResult.data, propertySorter, this.state.sortOrder);

        this.setState({
            usersList: orderedResults || [],
        });
    }

    handleRequestSort(field: string): void {
        const { sort, sortOrder } = this.state;
        const isAsc = sort === field && sortOrder === 'asc';
        this.setState({ sort: field, sortOrder: isAsc ? 'desc' : 'asc' });

        const propertySorter = (user: any): string => user[this.state.sort].toLowerCase();
        const orderedResults = _.orderBy(this.state.usersList, propertySorter, this.state.sortOrder);

        this.setState({
            usersList: orderedResults || [],
        });
    }

    @bind
    handleChangeRowsPerPage(event: any): void {
        this.setState({
            rowsPerPage: event.target.value,
            page: 0,
        });
    }

    @bind
    async handleChangePage(event: any, newPage: number): Promise<void> {
        this.setState({
            page: newPage,
        });
    }

    @bind
    toggleCreateUserDialog(): void {
        this.setState({
            showCreateDialog: !this.state.showCreateDialog,
            showConfirmDeleteDialog: false,
            showEditDialog: false,
            showConfirmResetPasswordDialog: false,
            editUser: {
                id: '',
                userName: '',
                normalizedUserName: '',
                email: '',
                normalizedEmail: '',
                emailConfirmed: false,
                passwordHash: '',
                securityStamp: '',
                concurrencyStamp: '',
                phoneNumber: '',
                phoneNumberConfirmed: false,
                twoFactorEnabled: false,
                lockoutEnd: undefined,
                lockoutEnabled: false,
                accessFailedCount: 0,
                firstName: '',
                lastName: '',
                lastLogin: undefined,
                isActive: false,
                role: '',
            } as AdminUserResponse,
        });
    }

    @bind
    async handleCreateUser(user: CreateUserRequest): Promise<void> {
        await this.props.userManagement.createUser(user).then(
            async () => {
                await this.getUsersList();
            },
            (reason) => {
                this.setState({ hasError: true });
            },
        );
    }

    @bind
    toggleEditUserDialog(user: AdminUserResponse): void {
        if (this.state.showEditDialog) {
            user = {
                id: '',
                userName: '',
                normalizedUserName: '',
                email: '',
                normalizedEmail: '',
                emailConfirmed: false,
                passwordHash: '',
                securityStamp: '',
                concurrencyStamp: '',
                phoneNumber: '',
                phoneNumberConfirmed: false,
                twoFactorEnabled: false,
                lockoutEnd: undefined,
                lockoutEnabled: false,
                accessFailedCount: 0,
                firstName: '',
                lastName: '',
                lastLogin: undefined,
                isActive: false,
                role: '',
            } as AdminUserResponse;
        }
        this.setState({
            showEditDialog: !this.state.showEditDialog,
            editUser: user,
            showConfirmDeleteDialog: false,
            showCreateDialog: false,
            showConfirmResetPasswordDialog: false,
        });
    }

    @bind
    async handleEditUser(user: UpdateUserRequest): Promise<void> {
        await this.props.userManagement.editUser(user);
        await this.getUsersList();
    }

    @bind
    toggleConfirmDeleteDialog(user: AdminUserResponse): void {
        if (this.state.showConfirmDeleteDialog) {
            user = {
                id: '',
                userName: '',
                normalizedUserName: '',
                email: '',
                normalizedEmail: '',
                emailConfirmed: false,
                passwordHash: '',
                securityStamp: '',
                concurrencyStamp: '',
                phoneNumber: '',
                phoneNumberConfirmed: false,
                twoFactorEnabled: false,
                lockoutEnd: undefined,
                lockoutEnabled: false,
                accessFailedCount: 0,
                firstName: '',
                lastName: '',
                lastLogin: undefined,
                isActive: false,
            } as AdminUserResponse;
        }
        this.setState({
            showConfirmDeleteDialog: !this.state.showConfirmDeleteDialog,
            editUser: user,
            showEditDialog: false,
            showCreateDialog: false,
            showConfirmResetPasswordDialog: false,
        });
    }

    @bind
    async handleDeleteUser(user: AdminUserResponse): Promise<void> {
        await this.props.userManagement.deleteUser(user);
        await this.getUsersList();
    }

    @bind
    toggleResetPasswordDialog(user: AdminUserResponse): void {
        if (this.state.showConfirmResetPasswordDialog) {
            user = {
                id: '',
                userName: '',
                normalizedUserName: '',
                email: '',
                normalizedEmail: '',
                emailConfirmed: false,
                passwordHash: '',
                securityStamp: '',
                concurrencyStamp: '',
                phoneNumber: '',
                phoneNumberConfirmed: false,
                twoFactorEnabled: false,
                lockoutEnd: undefined,
                lockoutEnabled: false,
                accessFailedCount: 0,
                firstName: '',
                lastName: '',
                lastLogin: undefined,
                isActive: false,
            } as AdminUserResponse;
        }
        this.setState({
            showConfirmResetPasswordDialog: !this.state.showConfirmResetPasswordDialog,
            editUser: user,
            showEditDialog: false,
            showCreateDialog: false,
            showConfirmDeleteDialog: false,
        });
    }

    @bind
    async handleResetUserPassword(user: AdminUserResponse): Promise<void> {
        await this.props.forgotPassword.sendPasswordResetLink(user.userName!);
    }

    @bind
    clearError(): void {
        this.setState({ hasError: false });
    }

    render(): JSX.Element {
        const users = this.state.usersList ?? [];
        const { page, rowsPerPage } = this.state;

        return (
            <div className={styles.main}>
                <PageHeader pageTitle={'User Management'} />
                <VerifyRole allowedRole={[RolesEnum.AdminUISuperAdmin]}>
                    <FilterBar onCreateUser={this.toggleCreateUserDialog} />
                </VerifyRole>
                <TableContainer className={styles.card}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {rowHeaders.map((head) => {
                                    return (
                                        <TableCell key={head.id} sortDirection={this.state.sort === head.id ? this.state.sortOrder : false}>
                                            <TableSortLabel
                                                active={this.state.sort === head.id}
                                                direction={this.state.sortOrder}
                                                onClick={(): void => this.handleRequestSort(head.id)}
                                            >
                                                {head.label}
                                            </TableSortLabel>
                                        </TableCell>
                                    );
                                })}
                                <TableCell />
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {users?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((user: AdminUserResponse, idx) => {
                                return (
                                    <TableRow key={idx}>
                                        <TableCell>{user.firstName}</TableCell>
                                        <TableCell>{user.lastName}</TableCell>
                                        <TableCell>{user.email}</TableCell>
                                        <TableCell>{user.lastLogin}</TableCell>
                                        <TableCell>
                                            {user.email !== 'admin@yaharasoftware.com' ? (
                                                <div>
                                                    <IconButton
                                                        size={'small'}
                                                        title={'Reset Password'}
                                                        onClick={(): void => {
                                                            this.toggleResetPasswordDialog(user);
                                                        }}
                                                    >
                                                        <RefreshIcon />
                                                    </IconButton>
                                                    <VerifyRole allowedRole={[RolesEnum.AdminUISuperAdmin]}>
                                                        <IconButton
                                                            size={'small'}
                                                            title={'Edit User'}
                                                            onClick={(): void => {
                                                                this.toggleEditUserDialog(user);
                                                            }}
                                                        >
                                                            <Edit />
                                                        </IconButton>
                                                    </VerifyRole>
                                                    <IconButton
                                                        size={'small'}
                                                        title={'Delete User'}
                                                        onClick={(): void => {
                                                            this.toggleConfirmDeleteDialog(user);
                                                        }}
                                                    >
                                                        <CloseIcon />
                                                    </IconButton>
                                                </div>
                                            ) : (
                                                ''
                                            )}
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    component="div"
                    rowsPerPageOptions={[10, 20, 50]}
                    count={users?.length || 0}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={this.handleChangePage}
                    onRowsPerPageChange={this.handleChangeRowsPerPage}
                />
                <CreateUserDialog
                    onCreateUser={this.handleCreateUser}
                    visible={this.state.showCreateDialog}
                    toggleDialog={this.toggleCreateUserDialog}
                    errorMessage={this.state.createUserError}
                />
                <EditUserDialog
                    onEditUser={this.handleEditUser}
                    visible={this.state.showEditDialog}
                    toggleDialog={this.toggleEditUserDialog}
                    userToEdit={this.state.editUser}
                />
                <ConfirmDeleteDialog
                    onDeleteUser={this.handleDeleteUser}
                    visible={this.state.showConfirmDeleteDialog}
                    toggleDialog={this.toggleConfirmDeleteDialog}
                    userToDelete={this.state.editUser}
                />
                <ConfirmResetPasswordDialog
                    onResetPassword={this.handleResetUserPassword}
                    visible={this.state.showConfirmResetPasswordDialog}
                    toggleDialog={this.toggleResetPasswordDialog}
                    userToReset={this.state.editUser}
                />
                <Dialog open={this.state.hasError}>
                    <DialogContent>{`Cannot create user`}</DialogContent>
                    <DialogActions>
                        <Button onClick={this.clearError}>Okay</Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

export const UserManagementPage = ForgotPasswordService.inject(UserManagementService.inject(_UserManagementPage));
