import { React } from '../../Imports';
import { Card, Table, TableBody, TableRow, TableCell, Button } from '../../MaterialUIComponents';
import { VehicleCameraPairingResponse } from '$Generated/api';

const styles = require('./StatusCard.scss') as {
    card: string;
    menuHeader: string;
    link: string;
    errorIcon: string;
    button: string
};

interface IStatusCardProps {
    device: VehicleCameraPairingResponse;
}

export class StatusCard extends React.PureComponent<IStatusCardProps, {}> {
    render(): JSX.Element {
        return (
            <Card className={styles.card}>
                <div className={styles.menuHeader}>Data Plan</div>
                <Table>
                    <TableBody>
                        <TableRow key={'provider'}>
                            <TableCell>Provider:</TableCell>
                            <TableCell>AT&T</TableCell>
                        </TableRow>
                        <TableRow key={'status'}>
                            <TableCell>Status:</TableCell>
                            <TableCell>Active</TableCell>
                        </TableRow>
                        <TableRow key={'usage'}>
                            <TableCell>Usage:</TableCell>
                            <TableCell>100mb</TableCell>
                        </TableRow>
                        <TableRow key={'actions'}>
                            <TableCell>
                                <Button className={styles.button}>Deprovision</Button>
                            </TableCell>
                            <TableCell>
                                <Button className={styles.button}>Suspend Service</Button>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </Card>
        );
    }
}
