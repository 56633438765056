import { FreezerService, managedAjaxUtil, IAjaxState, _ } from '../Imports';
import {
    CameraResponse, DeviceManagementApiFactory
} from '$Generated/api';

const InjectedPropName = 'cameraService';

interface ICameraState {
    CameraResults: IAjaxState<CameraResponse[]>;
}

class CameraFreezerService extends FreezerService<ICameraState, typeof InjectedPropName> {
    
    constructor() {
        super(
            {
                CameraResults: managedAjaxUtil.createInitialState(),
            },
            InjectedPropName,
        );
    }

    public async getCameras(fleetId:number, cameraDeviceType:number, searchString:string): Promise<void | CameraResponse[]> {
        return managedAjaxUtil.fetchResults({
            ajaxStateProperty: 'CameraResults',
            freezer: this.freezer,
            params: {
                fleetId,
                cameraDeviceType,
                searchString
            },
            onExecute: (apiOptions, params) => {
                if (params === undefined) {
                    throw new Error('Parameters is undefined');
                }

                const deviceManagementApi = DeviceManagementApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return deviceManagementApi.apiV1SearchcamerasGet(params)
            },
            onOk: (data: CameraResponse[]) => {
                return data;
            },
            onError: () => {
                //NOOP
            },
        });
    }
}

export const CameraService = new CameraFreezerService();
export type ICameraServiceInjectedProps = ReturnType<CameraFreezerService['getPropsForInjection']>;