import { moment } from '../../Imports';

export function dateAndTimezone(date: moment.Moment): string {
    return date.tz(moment.tz.guess()).format('L h:mm A z');
}
export function date(date: moment.Moment): string {
    return date.format('L');
}
export function timeOfDay(date: moment.Moment): string {
    return moment.tz(date.toString(), moment.tz.guess()).format('h:mm A');
}
export function timeWithTimezone(date: moment.Moment): string {
    return date.tz(moment.tz.guess()).format('h:mm A z');
}
export function getTime(date: moment.Moment) {
    return date.tz(moment.tz.guess()).format('h:mm A');
}

export function getUtcString(date: moment.Moment): string {
    return date.utc().format();
}
export function formatCsvTime(date: moment.Moment, guessTimezone: boolean): string {
    return moment(date).utc().format('YYYY-MM-DD hh:mm:ss');
}
export function getDaysAgo(fromDate: moment.Moment): string {
    if (fromDate) {
        const date = moment(fromDate);
        const now = moment();
        let daysAgo = '';
        if (date.month() === now.month() && date.day() === now.day() && date.year() === now.year()) {
            daysAgo = 'Today';
        } else {
            const dayNum = Math.ceil((now.valueOf() - date.valueOf()) / (24 * 3600 * 1000));
            daysAgo = dayNum + ' day' + (dayNum > 1 ? 's' : '') + ' ago';
        }
        return daysAgo;
    }
    return '';
}
export function dateWithMonthAbbr(date: moment.Moment): string {
    return moment.tz(date.toString(), moment.tz.guess()).format('MMM DD, YYYY');
}
