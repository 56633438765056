// #region Imports
import { withInjectedContext, ReactComponentLike } from '../externals/withInjectedContext';
import React = require('react');
import { IApplicationOptions, IWithApiOptionsProps } from '@videoplatform/data-types';
// #endregion Imports

export { IApplicationOptions, IWithApiOptionsProps };

export type IFetcher = typeof window.fetch;

export const ApiContext = React.createContext<IApplicationOptions>({
    applicationRoot: '',
    apiBaseUrl: '',
    wrappedFetch: window.fetch,
});

export function withApiContext<IOriginalProps extends Record<string, any> = {}>(
    Component: ReactComponentLike<IOriginalProps & IWithApiOptionsProps>,
) {
    return withInjectedContext<IOriginalProps, IApplicationOptions, IWithApiOptionsProps>(
        Component,
        ApiContext,
        (data) => ({ apiOptions: data }),
    );
}
