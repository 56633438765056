/* eslint-disable prefer-const */
import { React, _, bind } from '../../Imports';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, MenuItem } from '../../MaterialUIComponents';
import { CreateUserRequest } from '$Generated/api';
import { TextValidator, ValidatorForm } from 'MaterialUIComponents';
import { IDropdownItem, VPDropdown } from '$Components/Shared/VPDropdown';
import { SelectValidator } from 'react-material-ui-form-validator';

const styles = require('./CreateUserDialog.scss') as {
    main: string;
    actionButton: string;
    dialogContent: string;
    errorMessage: string;
};

interface ICreateUserDialogProps {
    onCreateUser: (user: CreateUserRequest) => void;
    visible: boolean;
    toggleDialog: () => void;
    errorMessage: string | undefined;
}

interface ICreateUserDialogState {
    user: CreateUserRequest;
    confirmPassword: string | undefined;
    selectedRole: string[];
}

class _CreateUserDialog extends React.Component<ICreateUserDialogProps, ICreateUserDialogState> {
    state = {
        user: {
            firstName: '',
            lastName: '',
            email: '',
            roleName: '',
            password: '',
        } as CreateUserRequest,
        confirmPassword: undefined,
        selectedRole: [],
    };

    componentDidMount() {
        ValidatorForm.addValidationRule('isPasswordMatch', (value) => {
            if (value !== this.state.user.password) {
                return false;
            }
            return true;
        });
        ValidatorForm.addValidationRule('passwordMinLength', (value) => {
            if (value == undefined || value.length < 8) {
                return false;
            }
            return true;
        });
        ValidatorForm.addValidationRule('passwordValue', (value) => {
            var specialCharacter = /^.*[*.!@#$%^&:;,.?~_+\-=]+.*$/;
            var lowerCase = /^.*[a-z]+.*$/;
            var upperCase = /^.*[A-Z]+.*$/;
            var number = /^.*[0-9]+.*$/;

            if (
                value == undefined ||
                !specialCharacter.exec(value) ||
                !upperCase.exec(value) ||
                !lowerCase.exec(value) ||
                !number.exec(value)
            ) {
                return false;
            }
            return true;
        });
    }

    private adminRoles: IDropdownItem[] = [
        { label: 'AdminUIView', value: 'AdminUIView' },
        { label: 'AdminUIEdit', value: 'AdminUIEdit' },
        { label: 'AdminUIAdmin', value: 'AdminUIAdmin' },
        { label: 'AdminUISuperAdmin', value: 'AdminUISuperAdmin' },
    ];

    componentWillUnmount() {
        ValidatorForm.removeValidationRule('isPasswordMatch');
        ValidatorForm.removeValidationRule('passwordMidLength');
        ValidatorForm.removeValidationRule('passwordValue');
    }

    @bind
    closeDialog(): void {
        this.setState({
            user: {
                firstName: '',
                lastName: '',
                email: '',
                roleName: '',
                password: '',
            } as CreateUserRequest,
            confirmPassword: undefined,
        });
        this.props.toggleDialog();
    }

    @bind
    cancelAddUser(): void {
        this.closeDialog();
    }

    @bind
    createUser(): void {
        this.setState({ user: { ...this.state.user } });
        this.props.onCreateUser(this.state.user);
        this.closeDialog();
    }

    @bind
    handleFieldChange(field: string, event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void {
        let user: CreateUserRequest = _.clone(this.state.user);
        switch (field) {
            case 'FirstName':
                user.firstName = event.target.value;
                break;
            case 'LastName':
                user.lastName = event.target.value;
                break;
            case 'Email':
                user.email = event.target.value;
                break;
            case 'Password':
                user.password = event.target.value;
                break;
            case 'ConfirmPassword':
                this.setState({ confirmPassword: event.target.value });
                break;
            case 'Role':
                user.roleName = event.target.value;
                break;
        }
        this.setState({ user: user });
    }

    @bind
    handleRoleChanged(selectedValue: any) {
        this.setState({ selectedRole: selectedValue });
    }

    render(): JSX.Element {
        return (
            <div className={styles.main}>
                <Dialog open={this.props.visible}>
                    <ValidatorForm ref={'createUser'} onSubmit={this.createUser} onError={(errors: any[]): void => console.log(errors)}>
                        <DialogTitle>Add User</DialogTitle>
                        <DialogContent className={styles.dialogContent}>
                            {this.props.errorMessage && <div className={styles.errorMessage}>{this.props.errorMessage}</div>}

                            <TextValidator
                                label="First Name"
                                margin="dense"
                                fullWidth={true}
                                variant="outlined"
                                onChange={(e: any): void => this.handleFieldChange('FirstName', e)}
                                name="firstName"
                                value={this.state.user.firstName}
                                validators={['required']}
                                errorMessages={['This field is required']}
                            />
                            <TextValidator
                                label="Last Name"
                                margin="dense"
                                fullWidth={true}
                                variant="outlined"
                                onChange={(e: any): void => this.handleFieldChange('LastName', e)}
                                name="lastName"
                                value={this.state.user.lastName}
                                validators={['required']}
                                errorMessages={['This field is required']}
                            />
                            <TextValidator
                                label="Email Address"
                                margin="dense"
                                fullWidth={true}
                                variant="outlined"
                                onChange={(e: any): void => this.handleFieldChange('Email', e)}
                                name="emailAddress"
                                style={{ width: '450px' }}
                                value={this.state.user.email}
                                validators={['required', 'isEmail']}
                                errorMessages={['This field is required', 'Email is not valid']}
                            />
                            <TextValidator
                                label="Password"
                                margin="dense"
                                fullWidth={true}
                                variant="outlined"
                                onChange={(e: any): void => this.handleFieldChange('Password', e)}
                                name="password"
                                type="password"
                                value={this.state.user.password}
                                validators={['required', 'isPasswordMatch', 'passwordMinLength', 'passwordValue']}
                                errorMessages={[
                                    'This field is required',
                                    'Passwords do not match',
                                    'Password must be at least 8 characters long',
                                    'Password should include at least one lowercase letter, uppercase letter, number, and special character(*.!@#$%^&:;,.?~_+-=)',
                                ]}
                            />
                            <TextValidator
                                label="Confirm Password"
                                margin="dense"
                                fullWidth={true}
                                variant="outlined"
                                onChange={(e: any): void => this.handleFieldChange('ConfirmPassword', e)}
                                name="confirmPassword"
                                type="password"
                                value={this.state.confirmPassword}
                                validators={['required', 'isPasswordMatch', 'passwordMinLength', 'passwordValue']}
                                errorMessages={[
                                    'This field is required',
                                    'Passwords do not match',
                                    'Password must be at least 7 characters long',
                                    'Password should include at least one lowercase letter, uppercase letter, number, and special character(*.!@#$%^&:;,.?~_+-=)',
                                ]}
                            />
                            <SelectValidator
                                label="Role"
                                name={'RoleName'}
                                fullWidth={true}
                                value={this.state.user.roleName}
                                validators={['required']}
                                errorMessages={['This field is required']}
                                onChange={(e: any): void => this.handleFieldChange('Role', e)}
                            >
                                {this.adminRoles.map((item, idx) => {
                                    return (
                                        <MenuItem value={item.value} key={idx}>
                                            {item.label}
                                        </MenuItem>
                                    );
                                })}
                            </SelectValidator>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.cancelAddUser} className={styles.actionButton}>
                                Cancel
                            </Button>
                            <Button type="submit" className={styles.actionButton}>
                                Save
                            </Button>
                        </DialogActions>
                    </ValidatorForm>
                </Dialog>
            </div>
        );
    }
}

export const CreateUserDialog = _CreateUserDialog;
