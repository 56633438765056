import { React, Route, RouteComponentProps, withRouter, Switch, Redirect } from 'Imports';

import { IApplicationProps } from 'Application';

import { CircularProgress } from 'MaterialUIComponents';
import { DeviceDetailsPage } from '$Pages/DeviceDetails/DeviceDetails';
import { DeviceManagementPage } from '$Pages/DeviceManagement/DeviceManagement';
import { ForgotPasswordPage } from '$Pages/ForgotPassword/ForgotPassword';
import { HealthCheckPage } from '$Pages/HealthCheck/HealthCheck';
import { HomePage } from '$Pages/Home/Home';
import { LoginPage } from '$Pages/Login/Login';
import { SetPasswordPage } from '$Pages/ForgotPassword/SetPassword';
import { UserManagementPage } from '$Pages/UserManagement/UserManagement';
import DeviceBulkPage from '$Pages/DeviceBulk/DeviceBulk';

interface IPageRouterBaseProps {
    selectedId: number;
    onSelectId: (id: number) => void;
}

type IPageRouterProps = IPageRouterBaseProps & IApplicationProps & RouteComponentProps;

class _PageRouter extends React.Component<IPageRouterProps> {
    render(): JSX.Element {
        const isFetchingCurrentUser = this.props.currentUser.getState().result.isFetching;
        if (isFetchingCurrentUser) {
            return <CircularProgress />;
        }
        const { isLoggedIn } = this.props.currentUser.getState();

        return (
            <>
                {isLoggedIn ? (
                    <Switch>
                        <Route
                            path="/"
                            exact={true}
                            render={(): JSX.Element => {
                                this.props.setPageTitle('Home');
                                return <HomePage />;
                            }}
                        />

                        <Route
                            path="/HealthCheck"
                            render={(): JSX.Element => {
                                this.props.setPageTitle('Health Check');
                                return <HealthCheckPage />;
                            }}
                        />

                        <Route
                            path="/ForgotPassword"
                            render={(): React.ReactNode => {
                                this.props.setPageTitle('Forgot Password');
                                return <ForgotPasswordPage />;
                            }}
                        />

                        <Route
                            path="/SetPassword"
                            render={(): React.ReactNode => {
                                this.props.setPageTitle('Set Password');
                                return <SetPasswordPage />;
                            }}
                        />

                        <Route
                            path="/DeviceManagement"
                            render={(): JSX.Element => {
                                this.props.setPageTitle('Device Health');
                                if (this.props.selectedId >= 0) {
                                    return <DeviceDetailsPage deviceId={this.props.selectedId} />;
                                } else {
                                    return <DeviceManagementPage onSelectDevice={this.props.onSelectId} />;
                                }
                            }}
                        />

                        <Route
                            path="/DeviceBulk"
                            render={(): JSX.Element => {
                                this.props.setPageTitle('Device Bulk');
                                return <DeviceBulkPage />;
                            }}
                        />

                        <Route
                            path="/UserManagement"
                            render={(): JSX.Element => {
                                this.props.setPageTitle('User Management');
                                return <UserManagementPage />;
                            }}
                        />

                        <Redirect to={'/'} />
                    </Switch>
                ) : (
                    <Switch>
                        <Route
                            path="/PasswordReset"
                            render={(): React.ReactNode => {
                                this.props.setPageTitle('PasswordReset');
                                return <ForgotPasswordPage />;
                            }}
                        />

                        <Route
                            path="/SetPassword"
                            render={(): React.ReactNode => {
                                this.props.setPageTitle('PasswordReset');
                                return <SetPasswordPage />;
                            }}
                        />

                        <Route
                            path="/ForgotPassword"
                            render={(): React.ReactNode => {
                                this.props.setPageTitle('Forgot Password');
                                return <ForgotPasswordPage />;
                            }}
                        />

                        <Route
                            path="/Login"
                            render={(): React.ReactNode => {
                                this.props.setPageTitle('Login');
                                return <LoginPage />;
                            }}
                        />

                        <Redirect to={'/Login'} />
                    </Switch>
                )}
            </>
        );
    }
}

export const PageRouter = withRouter(_PageRouter);
