import { React, withRouter, RouteComponentProps } from 'Imports';
import { TextField, Button, Link, Card, Typography, CardContent } from 'MaterialUIComponents';
import { IForgotPasswordServiceInjectedProps, ForgotPasswordService } from '$State/ForgotPasswordFreezer';
import { LoginUserRequest } from '$Generated/api';
interface ISetPasswordBaseProps {}

type ISetPasswordProps = ISetPasswordBaseProps & IForgotPasswordServiceInjectedProps & RouteComponentProps;

interface ISetPasswordState {
    confirmPassword: string;
    password: string;
    errorMsg: string;
    id: string;
    token: string;
    passwordHasError: boolean;
    visiblePassword: string;
    visibleConfirmPassword: string;
}

const styles = require('./SetPassword.scss') as {
    main: string;
    loginbutton: string;
    content: string;
    redText: string;
    link: string;
};

class _SetPassword extends React.Component<ISetPasswordProps, ISetPasswordState> {
    state: ISetPasswordState = {
        confirmPassword: '',
        password: '',
        errorMsg: '',
        id: '',
        token: '',
        passwordHasError: false,
        visiblePassword: '',
        visibleConfirmPassword: '',
    };

    componentDidMount() {
        let url = new URL(window.location.href);
        let searchParams = new URLSearchParams(url.search);
        let uid = searchParams.get('uid');
        let token = searchParams.get('token');

        if (uid !== null && token !== null) {
            this.setState({ id: uid, token });
        }
    }
    async resetPassword() {
        this.setState({ errorMsg: '' });

        if (this.state.password !== this.state.confirmPassword) {
            this.setState({ passwordHasError: true });
            return;
        } else {
            this.setState({ passwordHasError: false });
        }

        let user: LoginUserRequest = {
            password: this.state.password,
            token: this.state.token,
            username: this.state.id,
        };

        await this.props.forgotPassword
            .setPassword(user)
            .then(() => {
                if (!this.props.forgotPassword.getState().hasError) {
                    const { history } = this.props;
                    history.push('/Login');
                }
            })
            .catch((ex) => {
                ex.text()
                    .then(function (text: string) {
                        return text;
                    })
                    .then((error: string) => {
                        this.setState({ errorMsg: error });
                    });
            });
    }

    passwordChange(val: any) {
        const password = this.state.password;
        let inputValue = val.target.value;
        let newPassword;

        if (inputValue.length == 0) {
            this.setState({ password: '', visiblePassword: '' });
        } else if (inputValue.length == this.state.password.length - 1) {
            newPassword = password?.substring(0, password?.length - 1);
            this.setState({ password: newPassword, visiblePassword: '*'.repeat(newPassword?.length) });
        } else if (inputValue.length > this.state.password.length + 1 || inputValue.length < this.state.password.length - 1) {
            this.setState({ password: inputValue, visiblePassword: '*'.repeat(inputValue.length) });
        } else {
            this.setState({ password: password + inputValue.slice(-1), visiblePassword: '*'.repeat(inputValue.length) });
        }
    }

    confirmPasswordChange(val: any) {
        const password = this.state.confirmPassword;
        let inputValue = val.target.value;
        let newPassword;

        if (inputValue.length == 0) {
            this.setState({ confirmPassword: '', visibleConfirmPassword: '' });
        } else if (inputValue.length == this.state.confirmPassword.length - 1) {
            newPassword = password?.substring(0, password?.length - 1);
            this.setState({ confirmPassword: newPassword, visibleConfirmPassword: '*'.repeat(newPassword?.length) });
        } else if (inputValue.length > this.state.confirmPassword.length + 1 || inputValue.length < this.state.confirmPassword.length - 1) {
            this.setState({ confirmPassword: inputValue, visibleConfirmPassword: '*'.repeat(inputValue.length) });
        } else {
            this.setState({ confirmPassword: password + inputValue.slice(-1), visibleConfirmPassword: '*'.repeat(inputValue.length) });
        }
    }

    render() {
        return (
            <div className={styles.main}>
                <Card className={styles.content}>
                    <CardContent>
                        <Typography variant="h5" component="h2">
                            <b>Set Password</b>
                        </Typography>
                        {this.props.forgotPassword.getState().hasError && <div className={styles.redText}>{this.state.errorMsg}</div>}
                        {this.state.passwordHasError && <div className={styles.redText}>Your passwords don't match.</div>}
                    </CardContent>
                    <TextField
                        label={'New Password'}
                        type="password"
                        placeholder={'New Password'}
                        onChange={(e) => this.passwordChange(e)}
                        value={this.state.visiblePassword}
                    />
                    <TextField
                        label={'Confirm Password'}
                        type="password"
                        placeholder={'Confirm Password'}
                        onChange={(e) => this.confirmPasswordChange(e)}
                        value={this.state.visibleConfirmPassword}
                    />
                    <Button className={styles.loginbutton} onClick={(e) => this.resetPassword()}>
                        Reset Password
                    </Button>
                </Card>
            </div>
        );
    }
}

export const SetPasswordPage = withRouter(ForgotPasswordService.inject(_SetPassword));
