// #region Imports
import { List, ListItem, ListItemText, Theme, withStyles, WithStyles, CSSProperties, ListItemButton } from 'MaterialUIComponents';
import { bind, React, RouteComponentProps, withRouter, NavLink } from 'Imports';
import { CurrentUserService, ICurrentUserInjectedProps } from '$State/CurrentUserFreezer';
import { ConfigService } from '$State/ConfigFreezerService';
import { GetImageUrl } from '$Utilities/dataModelUtilities';

// #endregion Imports

const styles = require('./NavigationMenu.scss') as {
    active: string;
    link: string;
    listItemText: string;
    logo: string;
    main: string;
    navbar: string;
    page: string;
    pageOuter: string;
    subMenuItem: string;
    toolbar: string;
};

const muiStyles = (theme: Theme) => ({
    root: {
        flexGrow: 1,
        height: 430,
        zIndex: 1,
        overflow: 'hidden',
        position: 'relative',
        display: 'flex',
    } as CSSProperties,
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
    },
    content: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.default,

        minWidth: 0, // So the Typography noWrap works
    },
    toolbar: theme.mixins.toolbar,
});

interface INavigationMenuState {
    documentationOpen: boolean;
}

interface INavigationMenuProps {
    onSelectId: (id: number) => void;
}

export class _NavigationMenu extends React.Component<
    INavigationMenuProps & WithStyles<keyof ReturnType<typeof muiStyles>> & RouteComponentProps & ICurrentUserInjectedProps,
    INavigationMenuState
> {
    state: INavigationMenuState = {
        documentationOpen: false,
    };

    @bind
    toggleDocumentationOpen(e?: React.MouseEvent<Element>): void {
        if (e) {
            e.stopPropagation();
            e.preventDefault();
        }

        this.setState((curState) => {
            return {
                documentationOpen: !curState.documentationOpen,
            };
        });
    }

    @bind
    async handleLogout(): Promise<void> {
        await this.props.currentUser.logout().then(() => {
            const { history } = this.props;
            history.push('/Login');
        });
    }

    @bind
    GetImageBucketUrl(filename: string): string {
        const currentImagesBucket = ConfigService.getState().clientConfigResults.data?.imagesBucket;
        const currentRegion = ConfigService.getState().clientConfigResults.data?.imagesBucketRegion;
        // If config hasn't been set yet, return an empty location for the image src
        return currentImagesBucket && currentRegion ? GetImageUrl(currentImagesBucket, currentRegion, filename) : '//:0';
    }

    render(): JSX.Element {
        return (
            <div className={styles.main}>
                <List>
                    <NavLink
                        activeClassName={styles.active}
                        className={styles.link}
                        exact={true}
                        to="/HealthCheck"
                        onClick={(): void => this.props.onSelectId(-1)}
                    >
                        <ListItemButton>
                            <ListItemText inset={false}>Health Check</ListItemText>
                        </ListItemButton>
                    </NavLink>
                    <NavLink
                        activeClassName={styles.active}
                        className={styles.link}
                        exact={true}
                        to="/DeviceManagement"
                        onClick={(): void => this.props.onSelectId(-1)}
                    >
                        <ListItemButton>
                            <ListItemText inset={false}>Device Health</ListItemText>
                        </ListItemButton>
                    </NavLink>
                    <NavLink
                        activeClassName={styles.active}
                        className={styles.link}
                        exact={true}
                        to="/DeviceBulk"
                        onClick={(): void => this.props.onSelectId(-1)}
                    >
                        <ListItem button={true}>
                            <ListItemText inset={false}>Device Bulk</ListItemText>
                        </ListItem>
                    </NavLink>
                    <NavLink
                        activeClassName={styles.active}
                        className={styles.link}
                        exact={true}
                        to="/UserManagement"
                        onClick={(): void => this.props.onSelectId(-1)}
                    >
                        <ListItemButton>
                            <ListItemText inset={false}>User Management</ListItemText>
                        </ListItemButton>
                    </NavLink>
                    <ListItemButton onClick={this.handleLogout}>
                        <ListItemText inset={false}>Logout</ListItemText>
                    </ListItemButton>
                </List>
            </div>
        );
    }
}

export const NavigationMenu = withStyles(muiStyles)(withRouter(CurrentUserService.inject(_NavigationMenu)));
