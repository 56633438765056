import { React, } from "../../Imports";
import { TextField, Button, Link, Card, Typography, CardContent  } from "../../MaterialUIComponents";
import { IForgotPasswordServiceInjectedProps, ForgotPasswordService } from "../../state/ForgotPasswordFreezer";


interface IForgotPasswordBaseProps {
}

type IForgotPasswordProps = IForgotPasswordBaseProps & IForgotPasswordServiceInjectedProps;

interface IForgotPasswordState {
    username: string;
    password: string;
    errorMsg: string;

}

const styles = require("./ForgotPassword.scss") as {
    main: string,
    loginbutton: string,
    content: string,
    redText: string;
    link: string;
};

class _ForgotPassword extends React.Component<IForgotPasswordProps, IForgotPasswordState> {
    state: IForgotPasswordState = {
        username: "",
        password: "",
        errorMsg: ""
    };

    async resetPassword(username: string) {
        this.setState({errorMsg: ""});
        await this.props.forgotPassword.sendPasswordResetLink(username);

    }

    usernameChange(val: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) {
        this.setState({ username: val.target.value })
    }


    render() {
        return (
            <div className={styles.main}>

                    <Card className={styles.content} >

                        <CardContent>
                            <Typography variant="h5" component="h2">
                                <b>Forgot Password</b>
                            </Typography>
                            {this.props.forgotPassword.getState().hasError  && (
                                <Typography className={styles.redText}>
                                    There was an error with resetting a password.
                                </Typography>
                            )}
                        </CardContent>
                        <TextField label={"Username"} placeholder={"Username"} onChange={(e) => this.usernameChange(e)} value={this.state.username} />
                        <Button className={styles.loginbutton} onClick={(e) => this.resetPassword(this.state.username)}>Reset Password</Button>

                    </Card>
            </div>
        );
    }
}

export const ForgotPasswordPage = ForgotPasswordService.inject(_ForgotPassword);