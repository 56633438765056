/* eslint-disable prefer-const */
import { React, bind } from '../../Imports';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions } from '../../MaterialUIComponents';
import { AdminUserResponse } from '$Generated/api';

const styles = require('./EditUserDialog.scss') as {
    main: string;
    actionButton: string;
    dialogContent: string;
};

interface IConfirmResetPasswordDialogProps {
    onResetPassword: (user: AdminUserResponse) => void;
    visible: boolean;
    toggleDialog: (user: AdminUserResponse) => void;
    userToReset: AdminUserResponse;
}

class _ConfirmResetPasswordDialog extends React.Component<IConfirmResetPasswordDialogProps, {}> {
    @bind
    closeDialog(): void {
        this.props.toggleDialog({
            id: '',
            userName: '',
            normalizedUserName: '',
            email: '',
            normalizedEmail: '',
            emailConfirmed: false,
            passwordHash: '',
            securityStamp: '',
            concurrencyStamp: '',
            phoneNumber: '',
            phoneNumberConfirmed: false,
            twoFactorEnabled: false,
            lockoutEnd: undefined,
            lockoutEnabled: false,
            accessFailedCount: 0,
            firstName: '',
            lastName: '',
            lastLogin: undefined,
            isActive: false,
        } as AdminUserResponse);
    }

    @bind
    cancelResetPassword(): void {
        this.closeDialog();
    }

    @bind
    resetPassword(): void {
        this.props.onResetPassword(this.props.userToReset);
        this.closeDialog();
    }

    render(): JSX.Element {
        return (
            <div className={styles.main}>
                <Dialog open={this.props.visible}>
                    <DialogTitle>Reset Password</DialogTitle>
                    <DialogContent className={styles.dialogContent}>
                        Are you sure you want to reset the password for {this.props.userToReset.firstName} {this.props.userToReset.lastName}
                        ?
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.cancelResetPassword} className={styles.actionButton}>
                            Cancel
                        </Button>
                        <Button onClick={this.resetPassword} className={styles.actionButton}>
                            Reset Password
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

export const ConfirmResetPasswordDialog = _ConfirmResetPasswordDialog;
