import { FreezerService, managedAjaxUtil, IAjaxState, _ } from '../Imports';
import {
    DeviceHistoryResponse,
    DeviceManagementApiFactory,
    DropdownMenuItem,
    DeviceHistoryActionEnum,
} from '$Generated/api';
const InjectedPropName = 'deviceHistory';

interface IDeviceHistoryState {
    DeviceHistoryResults: IAjaxState<DeviceHistoryResponse[]>;
    HistoryFilterResults: IAjaxState<DropdownMenuItem[]>;
    hasError: boolean;
}

class DeviceHistoryFreezerService extends FreezerService<IDeviceHistoryState, typeof InjectedPropName> {
    constructor() {
        super(
            {
                DeviceHistoryResults: managedAjaxUtil.createInitialState(),
                HistoryFilterResults: managedAjaxUtil.createInitialState(),
                hasError: false,
            },
            InjectedPropName,
        );
    }

    public async getDeviceHistory(
        id: number | undefined,
        sortBy: string,
        isAsc: boolean,
        actionFilter: DeviceHistoryActionEnum | undefined,
    ): Promise<void | DeviceHistoryResponse[]> {
        return managedAjaxUtil.fetchResults({
            ajaxStateProperty: 'DeviceHistoryResults',
            freezer: this.freezer,
            onExecute: (apiOptions, params) => {
                if (params === undefined) {
                    throw new Error('Parameters is undefined');
                }

                const AdministrativeApi = DeviceManagementApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return AdministrativeApi.apiV1DeviceHistoryGet(params);
            },
            params: {
                id: id,
                sortBy: sortBy,
                sortAsc: isAsc,
                actionFilter: actionFilter,
            },
            onOk: (data: DeviceHistoryResponse[]) => {
                if (data !== undefined) {
                    this.freezer.get().set({ hasError: false });
                }

                return data;
            },
            onError: () => {
                this.freezer.get().set({ hasError: true });
            },
        });
    }

    public async getHistoryFilters(): Promise<void | DropdownMenuItem[]> {
        return managedAjaxUtil.fetchResults({
            ajaxStateProperty: 'HistoryFilterResults',
            freezer: this.freezer,
            onExecute: (apiOptions, params) => {
                if (params === undefined) {
                    throw new Error('Parameters is undefined');
                }

                const AdministrativeApi = DeviceManagementApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return AdministrativeApi.apiV1DeviceHistoryFiltersGet(params);
            },
            params: {},
            onOk: (data: DropdownMenuItem[]) => {
                if (data !== undefined) {
                    this.freezer.get().set({ hasError: false });
                }

                return data;
            },
            onError: () => {
                this.freezer.get().set({ hasError: true });
            },
        });
    }
}

export const DeviceHistoryService = new DeviceHistoryFreezerService();
export type IDeviceHistoryServiceInjectedProps = ReturnType<DeviceHistoryFreezerService['getPropsForInjection']>;
