import { CurrentUserService } from '../state/CurrentUserFreezer';
import { React } from '../Imports';

enum RolesEnum {
    AdminUIView = 'AdminUIView',
    AdminUIEdit = 'AdminUIEdit',
    AdminUIAdmin = 'AdminUIAdmin',
    AdminUISuperAdmin = 'AdminUISuperAdmin',
}

const getUserRoles = () => {
    var userRoles = CurrentUserService.getCurrentUserState();
    return userRoles?.data?.roles || [];
};

const canAccessUserRolesPage = async (allowedRole: string[]) => {
    var userRoles = await getUserRoles();
    return checkHasRole(userRoles || [], allowedRole);
};

const checkHasRole = (userRoles: string[], allowedRole: string[]) => {
    return (
        userRoles?.filter(
            (s) =>
                allowedRole.find((i) => i.toString() === s) || // If user has any permission allowed
                (allowedRole.find((i) => i !== RolesEnum.AdminUISuperAdmin.toString()) && s === RolesEnum.AdminUIAdmin.toString()) || // or if user is AdminUIAdmin and is not required to be AdminUISuperAdmin
                s === RolesEnum.AdminUISuperAdmin.toString(),
        ).length > 0
    ); // or if the user is AdminUISuperAdmin
};

type Props = {
    children: JSX.Element;
    allowedRole: string[];
    userRoles?: string[];
    userManagement?: any;
    restrictedMessage?: boolean;
};

const restrictedMessge = (restrictedMessage?: boolean): JSX.Element => {
    return !restrictedMessage ? (
        <></>
    ) : (
        <h1 style={{ textAlign: 'center', paddingTop: '20px' }}>
            You do not have access to this functionality. <br /> Please contact your administrator to request access.
        </h1>
    );
};

const VerifyRole = ({ children, userRoles, allowedRole, restrictedMessage = false }: Props): JSX.Element => {
    const _userRoles = userRoles ? userRoles : getUserRoles() || [];
    return checkHasRole(_userRoles, allowedRole) ? children : restrictedMessge(restrictedMessage);
};

export { VerifyRole, RolesEnum, checkHasRole, canAccessUserRolesPage };
