import { FreezerService, managedAjaxUtil, IAjaxState } from '../Imports';
import { TelematicDeviceApiFactory, TelematicDeviceAdminResponse, TelematicsDeviceResponse } from '$Generated/api';

const InjectedPropName = 'telematicsDeviceManagement';

interface ITelematicDeviceState {
    DeviceDetailsResult: IAjaxState<TelematicsDeviceResponse>;
    TelematicDeviceResults: IAjaxState<TelematicDeviceAdminResponse[]>,
    hasError: boolean;
}

class TelematicDeviceFreezerService extends FreezerService<ITelematicDeviceState, typeof InjectedPropName> {
    constructor() {
        super(
            {
                DeviceDetailsResult: managedAjaxUtil.createInitialState(),
                TelematicDeviceResults: managedAjaxUtil.createInitialState(),
                hasError: false,
            },
            InjectedPropName,
        );
    }

    public async getAllTelematicDevices(fleetId:number, telematicProviderId:string): Promise<void | TelematicDeviceAdminResponse[]> {
        return managedAjaxUtil.fetchResults({
            ajaxStateProperty: 'TelematicDeviceResults',
            freezer: this.freezer,
            params: {
                fleetId: fleetId,
                telematicProviderId: telematicProviderId
             },
            onExecute: (apiOptions, params) => {
                if (params === undefined) {
                    throw new Error('Parameters is undefined');
                }

                const telematicDeviceApi = TelematicDeviceApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return telematicDeviceApi.apiV1TelematicdevicesGet(params)
            },
            onOk: (data: TelematicDeviceAdminResponse[]) => {
                return data;
            },
            onError: () => {
                //NOOP
            },
        });
    }

    public async getDevice(id: string | undefined): Promise<void | TelematicsDeviceResponse> {
        return managedAjaxUtil.fetchResults({
            ajaxStateProperty: 'DeviceDetailsResult',
            freezer: this.freezer,
            onExecute: (apiOptions, params) => {
                if (params === undefined) {
                    throw new Error('Parameters is undefined');
                }

                const AdministrativeApi = TelematicDeviceApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return AdministrativeApi.apiV1TelematicDeviceGet(params);
            },
            params: {
                id: id,
            },
            onOk: (data: TelematicsDeviceResponse) => {
                if (data !== undefined) {
                    this.freezer.get().set({ hasError: false });
                }

                return data;
            },
            onError: () => {
                this.freezer.get().set({ hasError: true });
            },
        });
    }

    public async clearDeviceDetailsResult() {

        await this.freezer.get().set({DeviceDetailsResult: managedAjaxUtil.createInitialState()})
    }
}

export const TelematicDeviceService = new TelematicDeviceFreezerService();
export type ITelematicDeviceServiceInjectedProps = ReturnType<TelematicDeviceFreezerService['getPropsForInjection']>;
