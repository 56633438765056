import { React } from '../../Imports';

const styles = require('./MenuBar.scss') as {
    main: string;
    username: string;
};

interface IMenuBarProps {
    username?: string;
}

export class MenuBar extends React.PureComponent<IMenuBarProps, {}> {
    render(): JSX.Element {
        return (
            <div className={styles.main}>
                <div className={styles.username}>{this.props.username}</div>
            </div>
        );
    }
}
