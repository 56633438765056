import { React } from '../../Imports';
import { PageHeader } from '../../components/Shared/PageHeader';

interface IHealthCheckBaseProps {}

type IHealthCheckProps = IHealthCheckBaseProps;

interface IHealthCheckState {}

const styles = require('./HealthCheck.scss') as {
    main: string;
    contentContainer: string;
    content: string;
    filterContainer: string;
};

class _HealthCheckPage extends React.Component<IHealthCheckProps, IHealthCheckState> {
    state: IHealthCheckState = {};

    render(): JSX.Element {
        return (
            <div className={styles.main}>
                <div className={styles.contentContainer}>
                    <PageHeader pageTitle={'Health Check'} />
                </div>
            </div>
        );
    }
}

export const HealthCheckPage = _HealthCheckPage;
