import { FreezerService, managedAjaxUtil, IAjaxState, _ } from '../Imports';
import {
    DeviceManagementApiFactory, DeviceAssociationValidationResponse, TelematicDeviceAdminResponse, TelematicProviderIdEnum
} from '$Generated/api';

const InjectedPropName = 'deviceAssociationService';

interface IDeviceAssociationState {
    CameraValidationResult: IAjaxState<DeviceAssociationValidationResponse>;
    TelematicValidationResult: IAjaxState<DeviceAssociationValidationResponse>;
}

class DeviceAssociationFreezerService extends FreezerService<IDeviceAssociationState, typeof InjectedPropName> {

    constructor() {
        super(
            {
                CameraValidationResult: managedAjaxUtil.createInitialState(),
                TelematicValidationResult: managedAjaxUtil.createInitialState(),
            },
            InjectedPropName,
        );
    }

    public async saveDeviceAssociation(fleetId:number, telematicProviderId:TelematicProviderIdEnum, telematicDevice:TelematicDeviceAdminResponse, cameraDeviceId: number): Promise<void | {}> {
        return managedAjaxUtil.fetchResults({
            ajaxStateProperty: 'SaveDeviceAssociationResult',
            freezer: this.freezer,
            params: {
                body:{
                    fleetId,
                    telematicProviderId,
                    cameraDeviceId,
                    integrationPartnerVehicleId: telematicDevice.sourceId,
                    telematicType: telematicDevice.type,
                    vehicleName: telematicDevice.name,
                    telematicSerialNumber: telematicDevice.serialNumber
                }
            },
            onExecute: (apiOptions, params) => {
                if (params === undefined) {
                    throw new Error('Parameters is undefined');
                }

                const deviceManagementApi = DeviceManagementApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return deviceManagementApi.apiV1DeviceassociationPost(params);
            },
            onOk: () => {
                return {};
            },
            onError: () => {
                //NOOP
            },
        });
    }

    public async checkCurrentTelematicDeviceAssociation(fleetId:number, telematicProviderId:string, telematicDeviceId:string): Promise<void | DeviceAssociationValidationResponse> {
        return managedAjaxUtil.fetchResults({
            ajaxStateProperty: 'TelematicValidationResult',
            freezer: this.freezer,
            params: {
                telematicDeviceId,
                fleetId,
                telematicProviderId
            },
            onExecute: (apiOptions, params) => {
                if (params === undefined) {
                    throw new Error('Parameters is undefined');
                }

                const deviceManagementApi = DeviceManagementApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return deviceManagementApi.apiV1TelematicDeviceAssociationGet(params)
            },
            onOk: (data: DeviceAssociationValidationResponse) => {
                return data;
            },
            onError: () => {
                //NOOP
            },
        });
    }


    public async checkCurrentCameraAssociation(fleetId:number, cameraId:number): Promise<void | DeviceAssociationValidationResponse> {
        return managedAjaxUtil.fetchResults({
            ajaxStateProperty: 'CameraValidationResult',
            freezer: this.freezer,
            params: {
                cameraId,
                fleetId
            },
            onExecute: (apiOptions, params) => {
                if (params === undefined) {
                    throw new Error('Parameters is undefined');
                }

                const deviceManagementApi = DeviceManagementApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return deviceManagementApi.apiV1CameraAssociationGet(params)
            },
            onOk: (data: DeviceAssociationValidationResponse) => {
                return data;
            },
            onError: () => {
                //NOOP
            },
        });
    }

    public clearTelematicAssociationValidation(){
        this.freezer.get().set({ TelematicValidationResult: managedAjaxUtil.createInitialState()});
    }

    public clearCameraAssociationValidation(){
        this.freezer.get().set({ CameraValidationResult: managedAjaxUtil.createInitialState()});
    }
}

export const DeviceAssociationService = new DeviceAssociationFreezerService();
export type IDeviceAssociationServiceInjectedProps = ReturnType<DeviceAssociationFreezerService['getPropsForInjection']>;