import { React, bind } from '../../Imports';
import { Autocomplete, TextField } from 'MaterialUIComponents';

interface IAutocompleteDropdownProps {
    name: string;
    items: any[];
    selectedValue: any | undefined;
    onSelectedItemChange: (value: any) => void;
    error?: boolean;
}

interface IAutocompleteDropdownState {
    selectedValue: any;
}

const styles = require('./VPAutocompleteDropdown.scss') as {
    main: string;
};

export default class VPAutocompleteDropdown extends React.PureComponent<
    IAutocompleteDropdownProps,
    IAutocompleteDropdownState
> {
    state = {
        selectedValue: this.getSelectedValue(),
    };

    @bind
    handleChange(value: any): void {
        const { onSelectedItemChange } = this.props;
        const val = value ? value.value : '';
        onSelectedItemChange(val);
    }

    @bind
    getSelectedValue(): any {
        this.props.items.forEach((item) => {
            if (item.value == this.props.selectedValue) {
                return item;
            }
        });
    }

    render(): JSX.Element {
        return (
            <Autocomplete
                id={this.props.name}
                options={this.props.items}
                getOptionLabel={(option): any => option.label}
                className={styles.main}
                onChange={(event: any, newValue: any): void => {
                    this.handleChange(newValue);
                }}
                value={this.getSelectedValue()}
                renderInput={(params): JSX.Element => <TextField {...params} label={this.props.name} />}
            />
        );
    }
}
