import { React, bind, _ } from '../../Imports';
import { Select, FormControl, InputLabel, Input, MenuItem, ListItemText, Checkbox } from 'MaterialUIComponents';
import { IDropdownItem } from './VPDropdown';

interface IMultiselectProps {
    onSelectedItemsChange: (value: any[]) => void;
    selectedValues: any[] | undefined;
    items: IDropdownItem[];
    name: string;
}

interface IMultiselectState {
    selectedValues: any[];
}

const styles = require('./VPMultiselect.scss') as {
    select: string;
};

export class VPMultiselect extends React.Component<IMultiselectProps, IMultiselectState> {
    state: IMultiselectState = {
        selectedValues: this.props.selectedValues ? this.props.selectedValues : [],
    };

    @bind
    handleChange(event: any): void {
        const values = event.target.value;
        this.setState({
            selectedValues: values,
        });
        this.props.onSelectedItemsChange(values);
    }

    @bind
    renderValue(): string {
        return this.state.selectedValues.join(', ');
    }

    render(): JSX.Element {
        return (
            <FormControl className={styles.select}>
                <InputLabel id="select-multiple">{this.props.name}</InputLabel>
                <Select
                    labelId={'select-multiple'}
                    multiple={true}
                    value={this.state.selectedValues}
                    onChange={this.handleChange}
                    input={<Input id="select-multiple-input" />}
                    renderValue={this.renderValue}
                    className={styles.select}
                >
                    {this.props.items.map((item) => (
                        <MenuItem key={item.label} value={item.value}>
                            <Checkbox
                                color={'primary'}
                                checked={
                                    this.state.selectedValues
                                        ? this.state.selectedValues.indexOf(item.value.toString()) > -1
                                        : undefined
                                }
                            />
                            <ListItemText primary={item.label} />
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        );
    }
}
