import { _, FreezerService, managedAjaxUtil, IAjaxState } from 'Imports';
import { IdentityApiFactory, LoginUserRequest } from '$Generated/api';
import { CurrentUserService } from '$State/CurrentUserFreezer';

const InjectedPropName = 'login';

interface ILoginState {
    userLoginResult: IAjaxState<any>;
    hasError: boolean;
}

class LoginFreezerService extends FreezerService<ILoginState, typeof InjectedPropName> {
    constructor() {
        super(
            {
                userLoginResult: managedAjaxUtil.createInitialState(),
                hasError: false,
            },
            InjectedPropName,
        );
    }

    public async loginUser(userObj: LoginUserRequest): Promise<any> {
        return managedAjaxUtil.fetchResults({
            ajaxStateProperty: 'userLoginResult',
            freezer: this.freezer,
            onExecute: (apiOptions, params, options) => {
                if (params === undefined) {
                    throw new Error('Parameters is undefined');
                }

                const identityApi = IdentityApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return identityApi.apiV1LoginPost(params);
            },
            params: {
                body: userObj,
            },
            onOk: (data: any) => {
                if (data !== undefined) {
                    this.freezer.get().set({ hasError: false });

                    if (data.status === 200) {
                        CurrentUserService.getCurrentUser(true);
                    } else {
                        return 'unable to login';
                    }
                }

                return data;
            },
            onError: () => {
                this.freezer.get().set({ hasError: true });
            },
        });
    }
}

export const LoginService = new LoginFreezerService();
export type ILoginServiceInjectedProps = ReturnType<LoginFreezerService['getPropsForInjection']>;
