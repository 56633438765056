// eslint-disable-next-line @typescript-eslint/no-var-requires
const scssStyles = require('../../../../css/settings.scss');
import { Button } from '../../../../MaterialUIComponents';
import styled from '@emotion/styled';

type StyledOverrideButtonProps = {
    color?: string;
    backgroundColor?: string;
    fontWeight?: string;
    fontFamily?: string;
};

const StyledBaseButton = styled(Button)`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px 20px;
    gap: 10px;
    line-height: 15px;
    font-size: 15px;
    text-transform: none;
`;

const StyledSubmitButton = styled(StyledBaseButton)<StyledOverrideButtonProps>`
    color: ${(props) => props.color ?? scssStyles.submitButtonTextColor};
    background-color: ${(props) => props.backgroundColor ?? scssStyles.submitButtonBackgroundColor};
    font-weight: ${(props) => props.fontWeight ?? scssStyles.fontWeight};
    font-family: ${(props) => props.fontFamily ?? scssStyles.fontFamily};
    &:disabled {
        border: 1px solid #989898;
        color: #989898;
        background-color: white;
    }
    &:hover {
        background-color: ${(props) => props.backgroundColor ?? scssStyles.submitButtonBackgroundColor};
    }
`;

const StyledResetButton = styled(StyledBaseButton)<StyledOverrideButtonProps>`
    color: ${(props) => props.color ?? scssStyles.resetButtonTextColor};
    background-color: ${(props) => props.backgroundColor ?? scssStyles.resetButtonBackgroundColor};
    font-weight: ${(props) => props.fontWeight ?? scssStyles.fontWeight};
    font-family: ${(props) => props.fontFamily ?? scssStyles.fontFamily};
    &:disabled {
        border: 1px solid #989898;
        color: #989898;
        background-color: white;
    }
    &:hover {
        background-color: ${(props) => props.backgroundColor ?? scssStyles.resetButtonBackgroundColor};
    }
`;

const StyledRoundedSubmitButton = styled(StyledBaseButton)<StyledOverrideButtonProps>`
    border-radius: 30px;
    padding: 10px 24px;
    font-size: 16px;
    font-family: 'Roboto', sans-serif;
    text-transform: none;
    font-weight: 400;
    color: #6b7280;
    background-color: white;
    border: 1px solid #6b7280;
    &:hover {
        background-color: white;
    }

    &:disabled {
        color: #b9c0cb;
        background-color: #dee3ec;
        border: 0px solid #6b7280;
    }
`;

const StyledRoundedCancelButton = styled(StyledBaseButton)<StyledOverrideButtonProps>`
    border-radius: 30px;
    padding: 10px 24px;
    font-size: 16px;
    font-family: 'Roboto', sans-serif;
    text-transform: none;
    font-weight: 400;
    color: #6b7280;
    background-color: white;
    &:hover {
        background-color: white;
    }

    &:disabled {
        color: #b9c0cb;
        background-color: #dee3ec;
    }
`;

export { StyledSubmitButton, StyledResetButton, StyledRoundedSubmitButton, StyledRoundedCancelButton };
