import { React, moment, bind } from '../../Imports';
import { Card, Table, TableBody, TableRow, TableCell, TableSortLabel, TableHead } from '../../MaterialUIComponents';
import * as DateFormatter from '../Shared/DateFormatter';
import { DeviceHistoryService, IDeviceHistoryServiceInjectedProps } from '../../state/DeviceHistoryFreezerService';
import { HistoryDownload } from './HistoryDownload';
import { VPDropdown } from '../Shared/VPDropdown';
import { DeviceHistoryActionEnum } from '$Generated/api';
import { convertDropdownType } from '$Utilities/dataModelUtilities';

const styles = require('./HistoryCard.scss') as {
    card: string;
    menuHeader: string;
    headerIcon: string;
    sortLabel: string;
};

interface HistoryCardBaseProps {
    deviceId: number | undefined;
}

interface IHistoryCardState {
    sortBy: string;
    isAsc: boolean;
    actionFilter: DeviceHistoryActionEnum | undefined;
}

type IHistoryCardProps = HistoryCardBaseProps & IDeviceHistoryServiceInjectedProps;

const rowHeaders = [
    {
        id: 'createdOn',
        label: 'Date',
    },
    {
        id: 'createdBy',
        label: 'By',
    },
    {
        id: 'action',
        label: 'Action',
    },
    {
        id: 'statusOfAction',
        label: 'Status',
    },
];

class _HistoryCard extends React.Component<IHistoryCardProps, IHistoryCardState> {
    constructor(props: IHistoryCardProps) {
        super(props);
    }

    state = {
        sortBy: 'createdOn',
        isAsc: false,
        actionFilter: undefined,
    };

    async componentDidMount(): Promise<void> {
        await this.props.deviceHistory.getDeviceHistory(
            this.props.deviceId,
            this.state.sortBy,
            this.state.isAsc,
            this.state.actionFilter,
        );
        await this.props.deviceHistory.getHistoryFilters();
    }

    @bind
    handleSortRequest(sortBy: string): void {
        let isAsc = true;
        if (sortBy == this.state.sortBy) {
            isAsc = !this.state.isAsc;
        }

        this.setState({
            sortBy: sortBy,
            isAsc: isAsc,
        }, () => {
            DeviceHistoryService.getDeviceHistory(
                this.props.deviceId,
                this.state.sortBy,
                this.state.isAsc,
                this.state.actionFilter,
            );
        });
       
    }

    @bind
    handleFilterRequest(value: any): void {
        this.setState({
            actionFilter: value,
        });

        DeviceHistoryService.getDeviceHistory(this.props.deviceId, this.state.sortBy, this.state.isAsc, value);
    }

    render(): JSX.Element {
        const freezer = this.props.deviceHistory.getState();
        const { DeviceHistoryResults, HistoryFilterResults } = freezer;
        return (
            <Card className={styles.card}>
                <div className={styles.menuHeader}>
                    History{' '}
                    <HistoryDownload
                        data={DeviceHistoryResults.data ? DeviceHistoryResults.data : []}
                        csvTitle={'DeviceHistory'}
                    />
                </div>
                <VPDropdown
                    items={convertDropdownType(HistoryFilterResults.data)}
                    name={'Action'}
                    selectedValue={this.state.actionFilter ? this.state.actionFilter : ''}
                    onSelectedItemChange={this.handleFilterRequest}
                />
                <Table>
                    <TableHead>
                        <TableRow>
                            {rowHeaders.map((head) => {
                                return (
                                    <TableCell
                                        key={head.id}
                                        sortDirection={
                                            this.state.sortBy === head.id ? (this.state.isAsc ? 'asc' : 'desc') : false
                                        }
                                    >
                                        <TableSortLabel
                                            active={this.state.sortBy === head.id}
                                            direction={this.state.isAsc ? 'asc' : 'desc'}
                                            onClick={(): void => this.handleSortRequest(head.id)}
                                            className={styles.sortLabel}
                                        >
                                            {head.label}
                                        </TableSortLabel>
                                    </TableCell>
                                );
                            })}
                            <TableCell key={'actions'}></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {DeviceHistoryResults.data
                            ? DeviceHistoryResults.data.map((history, idx) => {
                                  return (
                                      <TableRow key={idx}>
                                          <TableCell>
                                              {history.createdOn &&
                                                  DateFormatter.dateAndTimezone(moment(history.createdOn))}
                                          </TableCell>
                                          <TableCell>{history.createdBy}</TableCell>
                                          <TableCell>{history.action}</TableCell>
                                          <TableCell>{history.statusOfAction}</TableCell>
                                      </TableRow>
                                  );
                              })
                            : 
                            <TableRow>
                                <TableCell>Loading...</TableCell>
                            </TableRow>}
                    </TableBody>
                </Table>
            </Card>
        );
    }
}

export const HistoryCard = DeviceHistoryService.inject(_HistoryCard);
