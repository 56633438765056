import { React, bind } from '../../Imports';
import { Select, MenuItem, InputLabel, FormControl } from 'MaterialUIComponents';
import { IDropdownItem } from './VPDropdown';

interface IStandardDropdownProps {
    name: string;
    items: IDropdownItem[];
    selectedValue: any | undefined;
    onSelectedItemChange: (value: any) => void;
}

const styles = require('./VPStandardDropdown.scss') as {
    main: string;
    menuItem: string;
    renderedValue: string;
};

export class VPStandardDropdown extends React.PureComponent<IStandardDropdownProps> {
    @bind
    getMenuItems(items: IDropdownItem[]): React.ReactNode[] {
        return items.map((item, idx) => {
            return (
                <MenuItem key={idx} value={item.value} selected={this.props.selectedValue == item.value}>
                    <div className={styles.menuItem}>
                        {item.label}
                        {item.iconUrl && <img src={item.iconUrl} />}
                    </div>
                </MenuItem>
            );
        });
    }

    @bind
    getRenderedValue(selectedItem: any | undefined): React.ReactNode {
        if (selectedItem) {
            let selectItem: IDropdownItem | undefined;
            this.props.items.forEach((item) => {
                if (item.value == selectedItem) {
                    selectItem = item;
                }
            });
            if (selectItem) {
                return (
                    <div className={styles.renderedValue}>
                        {selectItem.label}
                        {selectItem.iconUrl && <img src={selectItem.iconUrl} />}
                    </div>
                );
            }
        }

        return '';
    }

    @bind
    handleChange(event: any): void {
        const value: any | undefined = event.target.value;
        this.props.onSelectedItemChange(value);
    }

    render(): JSX.Element {
        const { items, name, selectedValue } = this.props;

        return (
            <FormControl>
                <InputLabel id={name}>{name}</InputLabel>
                <Select
                    className={styles.main}
                    labelId={name}
                    id={name + '-select'}
                    value={selectedValue}
                    onChange={this.handleChange}
                    renderValue={(): any => this.getRenderedValue(selectedValue)}
                >
                    {this.getMenuItems(items)}
                </Select>
            </FormControl>
        );
    }
}
