import { React } from '../../Imports';
import { VPStandardDropdown } from './VPStandardDropdown';
import VPAutocompleteDropdown from './VPAutocompleteDropdown';
import VPAutocompleteMultiselect from './VPAutocompleteMultiselect';
import { VPMultiselect } from './VPMultiselect';

interface IDropdownProps {
    name: string;
    items: IDropdownItem[];
    selectedValue?: any | undefined;
    selectedValues?: any[] | undefined;
    isAutocomplete?: boolean;
    isMultiselect?: boolean;
    isCombined?: boolean;
    error?: boolean;
    onSelectedItemChange?: (value: any) => void;
    onSelectedItemsChange?: (value: any[]) => void;
}

export interface IDropdownItem {
    label: string;
    value: any;
    iconUrl?: string;
}

export class VPDropdown extends React.Component<IDropdownProps, {}> {
    render(): JSX.Element {
        const { isAutocomplete, isMultiselect, isCombined } = this.props;

        if (isMultiselect) {
            return (
                <VPMultiselect
                    onSelectedItemsChange={
                        this.props.onSelectedItemsChange ? this.props.onSelectedItemsChange : (): void => {}
                    }
                    selectedValues={this.props.selectedValues}
                    name={this.props.name}
                    items={this.props.items}
                />
            );
        }
        if (isAutocomplete) {
            return (
                <VPAutocompleteDropdown
                    selectedValue={this.props.selectedValue}
                    onSelectedItemChange={
                        this.props.onSelectedItemChange ? this.props.onSelectedItemChange : (): void => {}
                    }
                    name={this.props.name}
                    items={this.props.items}
                />
            );
        }
        if (isCombined) {
            return (
                <VPAutocompleteMultiselect
                    selectedValues={this.props.selectedValues}
                    onSelectedItemChange={
                        this.props.onSelectedItemsChange ? this.props.onSelectedItemsChange : (): void => {}
                    }
                    name={this.props.name}
                    items={this.props.items}
                />
            );
        } else {
            return (
                <VPStandardDropdown
                    selectedValue={this.props.selectedValue}
                    onSelectedItemChange={
                        this.props.onSelectedItemChange ? this.props.onSelectedItemChange : (): void => {}
                    }
                    name={this.props.name}
                    items={this.props.items}
                />
            );
        }
    }
}
