import { React, moment } from '../../Imports';
import { Card, Table, TableBody, TableRow, TableCell, Button } from '../../MaterialUIComponents';
import * as DateFormatter from '../Shared/DateFormatter';
import { GetAppIcon } from '../../MaterialUIIcons';

const styles = require('./SystemLogsCard.scss') as {
    card: string;
    menuHeader: string;
    button: string;
    buttonRow: string;
    icon: string;
};

export class SystemLogsCard extends React.PureComponent<any, {}> {
    render(): JSX.Element {
        return (
            <Card className={styles.card}>
                <div className={styles.menuHeader}>System Logs</div>
                <Table>
                    <TableBody>
                        <TableRow key={'log1'}>
                            <TableCell>{DateFormatter.dateAndTimezone(moment())}</TableCell>
                            <TableCell>Pending</TableCell>
                        </TableRow>
                        <TableRow key={'log2'}>
                            <TableCell>{DateFormatter.dateAndTimezone(moment())}</TableCell>
                            <TableCell>
                                <GetAppIcon className={styles.icon} />
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
                <div className={styles.buttonRow}>
                    <Button className={styles.button}>Get Logs</Button>
                </div>
            </Card>
        );
    }
}
