// #region Imports
import { Drawer, withStyles, WithStyles, CSSProperties, CircularProgress, ThemeProvider, createTheme, StyledEngineProvider } from 'MaterialUIComponents';

// framework
import { cx } from '@videoplatform/css-helpers';
import { getLogger } from '@videoplatform/logging';
import { ISinglePageApplicationProps } from '@videoplatform/react-spa';

import * as s from 'underscore.string';

// application
import { ApiContext, IApplicationOptions } from './utilities/api';
import { CurrentUserService, ICurrentUserInjectedProps } from './state/CurrentUserFreezer';
import { MenuBar } from './components/Shared/MenuBar';
import { NavigationMenu } from './components/Shared/NavigationMenu';
import { React, Router, bind } from './Imports';
import { IConfigServiceInjectedProps, ConfigService } from '$State/ConfigFreezerService';
import { PageRouter } from '$Pages/PageRouter';
import { GetImageUrl } from '$Utilities/dataModelUtilities';

import { init as initApm } from '@elastic/apm-rum';
// #endregion Imports

// Include the global styles
require('./css/global.scss');

const styles = require('./Application.scss') as {
    active: string;
    link: string;
    logo: string;
    main: string;
    navbar: string;
    page: string;
    pageOuter: string;
    toolbar: string;
};

const theme = createTheme({
    components: {
        MuiTextField: {
            defaultProps: {
                variant: 'standard',
            },
        },
    },
});

const muiStyles = () => ({
    root: {
        flexGrow: 1,
        height: 430,
        zIndex: 1,
        overflow: 'hidden',
        position: 'relative',
        display: 'flex',
    } as CSSProperties,
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
    },
    drawerPaper: {
        position: 'relative',
        width: 190,
    } as CSSProperties,
    content: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.default,
        padding: '0 10px',
        minHeight: 0,
        minWidth: 0, // So the Typography noWrap works
    },
    toolbar: theme.mixins.toolbar,
});

const logger = getLogger('Application');

interface IApplicationState {
    apiOptions: IApplicationOptions;
    selectedId: number;
    imagesBucket: string;
    imagesBucketRegion: string;
    userChecked: boolean;
}

type IApplicationProps = ISinglePageApplicationProps &
    IConfigServiceInjectedProps &
    WithStyles<keyof ReturnType<typeof muiStyles>> &
    ICurrentUserInjectedProps;

export class _Application extends React.Component<IApplicationProps, IApplicationState> {
    state = {
        apiOptions: {
            // The current api generator can't handle "/" as a base url, so we replace that with a
            // fully qualified base URL with trailing slashes removed
            applicationRoot: this.props.baseUrl,
            apiBaseUrl: s.rtrim(`${this.props.apiBaseUrl}`, '/'),
            wrappedFetch: fetch,
        },
        selectedId: -1,
        imagesBucket: '',
        imagesBucketRegion: '',
        userChecked: false,
    };

    parseURL(url: string | null | undefined): any {
        const parser = document.createElement('a');
        // Let the browser do the work
        parser.href = url === null || url === undefined ? '' : url;
        return {
            protocol: parser.protocol,
            host: parser.host,
            hostname: parser.hostname,
            port: parser.port,
            pathname: parser.pathname,
            search: parser.search,
            hash: parser.hash,
        };
    }

    isPublicRoute(): boolean {
        const paths = ['/Login', '/SetPassword', '/PasswordReset', '/ForgotPassword'];
        return paths.findIndex((p) => p.toLocaleLowerCase() == location.pathname.toLocaleLowerCase()) === -1;
    }

    async componentDidMount(): Promise<void> {
        //get the current user to confirm authenticated
        await this.props.config.getClientConfig();
        this.props.setFaviconUrl(this.GetImageBucketUrl('favicon.ico'));
        if (this.isPublicRoute()) {
            await this.props.currentUser.getCurrentUser(true).catch(() => {});
        }
        this.setState({ userChecked: true });
        const apm = initApm({
            // Set required service name (allowed characters: a-z, A-Z, 0-9, -, _, and space)
            serviceName: ConfigService.getApmServiceName(), //'ui_vprotect',
            // Set custom APM Server URL
            serverUrl: ConfigService.getApmServiceUrl(),
            // Set service environment (required for sourcemap feature)
            environment: ConfigService.getDeployEnvironment(),
        });
        apm.addLabels({ IntegrationPlatform: ConfigService.getIntegrationPlatform() });
    }

    @bind
    onSelectId(id: number): void {
        this.setState({ selectedId: id });
    }

    @bind
    GetImageBucketUrl(filename: string): string {
        const currentImagesBucket = this.props.config.getState().clientConfigResults.data?.imagesBucket;
        const currentRegion = this.props.config.getState().clientConfigResults.data?.imagesBucketRegion;
        // If config hasn't been set yet, return an empty location for the image src
        return currentImagesBucket && currentRegion ? GetImageUrl(currentImagesBucket, currentRegion, filename) : '//:0';
    }

    render(): JSX.Element {
        logger.info('Rendering application');
        const isFetchingCurrentUser = this.props.currentUser.getState().result.isFetching;
        const { userChecked } = this.state;
        if (isFetchingCurrentUser || !userChecked) {
            return <CircularProgress />;
        }
        const hasFetchedCurrentUser = this.props.currentUser.getState().result.hasFetched;
        const currentUser = this.props.currentUser.getState().result;

        return (
            <StyledEngineProvider injectFirst>
                <ThemeProvider theme={theme}>
                    <div className={cx([this.props.classes.root, styles.main])}>
                        <ApiContext.Provider value={this.state.apiOptions}>
                            <Router>
                                <>
                                    {hasFetchedCurrentUser && currentUser.data && (
                                        <Drawer
                                            variant="permanent"
                                            classes={{
                                                paper: `${styles.navbar}`,
                                            }}
                                        >
                                            <NavigationMenu onSelectId={this.onSelectId} />
                                        </Drawer>
                                    )}
                                    <main className={this.props.classes.content} style={{ paddingLeft: 0, paddingRight: 0, minHeight: 0 }}>
                                        {hasFetchedCurrentUser && currentUser.data && (
                                            <div className={this.props.classes.toolbar}>
                                                <MenuBar username={currentUser.data?.firstName + ' ' + currentUser.data?.lastName} />
                                            </div>
                                        )}
                                        <div className={styles.pageOuter}>
                                            <div className={styles.page}>
                                                <PageRouter {...this.props} selectedId={this.state.selectedId} onSelectId={this.onSelectId} />
                                            </div>
                                        </div>
                                    </main>
                                </>
                            </Router>
                        </ApiContext.Provider>
                    </div>
                </ThemeProvider>
            </StyledEngineProvider>
        );
    }
}

const Application = withStyles(muiStyles)(CurrentUserService.inject(ConfigService.inject(_Application)));

export { Application, IApplicationProps };
