/* eslint-disable prefer-const */
import { React, _, bind } from '../../Imports';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, MenuItem } from '../../MaterialUIComponents';
import { UpdateUserRequest, AdminUserResponse } from '$Generated/api';
import { ValidatorForm, TextValidator } from 'MaterialUIComponents';
import { IDropdownItem, VPDropdown } from '$Components/Shared/VPDropdown';
import { SelectValidator } from 'react-material-ui-form-validator';

const styles = require('./EditUserDialog.scss') as {
    main: string;
    actionButton: string;
    dialogContent: string;
};

interface IEditUserDialogProps {
    onEditUser: (user: UpdateUserRequest) => void;
    visible: boolean;
    toggleDialog: (user: AdminUserResponse) => void;
    userToEdit: AdminUserResponse;
}

interface IEditUserDialogState {
    user: UpdateUserRequest;
    selectedRole: string[];
}

class _EditUserDialog extends React.Component<IEditUserDialogProps, IEditUserDialogState> {
    constructor(props: IEditUserDialogProps) {
        super(props);
    }
    state = {
        user: {
            id: this.props.userToEdit.id,
            userName: this.props.userToEdit.userName,
            email: this.props.userToEdit.email,
            firstName: this.props.userToEdit.firstName,
            lastName: this.props.userToEdit.lastName,
            role: this.props.userToEdit.role,
        } as UpdateUserRequest,
        selectedRole: [],
    };

    componentDidUpdate(nextProps: IEditUserDialogProps): void {
        if (this.props.visible !== nextProps.visible) {
            const state = {
                user: {
                    id: this.props.userToEdit.id,
                    userName: this.props.userToEdit.userName,
                    email: this.props.userToEdit.email,
                    firstName: this.props.userToEdit.firstName,
                    lastName: this.props.userToEdit.lastName,
                    role: this.props.userToEdit.role,
                } as UpdateUserRequest,
            };
            this.setState(state);
        }
    }

    private adminRoles: IDropdownItem[] = [
        { label: 'AdminUIView', value: 'AdminUIView' },
        { label: 'AdminUIEdit', value: 'AdminUIEdit' },
        { label: 'AdminUIAdmin', value: 'AdminUIAdmin' },
        { label: 'AdminUISuperAdmin', value: 'AdminUISuperAdmin' },
    ];

    @bind
    closeDialog(): void {
        this.props.toggleDialog({
            id: '',
            userName: '',
            firstName: '',
            lastName: '',
            email: '',
            role: '',
        } as AdminUserResponse);
    }

    @bind
    cancelEditUser(): void {
        this.setState({
            user: {
                id: '',
                userName: '',
                firstName: '',
                lastName: '',
                email: '',
                role: '',
            } as UpdateUserRequest,
        });
        this.closeDialog();
    }

    @bind
    editUser(): void {
        this.setState({ user: { ...(this.state.user as UpdateUserRequest) } });
        this.props.onEditUser(this.state.user as UpdateUserRequest);
        this.closeDialog();
    }

    @bind
    handleFieldChange(field: string, event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void {
        let user: AdminUserResponse = _.clone(this.state.user);
        switch (field) {
            case 'FirstName':
                user.firstName = event.target.value;
                break;
            case 'LastName':
                user.lastName = event.target.value;
                break;
            case 'Email':
                user.email = event.target.value;
                break;
            case 'Role':
                user.role = event.target.value;
                break;
        }
        this.setState({ user: user as UpdateUserRequest });
    }

    render(): JSX.Element {
        return (
            <div className={styles.main}>
                <Dialog open={this.props.visible}>
                    <ValidatorForm ref={'editUser'} onSubmit={this.editUser} onError={(errors: any[]): void => console.log(errors)}>
                        <DialogTitle>Edit User</DialogTitle>
                        <DialogContent className={styles.dialogContent}>
                            <TextValidator
                                label="First Name"
                                margin="dense"
                                fullWidth={true}
                                variant="outlined"
                                onChange={(e: any): void => this.handleFieldChange('FirstName', e)}
                                name="firstName"
                                value={this.state.user.firstName}
                                validators={['required']}
                                errorMessages={['This field is required']}
                            />
                            <TextValidator
                                label="Last Name"
                                margin="dense"
                                fullWidth={true}
                                variant="outlined"
                                onChange={(e: any): void => this.handleFieldChange('LastName', e)}
                                name="lastName"
                                value={this.state.user.lastName}
                                validators={['required']}
                                errorMessages={['This field is required']}
                            />
                            <TextValidator
                                label="Email Address"
                                margin="dense"
                                fullWidth={true}
                                variant="outlined"
                                onChange={(e: any): void => this.handleFieldChange('Email', e)}
                                name="emailAddress"
                                style={{ width: '450px' }}
                                value={this.state.user.email}
                                validators={['required', 'isEmail']}
                                errorMessages={['This field is required', 'Email is not valid']}
                            />
                            <SelectValidator
                                label="Role"
                                name={'Role'}
                                fullWidth={true}
                                value={this.state.user.role}
                                validators={['required']}
                                errorMessages={['This field is required']}
                                onChange={(e: any): void => this.handleFieldChange('Role', e)}
                            >
                                {this.adminRoles.map((item, idx) => {
                                    return (
                                        <MenuItem value={item.value} key={idx}>
                                            {item.label}
                                        </MenuItem>
                                    );
                                })}
                            </SelectValidator>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.cancelEditUser} className={styles.actionButton}>
                                Cancel
                            </Button>
                            <Button type="submit" className={styles.actionButton}>
                                Save
                            </Button>
                        </DialogActions>
                    </ValidatorForm>
                </Dialog>
            </div>
        );
    }
}

export const EditUserDialog = _EditUserDialog;
