import { React, bind, RouteComponentProps, withRouter } from 'Imports';
import { TextField, Button, Card, Typography, CardContent } from 'MaterialUIComponents';
import { ILoginServiceInjectedProps, LoginService } from '$State/LoginFreezer';
import { ConfigService, IConfigServiceInjectedProps } from '$State/ConfigFreezerService';
import { GetImageUrl } from '$Utilities/dataModelUtilities';

interface ILoginBaseProps {}

type ILoginProps = ILoginBaseProps & ILoginServiceInjectedProps & IConfigServiceInjectedProps & RouteComponentProps;

interface ILoginState {
    username: string;
    password: string;
    errorMsg: string;
    visiblePassword: string;
}

const styles = require('./Login.scss') as {
    main: string;
    loginbutton: string;
    content: string;
    redText: string;
    link: string;
};

class _LoginPage extends React.Component<ILoginProps, ILoginState> {
    state: ILoginState = {
        username: '',
        password: '',
        errorMsg: '',
        visiblePassword: '',
    };

    async login(username: string, password: string) {
        this.setState({ errorMsg: '' });
        await this.props.login.loginUser({ username, password }).then(() => {
            const { history } = this.props;
            history.push('/');
        });
    }

    usernameChange(val: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) {
        this.setState({ username: val.target.value });
    }

    @bind
    passwordChange(val: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) {
        let password = this.state.password;
        let inputValue = val.target.value;
        let newPassword;

        if (inputValue.length == 0) {
            this.setState({ password: '', visiblePassword: '' });
        } else if (inputValue.length == this.state.password.length - 1) {
            newPassword = password?.substring(0, password?.length - 1);
            this.setState({ password: newPassword, visiblePassword: '*'.repeat(newPassword?.length) });
        } else if (inputValue.length > this.state.password.length + 1 || inputValue.length < this.state.password.length - 1) {
            this.setState({ password: inputValue, visiblePassword: '*'.repeat(inputValue.length) });
        } else {
            this.setState({ password: password + inputValue.slice(-1), visiblePassword: '*'.repeat(inputValue.length) });
        }
    }

    GetImageBucketUrl(filename: string): string {
        const currentImagesBucket = this.props.config.getState().clientConfigResults.data?.imagesBucket;
        const currentRegion = this.props.config.getState().clientConfigResults.data?.imagesBucketRegion;
        // If config hasn't been set yet, return an empty location for the image src
        return currentImagesBucket && currentRegion ? GetImageUrl(currentImagesBucket, currentRegion, filename) : '//:0';
    }

    goToForgotPassword(): void {
        const { history } = this.props;
        history.push('/ForgotPassword');
    }

    render() {
        return (
            <div className={styles.main}>
                <img src={this.GetImageBucketUrl('logo.png')} />
                <Card className={styles.content}>
                    <CardContent>
                        <Typography variant="h5" component="h2">
                            <b>Login</b>
                        </Typography>
                        {this.props.login.getState().hasError && (
                            <Typography className={styles.redText}>There was an error with your credentials.</Typography>
                        )}
                    </CardContent>
                    <TextField
                        label={'Username'}
                        placeholder={'Username'}
                        onChange={(e) => this.usernameChange(e)}
                        value={this.state.username}
                    />
                    <TextField
                        label={'Password'}
                        type="password"
                        placeholder={'Password'}
                        onChange={(e) => this.passwordChange(e)}
                        value={this.state.visiblePassword}
                    />
                    <Button className={styles.loginbutton} onClick={(e) => this.login(this.state.username, this.state.password)}>
                        Login
                    </Button>
                    <div className={styles.link} onClick={() => this.goToForgotPassword()}>
                        Forgot Password
                    </div>
                </Card>
            </div>
        );
    }
}

export const LoginPage = withRouter(LoginService.inject(ConfigService.inject(_LoginPage)));
