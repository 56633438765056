import { _, FreezerService, managedAjaxUtil, IAjaxState } from 'Imports';
import { IdentityApiFactory, UserResponse } from '$Generated/api';

const InjectedPropName = 'currentUser';

interface ICurrentUserState {
    result: IAjaxState<UserResponse>;
    logout: IAjaxState<any>;
    hasError: boolean;
    isLoggedIn: boolean;
}

class CurrentUserFreezerService extends FreezerService<ICurrentUserState, typeof InjectedPropName> {
    constructor() {
        super(
            {
                result: managedAjaxUtil.createInitialState(),
                hasError: false,
                logout: managedAjaxUtil.createInitialState(),
                isLoggedIn: false,
            },
            InjectedPropName,
        );
    }

    public getCurrentUserState() {
        try {
            return this.getState().result;
        } catch (e) {
            console.error('UserManagementFreezer.getCurrentUserState(): ' + e);
        }
    }

    public async getCurrentUser(isLoggingIn: boolean = false): Promise<void | UserResponse> {
        if (isLoggingIn) {
            await this.freezer.get().set({ isLoggedIn: true });
        }
        if (!this.getState().isLoggedIn && !isLoggingIn) {
            return;
        }
        return managedAjaxUtil.fetchResults({
            ajaxStateProperty: 'result',
            freezer: this.freezer,
            onExecute: (apiOptions, params, options) => {
                if (params === undefined) {
                    throw new Error('Parameters is undefined');
                }
                const identityApi = IdentityApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return identityApi.apiV1CurrentUserGet(params);
            },
            params: {
                body: null,
            },
            onOk: (data: UserResponse) => {
                if (data !== undefined) {
                    this.freezer.get().set({ hasError: false, isLoggedIn: true });
                }
                return data;
            },
            onError: (e) => {
                if (e.statusCode !== 401) {
                    this.freezer.get().set({ result: managedAjaxUtil.createInitialState() });
                    this.freezer.get().set({ hasError: true, isLoggedIn: false });
                }
            },
        });
    }

    public async logout(): Promise<any> {
        return managedAjaxUtil.fetchResults({
            ajaxStateProperty: 'logout',
            freezer: this.freezer,
            onExecute: (apiOptions, params, options) => {
                if (params === undefined) {
                    throw new Error('Parameters is undefined');
                }

                const identityApi = IdentityApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return identityApi.apiV1LogoutPost();
            },
            params: {
                body: null,
            },
            onOk: (data: any) => {
                this.freezer.get().set({ result: managedAjaxUtil.createInitialState(), isLoggedIn: false });
            },
            onError: () => {
                this.freezer.get().set({ hasError: true });
            },
        });
    }
}

export const CurrentUserService = new CurrentUserFreezerService();
export type ICurrentUserInjectedProps = ReturnType<CurrentUserFreezerService['getPropsForInjection']>;
