import { React } from '../../Imports';
import { PageHeader } from '../../components/Shared/PageHeader';

interface IHomeBaseProps {}

type IHomeProps = IHomeBaseProps;

interface IHomeState {}

const styles = require('./Home.scss') as {
    main: string;
    contentContainer: string;
    content: string;
    filterContainer: string;
};

class _HomePage extends React.Component<IHomeProps, IHomeState> {
    state: IHomeState = {};

    render(): JSX.Element {
        return (
            <div className={styles.main}>
                <div className={styles.contentContainer}>
                    <PageHeader pageTitle={'AdminUI Home Page'} />
                </div>
            </div>
        );
    }
}

export const HomePage = _HomePage;
