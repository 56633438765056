import { React } from '../../Imports';

const styles = require('./PageHeader.scss') as {
    main: string;
    pageTitle: string;
    pageBack: string;
};

interface IPageHeaderProps {
    pageTitle: string;
}

export class PageHeader extends React.PureComponent<IPageHeaderProps, {}> {
    render(): JSX.Element {
        return (
            <div className={styles.main}>
                <div className={styles.pageTitle}>{this.props.pageTitle}</div>
            </div>
        );
    }
}
