/**
 * API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import * as querystring from "querystring";
import * as url from "url";

const isomorphicFetch = fetch;
const assign: (target: any, ...sources: any[]) => any = (Object as any).assign;

interface Dictionary<T> { [index: string]: T; }
export interface FetchAPI { (url: string, init?: any): Promise<any>; }

const BASE_PATH = "https://localhost".replace(/\/+$/, "");

export interface FetchArgs {
    url: string;
    options: any;
}

export class BaseAPI {
    basePath: string;
    fetch: FetchAPI;

    constructor(fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) {
        this.basePath = basePath;
        this.fetch = fetch;
    }
};

export interface AddAgreementVersionRequest {
    "description"?: string;
    "effectiveDate"?: Date;
}

export interface AdminDashboardResponse {
    "onlineCameras"?: number;
    "offlineCameras"?: number;
    "eventCountToday"?: number;
    "eventCountSevenDays"?: number;
}

export interface AdminUserResponse {
    "id"?: string;
    "userName"?: string;
    "normalizedUserName"?: string;
    "email"?: string;
    "normalizedEmail"?: string;
    "emailConfirmed"?: boolean;
    "passwordHash"?: string;
    "securityStamp"?: string;
    "concurrencyStamp"?: string;
    "phoneNumber"?: string;
    "phoneNumberConfirmed"?: boolean;
    "twoFactorEnabled"?: boolean;
    "lockoutEnd"?: Date;
    "lockoutEnabled"?: boolean;
    "accessFailedCount"?: number;
    "firstName"?: string;
    "lastName"?: string;
    "lastLogin"?: Date;
    "isActive"?: boolean;
    "role"?: string;
}

export interface Assembly {
    "definedTypes"?: Array<TypeInfo>;
    "exportedTypes"?: Array<Type>;
    "codeBase"?: string;
    "entryPoint"?: MethodInfo;
    "fullName"?: string;
    "imageRuntimeVersion"?: string;
    "isDynamic"?: boolean;
    "location"?: string;
    "reflectionOnly"?: boolean;
    "isCollectible"?: boolean;
    "isFullyTrusted"?: boolean;
    "customAttributes"?: Array<CustomAttributeData>;
    "escapedCodeBase"?: string;
    "manifestModule"?: Module;
    "modules"?: Array<Module>;
    "globalAssemblyCache"?: boolean;
    "hostContext"?: number;
    "securityRuleSet"?: SecurityRuleSet;
}

export type AwsJobTypeEnum = "JJKApp" | "MitacBase" | "MitacRegion";

export interface BulkUpdateFleetRequest {
    "deviceUpdateFleets"?: Array<DeviceUpdateFleetRequest>;
}

export type CallingConventions = "Standard" | "VarArgs" | "Any" | "HasThis" | "ExplicitThis";

export interface CameraConfigUpdateRequest {
    "settings"?: string;
    "serialNumber"?: string;
    "configType"?: CameraDeviceConfigTypeEnum;
}

export type CameraDeviceConfigTypeEnum = "Gemini" | "AppSettings";

export interface CameraDeviceResponse {
    "id"?: number;
    "deviceType"?: string;
    "serialNumber"?: string;
    "imei"?: string;
    "iccid"?: string;
    "lastHeartbeatTime"?: Date;
    "deviceStatus"?: CameraDeviceStatusEnum;
    "currentPairing"?: VehicleCameraPairingResponse;
}

export type CameraDeviceStatusEnum = "Active" | "Suspended" | "Retired" | "Expired" | "New" | "PendingSignature" | "Pairing" | "Disabled";

export type CameraDeviceTypeEnum = "Garmin790" | "VP200D" | "IntegrationTest" | "VP210D" | "VP220D" | "TestCamera" | "VP230D" | "VP240D" | "VP220S";

export interface CameraReleaseResponse {
    "releaseVersion"?: string;
    "releaseCreatedAt"?: Date;
    "releaseCompletedAt"?: Date;
    "releaseCreatedByUser"?: string;
    "releaseStatus"?: OTAReleaseStatusEnum;
    "lastSuccessfulVersion"?: string;
}

export interface CameraResponse {
    "id"?: number;
    "fleetId"?: number;
    "cameraImei"?: string;
    "cameraSerialNumber"?: string;
    "cameraIccid"?: string;
    "isActive"?: boolean;
}

export interface ClientConfigResponse {
    "imagesBucket"?: string;
    "imagesBucketRegion"?: string;
    "apmServiceUrl"?: string;
    "apmServiceName"?: string;
    "deployEnvironment"?: string;
    "integrationPlatform"?: string;
}

export interface ConstructorInfo {
    "name"?: string;
    "declaringType"?: Type;
    "reflectedType"?: Type;
    "module"?: Module;
    "customAttributes"?: Array<CustomAttributeData>;
    "isCollectible"?: boolean;
    "metadataToken"?: number;
    "attributes"?: MethodAttributes;
    "methodImplementationFlags"?: MethodImplAttributes;
    "callingConvention"?: CallingConventions;
    "isAbstract"?: boolean;
    "isConstructor"?: boolean;
    "isFinal"?: boolean;
    "isHideBySig"?: boolean;
    "isSpecialName"?: boolean;
    "isStatic"?: boolean;
    "isVirtual"?: boolean;
    "isAssembly"?: boolean;
    "isFamily"?: boolean;
    "isFamilyAndAssembly"?: boolean;
    "isFamilyOrAssembly"?: boolean;
    "isPrivate"?: boolean;
    "isPublic"?: boolean;
    "isConstructedGenericMethod"?: boolean;
    "isGenericMethod"?: boolean;
    "isGenericMethodDefinition"?: boolean;
    "containsGenericParameters"?: boolean;
    "methodHandle"?: RuntimeMethodHandle;
    "isSecurityCritical"?: boolean;
    "isSecuritySafeCritical"?: boolean;
    "isSecurityTransparent"?: boolean;
    "memberType"?: MemberTypes;
}

export interface CreatePairingRequest {
    "fleetId"?: number;
    "cameraDeviceId"?: number;
    "vehicleName"?: string;
    "vehicleVin"?: string;
    "telematicProviderId"?: TelematicProviderIdEnum;
    "integrationPartnerVehicleId"?: string;
    "telematicType"?: string;
    "telematicSerialNumber"?: string;
    "isGpsTrackingEnabled"?: boolean;
}

export interface CreateUserRequest {
    "firstName": string;
    "lastName": string;
    "email": string;
    "roleName": string;
    "password": string;
}

export interface CustomAttributeData {
    "attributeType"?: Type;
    "constructor"?: ConstructorInfo;
    "constructorArguments"?: Array<CustomAttributeTypedArgument>;
    "namedArguments"?: Array<CustomAttributeNamedArgument>;
}

export interface CustomAttributeNamedArgument {
    "memberInfo"?: MemberInfo;
    "typedValue"?: CustomAttributeTypedArgument;
    "memberName"?: string;
    "isField"?: boolean;
}

export interface CustomAttributeTypedArgument {
    "argumentType"?: Type;
}

export interface DataUsageResponse {
    "usageDate"?: string;
    "quantity"?: number;
    "unit"?: string;
}

export interface DefaultOrCameraJobCreationRequestModel {
    "version"?: string;
}

export interface DeviceAssociationValidationResponse {
    "isCurrentlyAssociated"?: boolean;
    "associatedWithIdentifier"?: string;
    "associatedSince"?: Date;
    "associatedWithType"?: string;
}

export interface DeviceBatchError {
    "msg"?: string;
    "uniqueId"?: string;
}

export interface DeviceBatchResponse {
    "errors"?: Array<DeviceBatchError>;
    "addedDevices"?: number;
    "processedDevices"?: number;
}

export interface DeviceHealthResponse {
    "id"?: number;
    "clientName"?: string;
    "serialNumber"?: string;
    "iccid"?: string;
    "lastHeartbeatTime"?: Date;
    "lastProcessedEventTime"?: Date;
    "deviceStatus"?: string;
    "simStatus"?: string;
    "lastDataUsage"?: string;
    "lastGPSCommunication"?: Date;
    "lastKnownLocation"?: Location;
    "deviceTypeId"?: CameraDeviceTypeEnum;
}

export interface DeviceHealthResponseIEnumerablePagedResponseBase {
    "success"?: boolean;
    "error"?: Exception;
    "totalCount"?: number;
    "data"?: Array<DeviceHealthResponse>;
    "currentPage"?: number;
    "itemsPerPage"?: number;
    "totalPages"?: number;
}

export type DeviceHistoryActionEnum = "PairingCreated" | "PairingDeleted" | "PairingUpdated";

export interface DeviceHistoryResponse {
    "action"?: DeviceHistoryActionEnum;
    "statusOfAction"?: string;
    "createdOn"?: Date;
    "createdBy"?: string;
}

export interface DeviceManagementFilterDataResponse {
    "clients"?: Array<DropdownMenuItem>;
    "cameras"?: Array<DropdownMenuItem>;
    "status"?: Array<DropdownMenuItem>;
}

export interface DeviceUpdateFleetRequest {
    "serialNumber": string;
    "newFleetName": string;
    "newCompanyId": string;
    "currentFleetName": string;
}

export interface DropdownMenuItem {
    "value"?: string;
    "label"?: string;
}

export type EventAttributes = "None" | "SpecialName" | "RTSpecialName" | "ReservedMask";

export interface EventInfo {
    "name"?: string;
    "declaringType"?: Type;
    "reflectedType"?: Type;
    "module"?: Module;
    "customAttributes"?: Array<CustomAttributeData>;
    "isCollectible"?: boolean;
    "metadataToken"?: number;
    "memberType"?: MemberTypes;
    "attributes"?: EventAttributes;
    "isSpecialName"?: boolean;
    "addMethod"?: MethodInfo;
    "removeMethod"?: MethodInfo;
    "raiseMethod"?: MethodInfo;
    "isMulticast"?: boolean;
    "eventHandlerType"?: Type;
}

export interface Exception {
    "targetSite"?: MethodBase;
    "message"?: string;
    "data"?: any;
    "innerException"?: Exception;
    "helpLink"?: string;
    "source"?: string;
    "hResult"?: number;
    "stackTrace"?: string;
}

export type FieldAttributes = "PrivateScope" | "Private" | "FamANDAssem" | "Assembly" | "Family" | "FamORAssem" | "Public" | "FieldAccessMask" | "Static" | "InitOnly" | "Literal" | "NotSerialized" | "HasFieldRVA" | "SpecialName" | "RTSpecialName" | "HasFieldMarshal" | "PinvokeImpl" | "HasDefault" | "ReservedMask";

export interface FieldInfo {
    "name"?: string;
    "declaringType"?: Type;
    "reflectedType"?: Type;
    "module"?: Module;
    "customAttributes"?: Array<CustomAttributeData>;
    "isCollectible"?: boolean;
    "metadataToken"?: number;
    "memberType"?: MemberTypes;
    "attributes"?: FieldAttributes;
    "fieldType"?: Type;
    "isInitOnly"?: boolean;
    "isLiteral"?: boolean;
    "isNotSerialized"?: boolean;
    "isPinvokeImpl"?: boolean;
    "isSpecialName"?: boolean;
    "isStatic"?: boolean;
    "isAssembly"?: boolean;
    "isFamily"?: boolean;
    "isFamilyAndAssembly"?: boolean;
    "isFamilyOrAssembly"?: boolean;
    "isPrivate"?: boolean;
    "isPublic"?: boolean;
    "isSecurityCritical"?: boolean;
    "isSecuritySafeCritical"?: boolean;
    "isSecurityTransparent"?: boolean;
    "fieldHandle"?: RuntimeFieldHandle;
}

export interface FleetCameraConfigUpdateRequestModel {
    "settings"?: string;
    "fleetId"?: number;
    "deviceTypeId"?: CameraDeviceTypeEnum;
    "configType"?: CameraDeviceConfigTypeEnum;
    "removeCameraSettings"?: boolean;
}

export interface FleetJobCreationRequestModel {
    "version"?: string;
    "applyToAllCameras"?: boolean;
}

export interface FleetReleaseResponse {
    "version"?: string;
    "totalCameras"?: number;
    "successfulCameras"?: number;
    "pendingCameras"?: number;
    "failedCameras"?: number;
    "createdAt"?: Date;
    "createdByUser"?: string;
}

export interface FleetResponse {
    "id"?: number;
    "name"?: string;
    "pricingPlanTypeId"?: PricingPlanTypeEnum;
    "isActive"?: boolean;
}

export type GenericParameterAttributes = "None" | "Covariant" | "Contravariant" | "VarianceMask" | "ReferenceTypeConstraint" | "NotNullableValueTypeConstraint" | "DefaultConstructorConstraint" | "SpecialConstraintMask";

export interface HealthCheckResponse {
}

export interface HealthCheckResponseResponseBase {
    "success"?: boolean;
    "error"?: Exception;
    "totalCount"?: number;
    "data"?: HealthCheckResponse;
}

export interface ICustomAttributeProvider {
}

export interface IdentityUser {
    "id"?: string;
    "userName"?: string;
    "normalizedUserName"?: string;
    "email"?: string;
    "normalizedEmail"?: string;
    "emailConfirmed"?: boolean;
    "passwordHash"?: string;
    "securityStamp"?: string;
    "concurrencyStamp"?: string;
    "phoneNumber"?: string;
    "phoneNumberConfirmed"?: boolean;
    "twoFactorEnabled"?: boolean;
    "lockoutEnd"?: Date;
    "lockoutEnabled"?: boolean;
    "accessFailedCount"?: number;
}

export interface IntPtr {
}

export type LayoutKind = "Sequential" | "Explicit" | "Auto";

export interface Location {
    "latitude"?: number;
    "longitude"?: number;
}

export interface LogRequestResponse {
    "id"?: number;
    "status"?: string;
    "presignedUrl"?: string;
    "requestDate"?: Date;
    "completionDate"?: Date;
    "requestedBy"?: string;
}

export interface LogRequestResponseIEnumerablePagedResponseBase {
    "success"?: boolean;
    "error"?: Exception;
    "totalCount"?: number;
    "data"?: Array<LogRequestResponse>;
    "currentPage"?: number;
    "itemsPerPage"?: number;
    "totalPages"?: number;
}

export interface LoginUserRequest {
    "username"?: string;
    "password"?: string;
    "token"?: string;
}

export interface LookupResponse {
    "id"?: number;
    "displayName"?: string;
}

export interface MemberInfo {
    "memberType"?: MemberTypes;
    "declaringType"?: Type;
    "reflectedType"?: Type;
    "name"?: string;
    "module"?: Module;
    "customAttributes"?: Array<CustomAttributeData>;
    "isCollectible"?: boolean;
    "metadataToken"?: number;
}

export type MemberTypes = "Constructor" | "Event" | "Field" | "Method" | "Property" | "TypeInfo" | "Custom" | "NestedType" | "All";

export type MethodAttributes = "ReuseSlot" | "PrivateScope" | "Private" | "FamANDAssem" | "Assembly" | "Family" | "FamORAssem" | "Public" | "MemberAccessMask" | "UnmanagedExport" | "Static" | "Final" | "Virtual" | "HideBySig" | "NewSlot" | "VtableLayoutMask" | "CheckAccessOnOverride" | "Abstract" | "SpecialName" | "RTSpecialName" | "PinvokeImpl" | "HasSecurity" | "RequireSecObject" | "ReservedMask";

export interface MethodBase {
    "memberType"?: MemberTypes;
    "name"?: string;
    "declaringType"?: Type;
    "reflectedType"?: Type;
    "module"?: Module;
    "customAttributes"?: Array<CustomAttributeData>;
    "isCollectible"?: boolean;
    "metadataToken"?: number;
    "attributes"?: MethodAttributes;
    "methodImplementationFlags"?: MethodImplAttributes;
    "callingConvention"?: CallingConventions;
    "isAbstract"?: boolean;
    "isConstructor"?: boolean;
    "isFinal"?: boolean;
    "isHideBySig"?: boolean;
    "isSpecialName"?: boolean;
    "isStatic"?: boolean;
    "isVirtual"?: boolean;
    "isAssembly"?: boolean;
    "isFamily"?: boolean;
    "isFamilyAndAssembly"?: boolean;
    "isFamilyOrAssembly"?: boolean;
    "isPrivate"?: boolean;
    "isPublic"?: boolean;
    "isConstructedGenericMethod"?: boolean;
    "isGenericMethod"?: boolean;
    "isGenericMethodDefinition"?: boolean;
    "containsGenericParameters"?: boolean;
    "methodHandle"?: RuntimeMethodHandle;
    "isSecurityCritical"?: boolean;
    "isSecuritySafeCritical"?: boolean;
    "isSecurityTransparent"?: boolean;
}

export type MethodImplAttributes = "IL" | "Managed" | "Native" | "OPTIL" | "Runtime" | "CodeTypeMask" | "Unmanaged" | "ManagedMask" | "NoInlining" | "ForwardRef" | "Synchronized" | "NoOptimization" | "PreserveSig" | "AggressiveInlining" | "AggressiveOptimization" | "InternalCall" | "MaxMethodImplVal";

export interface MethodInfo {
    "name"?: string;
    "declaringType"?: Type;
    "reflectedType"?: Type;
    "module"?: Module;
    "customAttributes"?: Array<CustomAttributeData>;
    "isCollectible"?: boolean;
    "metadataToken"?: number;
    "attributes"?: MethodAttributes;
    "methodImplementationFlags"?: MethodImplAttributes;
    "callingConvention"?: CallingConventions;
    "isAbstract"?: boolean;
    "isConstructor"?: boolean;
    "isFinal"?: boolean;
    "isHideBySig"?: boolean;
    "isSpecialName"?: boolean;
    "isStatic"?: boolean;
    "isVirtual"?: boolean;
    "isAssembly"?: boolean;
    "isFamily"?: boolean;
    "isFamilyAndAssembly"?: boolean;
    "isFamilyOrAssembly"?: boolean;
    "isPrivate"?: boolean;
    "isPublic"?: boolean;
    "isConstructedGenericMethod"?: boolean;
    "isGenericMethod"?: boolean;
    "isGenericMethodDefinition"?: boolean;
    "containsGenericParameters"?: boolean;
    "methodHandle"?: RuntimeMethodHandle;
    "isSecurityCritical"?: boolean;
    "isSecuritySafeCritical"?: boolean;
    "isSecurityTransparent"?: boolean;
    "memberType"?: MemberTypes;
    "returnParameter"?: ParameterInfo;
    "returnType"?: Type;
    "returnTypeCustomAttributes"?: ICustomAttributeProvider;
}

export interface Module {
    "assembly"?: Assembly;
    "fullyQualifiedName"?: string;
    "name"?: string;
    "mdStreamVersion"?: number;
    "moduleVersionId"?: string;
    "scopeName"?: string;
    "moduleHandle"?: ModuleHandle;
    "customAttributes"?: Array<CustomAttributeData>;
    "metadataToken"?: number;
}

export interface ModuleHandle {
    "mdStreamVersion"?: number;
}

export type OTAReleaseStatusEnum = "Queued" | "InProgress" | "Succeeded" | "Failed" | "DowgradeRejected" | "Cancelled";

export type ParameterAttributes = "None" | "In" | "Out" | "Lcid" | "Retval" | "Optional" | "HasDefault" | "HasFieldMarshal" | "Reserved3" | "Reserved4" | "ReservedMask";

export interface ParameterInfo {
    "attributes"?: ParameterAttributes;
    "member"?: MemberInfo;
    "name"?: string;
    "parameterType"?: Type;
    "position"?: number;
    "isIn"?: boolean;
    "isLcid"?: boolean;
    "isOptional"?: boolean;
    "isOut"?: boolean;
    "isRetval"?: boolean;
    "hasDefaultValue"?: boolean;
    "customAttributes"?: Array<CustomAttributeData>;
    "metadataToken"?: number;
}

export type PricingPlanTypeEnum = "Base" | "Pro" | "ProPlus";

export interface ProcessControlMessage {
    "messageId"?: string;
    "queueName"?: string;
    "processId"?: string;
    "tenantId"?: string;
    "targetDataKey"?: string;
    "priority"?: number;
    "processData"?: { [key: string]: string; };
}

export type PropertyAttributes = "None" | "SpecialName" | "RTSpecialName" | "HasDefault" | "Reserved2" | "Reserved3" | "Reserved4" | "ReservedMask";

export interface PropertyInfo {
    "name"?: string;
    "declaringType"?: Type;
    "reflectedType"?: Type;
    "module"?: Module;
    "customAttributes"?: Array<CustomAttributeData>;
    "isCollectible"?: boolean;
    "metadataToken"?: number;
    "memberType"?: MemberTypes;
    "propertyType"?: Type;
    "attributes"?: PropertyAttributes;
    "isSpecialName"?: boolean;
    "canRead"?: boolean;
    "canWrite"?: boolean;
    "getMethod"?: MethodInfo;
    "setMethod"?: MethodInfo;
}

export interface RequestPingRequest {
    "serialNumber"?: string;
    "wait"?: boolean;
}

export interface RequestRebootRequest {
    "serialNumber"?: string;
}

export interface RuntimeFieldHandle {
    "value"?: IntPtr;
}

export interface RuntimeMethodHandle {
    "value"?: IntPtr;
}

export interface RuntimeTypeHandle {
    "value"?: IntPtr;
}

export type SecurityRuleSet = "None" | "Level1" | "Level2";

export interface SplitFeatureToggle {
    "featureToggle"?: string;
    "isSplitOn"?: boolean;
}

export interface SplitTreatmentOptions {
    "splitKey"?: string;
    "featureToggle"?: string;
    "attributes"?: any;
    "controlDefault"?: boolean;
}

export interface StructLayoutAttribute {
    "value"?: LayoutKind;
}

export interface TelematicDeviceAdminResponse {
    "sourceId"?: string;
    "name"?: string;
    "serialNumber"?: string;
    "type"?: string;
}

export interface TelematicDeviceResponse {
    "id"?: number;
    "serialNumber"?: string;
    "deviceId"?: string;
    "name"?: string;
    "type"?: string;
    "providerId"?: TelematicProviderIdEnum;
    "providerName"?: string;
    "location"?: string;
    "latitude"?: number;
    "longitude"?: number;
    "ignitionOn"?: boolean;
    "refreshDate"?: Date;
}

export type TelematicProviderIdEnum = "Geotab" | "Striker" | "Mitac";

export interface TelematicsDeviceResponse {
    "id"?: number;
    "serialNumber"?: string;
    "deviceId"?: string;
    "name"?: string;
    "type"?: string;
    "provider"?: string;
    "location"?: string;
    "latitude"?: number;
    "longitude"?: number;
    "ignitionOn"?: boolean;
    "refreshDate"?: Date;
}

export interface Type {
    "name"?: string;
    "customAttributes"?: Array<CustomAttributeData>;
    "isCollectible"?: boolean;
    "metadataToken"?: number;
    "isInterface"?: boolean;
    "memberType"?: MemberTypes;
    "namespace"?: string;
    "assemblyQualifiedName"?: string;
    "fullName"?: string;
    "assembly"?: Assembly;
    "module"?: Module;
    "isNested"?: boolean;
    "declaringType"?: Type;
    "declaringMethod"?: MethodBase;
    "reflectedType"?: Type;
    "underlyingSystemType"?: Type;
    "isTypeDefinition"?: boolean;
    "isArray"?: boolean;
    "isByRef"?: boolean;
    "isPointer"?: boolean;
    "isConstructedGenericType"?: boolean;
    "isGenericParameter"?: boolean;
    "isGenericTypeParameter"?: boolean;
    "isGenericMethodParameter"?: boolean;
    "isGenericType"?: boolean;
    "isGenericTypeDefinition"?: boolean;
    "isSZArray"?: boolean;
    "isVariableBoundArray"?: boolean;
    "isByRefLike"?: boolean;
    "hasElementType"?: boolean;
    "genericTypeArguments"?: Array<Type>;
    "genericParameterPosition"?: number;
    "genericParameterAttributes"?: GenericParameterAttributes;
    "attributes"?: TypeAttributes;
    "isAbstract"?: boolean;
    "isImport"?: boolean;
    "isSealed"?: boolean;
    "isSpecialName"?: boolean;
    "isClass"?: boolean;
    "isNestedAssembly"?: boolean;
    "isNestedFamANDAssem"?: boolean;
    "isNestedFamily"?: boolean;
    "isNestedFamORAssem"?: boolean;
    "isNestedPrivate"?: boolean;
    "isNestedPublic"?: boolean;
    "isNotPublic"?: boolean;
    "isPublic"?: boolean;
    "isAutoLayout"?: boolean;
    "isExplicitLayout"?: boolean;
    "isLayoutSequential"?: boolean;
    "isAnsiClass"?: boolean;
    "isAutoClass"?: boolean;
    "isUnicodeClass"?: boolean;
    "isCOMObject"?: boolean;
    "isContextful"?: boolean;
    "isEnum"?: boolean;
    "isMarshalByRef"?: boolean;
    "isPrimitive"?: boolean;
    "isValueType"?: boolean;
    "isSignatureType"?: boolean;
    "isSecurityCritical"?: boolean;
    "isSecuritySafeCritical"?: boolean;
    "isSecurityTransparent"?: boolean;
    "structLayoutAttribute"?: StructLayoutAttribute;
    "typeInitializer"?: ConstructorInfo;
    "typeHandle"?: RuntimeTypeHandle;
    "guid"?: string;
    "baseType"?: Type;
    "isSerializable"?: boolean;
    "containsGenericParameters"?: boolean;
    "isVisible"?: boolean;
}

export type TypeAttributes = "NotPublic" | "AutoLayout" | "AnsiClass" | "Class" | "Public" | "NestedPublic" | "NestedPrivate" | "NestedFamily" | "NestedAssembly" | "NestedFamANDAssem" | "NestedFamORAssem" | "VisibilityMask" | "SequentialLayout" | "ExplicitLayout" | "LayoutMask" | "Interface" | "ClassSemanticsMask" | "Abstract" | "Sealed" | "SpecialName" | "RTSpecialName" | "Import" | "Serializable" | "WindowsRuntime" | "UnicodeClass" | "AutoClass" | "CustomFormatClass" | "StringFormatMask" | "HasSecurity" | "ReservedMask" | "BeforeFieldInit" | "CustomFormatMask";

export interface TypeInfo {
    "name"?: string;
    "customAttributes"?: Array<CustomAttributeData>;
    "isCollectible"?: boolean;
    "metadataToken"?: number;
    "isInterface"?: boolean;
    "memberType"?: MemberTypes;
    "namespace"?: string;
    "assemblyQualifiedName"?: string;
    "fullName"?: string;
    "assembly"?: Assembly;
    "module"?: Module;
    "isNested"?: boolean;
    "declaringType"?: Type;
    "declaringMethod"?: MethodBase;
    "reflectedType"?: Type;
    "underlyingSystemType"?: Type;
    "isTypeDefinition"?: boolean;
    "isArray"?: boolean;
    "isByRef"?: boolean;
    "isPointer"?: boolean;
    "isConstructedGenericType"?: boolean;
    "isGenericParameter"?: boolean;
    "isGenericTypeParameter"?: boolean;
    "isGenericMethodParameter"?: boolean;
    "isGenericType"?: boolean;
    "isGenericTypeDefinition"?: boolean;
    "isSZArray"?: boolean;
    "isVariableBoundArray"?: boolean;
    "isByRefLike"?: boolean;
    "hasElementType"?: boolean;
    "genericTypeArguments"?: Array<Type>;
    "genericParameterPosition"?: number;
    "genericParameterAttributes"?: GenericParameterAttributes;
    "attributes"?: TypeAttributes;
    "isAbstract"?: boolean;
    "isImport"?: boolean;
    "isSealed"?: boolean;
    "isSpecialName"?: boolean;
    "isClass"?: boolean;
    "isNestedAssembly"?: boolean;
    "isNestedFamANDAssem"?: boolean;
    "isNestedFamily"?: boolean;
    "isNestedFamORAssem"?: boolean;
    "isNestedPrivate"?: boolean;
    "isNestedPublic"?: boolean;
    "isNotPublic"?: boolean;
    "isPublic"?: boolean;
    "isAutoLayout"?: boolean;
    "isExplicitLayout"?: boolean;
    "isLayoutSequential"?: boolean;
    "isAnsiClass"?: boolean;
    "isAutoClass"?: boolean;
    "isUnicodeClass"?: boolean;
    "isCOMObject"?: boolean;
    "isContextful"?: boolean;
    "isEnum"?: boolean;
    "isMarshalByRef"?: boolean;
    "isPrimitive"?: boolean;
    "isValueType"?: boolean;
    "isSignatureType"?: boolean;
    "isSecurityCritical"?: boolean;
    "isSecuritySafeCritical"?: boolean;
    "isSecurityTransparent"?: boolean;
    "structLayoutAttribute"?: StructLayoutAttribute;
    "typeInitializer"?: ConstructorInfo;
    "typeHandle"?: RuntimeTypeHandle;
    "guid"?: string;
    "baseType"?: Type;
    "isSerializable"?: boolean;
    "containsGenericParameters"?: boolean;
    "isVisible"?: boolean;
    "genericTypeParameters"?: Array<Type>;
    "declaredConstructors"?: Array<ConstructorInfo>;
    "declaredEvents"?: Array<EventInfo>;
    "declaredFields"?: Array<FieldInfo>;
    "declaredMembers"?: Array<MemberInfo>;
    "declaredMethods"?: Array<MethodInfo>;
    "declaredNestedTypes"?: Array<TypeInfo>;
    "declaredProperties"?: Array<PropertyInfo>;
    "implementedInterfaces"?: Array<Type>;
}

export interface UpdateCameraFleetRequest {
    "fleetId"?: number;
    "cameraSerialNumbers"?: Array<string>;
}

export interface UpdateUserRequest {
    "id": string;
    "userName": string;
    "firstName": string;
    "lastName": string;
    "email": string;
    "role": string;
}

export interface UserResponse {
    "firstName"?: string;
    "lastName"?: string;
    "email"?: string;
    "roles"?: Array<string>;
}

export interface VehicleCameraPairingResponse {
    "id"?: number;
    "cameraId"?: number;
    "isGpsTrackingEnabled"?: boolean;
    "fleetId"?: number;
    "clientName"?: string;
    "startDate"?: Date;
    "endDate"?: Date;
    "camera"?: CameraDeviceResponse;
    "vehicle"?: VehicleResponse;
}

export interface VehicleResponse {
    "id"?: number;
    "name"?: string;
    "vin"?: string;
    "integrationPartnerVehicleId"?: string;
    "fleetId"?: number;
    "telematicDevice"?: TelematicDeviceResponse;
}



/**
 * AgreementsApi - fetch parameter creator
 */
export const AgreementsApiFetchParamCreator = {
    /** 
     * @param body 
     */
    apiV1AgreementsAgreementVersionPost(params: {  "body"?: AddAgreementVersionRequest; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/agreements/agreement-version`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["body"]) {
            fetchOptions.body = JSON.stringify(params["body"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
};

/**
 * AgreementsApi - functional programming interface
 */
export const AgreementsApiFp = {
    /** 
     * @param body 
     */
    apiV1AgreementsAgreementVersionPost(params: { "body"?: AddAgreementVersionRequest;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = AgreementsApiFetchParamCreator.apiV1AgreementsAgreementVersionPost(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
};

/**
 * AgreementsApi - object-oriented interface
 */
export class AgreementsApi extends BaseAPI {
    /** 
     * @param body 
     */
    apiV1AgreementsAgreementVersionPost(params: {  "body"?: AddAgreementVersionRequest; }, options?: any) {
        return AgreementsApiFp.apiV1AgreementsAgreementVersionPost(params, options)(this.fetch, this.basePath);
    }
};

/**
 * AgreementsApi - factory interface
 */
export const AgreementsApiFactory = function (fetch?: FetchAPI, basePath?: string) {
    return {
        /** 
         * @param body 
         */
        apiV1AgreementsAgreementVersionPost(params: {  "body"?: AddAgreementVersionRequest; }, options?: any) {
            return AgreementsApiFp.apiV1AgreementsAgreementVersionPost(params, options)(fetch, basePath);
        },
    };
};


/**
 * ApiEndpointApi - fetch parameter creator
 */
export const ApiEndpointApiFetchParamCreator = {
    /** 
     */
    apiHelloGet(options?: any): FetchArgs {
        const baseUrl = `/api/hello`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * @param body 
     */
    apiProcessControlMessageByNamePost(params: {  "body"?: ProcessControlMessage; }, options?: any): FetchArgs {
        const baseUrl = `/api/ProcessControlMessageByName`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["body"]) {
            fetchOptions.body = JSON.stringify(params["body"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
};

/**
 * ApiEndpointApi - functional programming interface
 */
export const ApiEndpointApiFp = {
    /** 
     */
    apiHelloGet(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<string> {
        const fetchArgs = ApiEndpointApiFetchParamCreator.apiHelloGet(options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * @param body 
     */
    apiProcessControlMessageByNamePost(params: { "body"?: ProcessControlMessage;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = ApiEndpointApiFetchParamCreator.apiProcessControlMessageByNamePost(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
};

/**
 * ApiEndpointApi - object-oriented interface
 */
export class ApiEndpointApi extends BaseAPI {
    /** 
     */
    apiHelloGet(options?: any) {
        return ApiEndpointApiFp.apiHelloGet(options)(this.fetch, this.basePath);
    }
    /** 
     * @param body 
     */
    apiProcessControlMessageByNamePost(params: {  "body"?: ProcessControlMessage; }, options?: any) {
        return ApiEndpointApiFp.apiProcessControlMessageByNamePost(params, options)(this.fetch, this.basePath);
    }
};

/**
 * ApiEndpointApi - factory interface
 */
export const ApiEndpointApiFactory = function (fetch?: FetchAPI, basePath?: string) {
    return {
        /** 
         */
        apiHelloGet(options?: any) {
            return ApiEndpointApiFp.apiHelloGet(options)(fetch, basePath);
        },
        /** 
         * @param body 
         */
        apiProcessControlMessageByNamePost(params: {  "body"?: ProcessControlMessage; }, options?: any) {
            return ApiEndpointApiFp.apiProcessControlMessageByNamePost(params, options)(fetch, basePath);
        },
    };
};


/**
 * AutomatedTestApi - fetch parameter creator
 */
export const AutomatedTestApiFetchParamCreator = {
    /** 
     * @param serialNumber 
     */
    apiV1AutomatedTestAddCameraToEnvironmentTenantSerialNumberPut(params: {  "serialNumber": string; }, options?: any): FetchArgs {
        // verify required parameter "serialNumber" is set
        if (params["serialNumber"] == null) {
            throw new Error("Missing required parameter serialNumber when calling apiV1AutomatedTestAddCameraToEnvironmentTenantSerialNumberPut");
        }
        const baseUrl = `/api/v1/AutomatedTest/add-camera-to-environment-tenant/{serialNumber}`
            .replace(`{${"serialNumber"}}`, `${ params["serialNumber"] }`);
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "PUT" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * @param email 
     */
    apiV1AutomatedTestInstallerEmailDelete(params: {  "email": string; }, options?: any): FetchArgs {
        // verify required parameter "email" is set
        if (params["email"] == null) {
            throw new Error("Missing required parameter email when calling apiV1AutomatedTestInstallerEmailDelete");
        }
        const baseUrl = `/api/v1/AutomatedTest/installer/{email}`
            .replace(`{${"email"}}`, `${ params["email"] }`);
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "DELETE" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * @param serialNumber 
     */
    apiV1AutomatedTestReturnCameraToLandingTenantSerialNumberPut(params: {  "serialNumber": string; }, options?: any): FetchArgs {
        // verify required parameter "serialNumber" is set
        if (params["serialNumber"] == null) {
            throw new Error("Missing required parameter serialNumber when calling apiV1AutomatedTestReturnCameraToLandingTenantSerialNumberPut");
        }
        const baseUrl = `/api/v1/AutomatedTest/return-camera-to-landing-tenant/{serialNumber}`
            .replace(`{${"serialNumber"}}`, `${ params["serialNumber"] }`);
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "PUT" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
};

/**
 * AutomatedTestApi - functional programming interface
 */
export const AutomatedTestApiFp = {
    /** 
     * @param serialNumber 
     */
    apiV1AutomatedTestAddCameraToEnvironmentTenantSerialNumberPut(params: { "serialNumber": string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = AutomatedTestApiFetchParamCreator.apiV1AutomatedTestAddCameraToEnvironmentTenantSerialNumberPut(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * @param email 
     */
    apiV1AutomatedTestInstallerEmailDelete(params: { "email": string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = AutomatedTestApiFetchParamCreator.apiV1AutomatedTestInstallerEmailDelete(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * @param serialNumber 
     */
    apiV1AutomatedTestReturnCameraToLandingTenantSerialNumberPut(params: { "serialNumber": string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = AutomatedTestApiFetchParamCreator.apiV1AutomatedTestReturnCameraToLandingTenantSerialNumberPut(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
};

/**
 * AutomatedTestApi - object-oriented interface
 */
export class AutomatedTestApi extends BaseAPI {
    /** 
     * @param serialNumber 
     */
    apiV1AutomatedTestAddCameraToEnvironmentTenantSerialNumberPut(params: {  "serialNumber": string; }, options?: any) {
        return AutomatedTestApiFp.apiV1AutomatedTestAddCameraToEnvironmentTenantSerialNumberPut(params, options)(this.fetch, this.basePath);
    }
    /** 
     * @param email 
     */
    apiV1AutomatedTestInstallerEmailDelete(params: {  "email": string; }, options?: any) {
        return AutomatedTestApiFp.apiV1AutomatedTestInstallerEmailDelete(params, options)(this.fetch, this.basePath);
    }
    /** 
     * @param serialNumber 
     */
    apiV1AutomatedTestReturnCameraToLandingTenantSerialNumberPut(params: {  "serialNumber": string; }, options?: any) {
        return AutomatedTestApiFp.apiV1AutomatedTestReturnCameraToLandingTenantSerialNumberPut(params, options)(this.fetch, this.basePath);
    }
};

/**
 * AutomatedTestApi - factory interface
 */
export const AutomatedTestApiFactory = function (fetch?: FetchAPI, basePath?: string) {
    return {
        /** 
         * @param serialNumber 
         */
        apiV1AutomatedTestAddCameraToEnvironmentTenantSerialNumberPut(params: {  "serialNumber": string; }, options?: any) {
            return AutomatedTestApiFp.apiV1AutomatedTestAddCameraToEnvironmentTenantSerialNumberPut(params, options)(fetch, basePath);
        },
        /** 
         * @param email 
         */
        apiV1AutomatedTestInstallerEmailDelete(params: {  "email": string; }, options?: any) {
            return AutomatedTestApiFp.apiV1AutomatedTestInstallerEmailDelete(params, options)(fetch, basePath);
        },
        /** 
         * @param serialNumber 
         */
        apiV1AutomatedTestReturnCameraToLandingTenantSerialNumberPut(params: {  "serialNumber": string; }, options?: any) {
            return AutomatedTestApiFp.apiV1AutomatedTestReturnCameraToLandingTenantSerialNumberPut(params, options)(fetch, basePath);
        },
    };
};


/**
 * CameraDeviceConfigApi - fetch parameter creator
 */
export const CameraDeviceConfigApiFetchParamCreator = {
    /** 
     * Inserts a new CameraDeviceConfig record with the given settings  Moreover publishes to config/initiate for the specified camera and configType if camera is online.
     * @param body 
     */
    apiV1CameraDeviceConfigCameraPut(params: {  "body"?: CameraConfigUpdateRequest; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/CameraDeviceConfig/camera`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "PUT" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["body"]) {
            fetchOptions.body = JSON.stringify(params["body"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Returns the currently applied configs from CameraDeviceConfigConfirmed and the target settings (all versions) based on the config tables.
     * @param serialNumber 
     * @param configType Example Value 1 &#x3D; Gemini, 2 &#x3D; AppSettings
     */
    apiV1CameraDeviceConfigCameraSerialNumberConfigTypeGet(params: {  "serialNumber": string; "configType": string; }, options?: any): FetchArgs {
        // verify required parameter "serialNumber" is set
        if (params["serialNumber"] == null) {
            throw new Error("Missing required parameter serialNumber when calling apiV1CameraDeviceConfigCameraSerialNumberConfigTypeGet");
        }
        // verify required parameter "configType" is set
        if (params["configType"] == null) {
            throw new Error("Missing required parameter configType when calling apiV1CameraDeviceConfigCameraSerialNumberConfigTypeGet");
        }
        const baseUrl = `/api/v1/CameraDeviceConfig/camera/{serialNumber}/{configType}`
            .replace(`{${"serialNumber"}}`, `${ params["serialNumber"] }`)
            .replace(`{${"configType"}}`, `${ params["configType"] }`);
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Returns a presigned URL for the camera-configuration-metadata.yaml file, which defines the details required to generate a configuration UI
     */
    apiV1CameraDeviceConfigConfigMetadataGet(options?: any): FetchArgs {
        const baseUrl = `/api/v1/CameraDeviceConfig/ConfigMetadata`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Returns all the settings from CameraDeviceTypeFleetConfig for the specified fleet, device type and comfig type
     * @param fleetId 
     * @param deviceTypeId Example Value 5 &#x3D; VP210D, 8 &#x3D; VP230D
     * @param configType Example Value 1 &#x3D; Gemini, 2 &#x3D; AppSettings
     */
    apiV1CameraDeviceConfigFleetFleetIdDevicetypeDeviceTypeIdConfigTypeGet(params: {  "fleetId": number; "deviceTypeId": string; "configType": string; }, options?: any): FetchArgs {
        // verify required parameter "fleetId" is set
        if (params["fleetId"] == null) {
            throw new Error("Missing required parameter fleetId when calling apiV1CameraDeviceConfigFleetFleetIdDevicetypeDeviceTypeIdConfigTypeGet");
        }
        // verify required parameter "deviceTypeId" is set
        if (params["deviceTypeId"] == null) {
            throw new Error("Missing required parameter deviceTypeId when calling apiV1CameraDeviceConfigFleetFleetIdDevicetypeDeviceTypeIdConfigTypeGet");
        }
        // verify required parameter "configType" is set
        if (params["configType"] == null) {
            throw new Error("Missing required parameter configType when calling apiV1CameraDeviceConfigFleetFleetIdDevicetypeDeviceTypeIdConfigTypeGet");
        }
        const baseUrl = `/api/v1/CameraDeviceConfig/fleet/{fleetId}/devicetype/{deviceTypeId}/{configType}`
            .replace(`{${"fleetId"}}`, `${ params["fleetId"] }`)
            .replace(`{${"deviceTypeId"}}`, `${ params["deviceTypeId"] }`)
            .replace(`{${"configType"}}`, `${ params["configType"] }`);
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Inserts CameraDeviceTypeFleetConfig record for the specified fleet, deviceType, and configType. Optionally removes custom settings for all cameras in the fleet, returning them to the default  Moreover publishes to config/initiate for the specified camera and configType if camera is online
     * @param body 
     */
    apiV1CameraDeviceConfigFleetPut(params: {  "body"?: FleetCameraConfigUpdateRequestModel; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/CameraDeviceConfig/fleet`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "PUT" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["body"]) {
            fetchOptions.body = JSON.stringify(params["body"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
};

/**
 * CameraDeviceConfigApi - functional programming interface
 */
export const CameraDeviceConfigApiFp = {
    /** 
     * Inserts a new CameraDeviceConfig record with the given settings  Moreover publishes to config/initiate for the specified camera and configType if camera is online.
     * @param body 
     */
    apiV1CameraDeviceConfigCameraPut(params: { "body"?: CameraConfigUpdateRequest;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = CameraDeviceConfigApiFetchParamCreator.apiV1CameraDeviceConfigCameraPut(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Returns the currently applied configs from CameraDeviceConfigConfirmed and the target settings (all versions) based on the config tables.
     * @param serialNumber 
     * @param configType Example Value 1 &#x3D; Gemini, 2 &#x3D; AppSettings
     */
    apiV1CameraDeviceConfigCameraSerialNumberConfigTypeGet(params: { "serialNumber": string; "configType": string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = CameraDeviceConfigApiFetchParamCreator.apiV1CameraDeviceConfigCameraSerialNumberConfigTypeGet(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Returns a presigned URL for the camera-configuration-metadata.yaml file, which defines the details required to generate a configuration UI
     */
    apiV1CameraDeviceConfigConfigMetadataGet(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<string> {
        const fetchArgs = CameraDeviceConfigApiFetchParamCreator.apiV1CameraDeviceConfigConfigMetadataGet(options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Returns all the settings from CameraDeviceTypeFleetConfig for the specified fleet, device type and comfig type
     * @param fleetId 
     * @param deviceTypeId Example Value 5 &#x3D; VP210D, 8 &#x3D; VP230D
     * @param configType Example Value 1 &#x3D; Gemini, 2 &#x3D; AppSettings
     */
    apiV1CameraDeviceConfigFleetFleetIdDevicetypeDeviceTypeIdConfigTypeGet(params: { "fleetId": number; "deviceTypeId": string; "configType": string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = CameraDeviceConfigApiFetchParamCreator.apiV1CameraDeviceConfigFleetFleetIdDevicetypeDeviceTypeIdConfigTypeGet(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Inserts CameraDeviceTypeFleetConfig record for the specified fleet, deviceType, and configType. Optionally removes custom settings for all cameras in the fleet, returning them to the default  Moreover publishes to config/initiate for the specified camera and configType if camera is online
     * @param body 
     */
    apiV1CameraDeviceConfigFleetPut(params: { "body"?: FleetCameraConfigUpdateRequestModel;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = CameraDeviceConfigApiFetchParamCreator.apiV1CameraDeviceConfigFleetPut(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
};

/**
 * CameraDeviceConfigApi - object-oriented interface
 */
export class CameraDeviceConfigApi extends BaseAPI {
    /** 
     * Inserts a new CameraDeviceConfig record with the given settings  Moreover publishes to config/initiate for the specified camera and configType if camera is online.
     * @param body 
     */
    apiV1CameraDeviceConfigCameraPut(params: {  "body"?: CameraConfigUpdateRequest; }, options?: any) {
        return CameraDeviceConfigApiFp.apiV1CameraDeviceConfigCameraPut(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Returns the currently applied configs from CameraDeviceConfigConfirmed and the target settings (all versions) based on the config tables.
     * @param serialNumber 
     * @param configType Example Value 1 &#x3D; Gemini, 2 &#x3D; AppSettings
     */
    apiV1CameraDeviceConfigCameraSerialNumberConfigTypeGet(params: {  "serialNumber": string; "configType": string; }, options?: any) {
        return CameraDeviceConfigApiFp.apiV1CameraDeviceConfigCameraSerialNumberConfigTypeGet(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Returns a presigned URL for the camera-configuration-metadata.yaml file, which defines the details required to generate a configuration UI
     */
    apiV1CameraDeviceConfigConfigMetadataGet(options?: any) {
        return CameraDeviceConfigApiFp.apiV1CameraDeviceConfigConfigMetadataGet(options)(this.fetch, this.basePath);
    }
    /** 
     * Returns all the settings from CameraDeviceTypeFleetConfig for the specified fleet, device type and comfig type
     * @param fleetId 
     * @param deviceTypeId Example Value 5 &#x3D; VP210D, 8 &#x3D; VP230D
     * @param configType Example Value 1 &#x3D; Gemini, 2 &#x3D; AppSettings
     */
    apiV1CameraDeviceConfigFleetFleetIdDevicetypeDeviceTypeIdConfigTypeGet(params: {  "fleetId": number; "deviceTypeId": string; "configType": string; }, options?: any) {
        return CameraDeviceConfigApiFp.apiV1CameraDeviceConfigFleetFleetIdDevicetypeDeviceTypeIdConfigTypeGet(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Inserts CameraDeviceTypeFleetConfig record for the specified fleet, deviceType, and configType. Optionally removes custom settings for all cameras in the fleet, returning them to the default  Moreover publishes to config/initiate for the specified camera and configType if camera is online
     * @param body 
     */
    apiV1CameraDeviceConfigFleetPut(params: {  "body"?: FleetCameraConfigUpdateRequestModel; }, options?: any) {
        return CameraDeviceConfigApiFp.apiV1CameraDeviceConfigFleetPut(params, options)(this.fetch, this.basePath);
    }
};

/**
 * CameraDeviceConfigApi - factory interface
 */
export const CameraDeviceConfigApiFactory = function (fetch?: FetchAPI, basePath?: string) {
    return {
        /** 
         * Inserts a new CameraDeviceConfig record with the given settings  Moreover publishes to config/initiate for the specified camera and configType if camera is online.
         * @param body 
         */
        apiV1CameraDeviceConfigCameraPut(params: {  "body"?: CameraConfigUpdateRequest; }, options?: any) {
            return CameraDeviceConfigApiFp.apiV1CameraDeviceConfigCameraPut(params, options)(fetch, basePath);
        },
        /** 
         * Returns the currently applied configs from CameraDeviceConfigConfirmed and the target settings (all versions) based on the config tables.
         * @param serialNumber 
         * @param configType Example Value 1 &#x3D; Gemini, 2 &#x3D; AppSettings
         */
        apiV1CameraDeviceConfigCameraSerialNumberConfigTypeGet(params: {  "serialNumber": string; "configType": string; }, options?: any) {
            return CameraDeviceConfigApiFp.apiV1CameraDeviceConfigCameraSerialNumberConfigTypeGet(params, options)(fetch, basePath);
        },
        /** 
         * Returns a presigned URL for the camera-configuration-metadata.yaml file, which defines the details required to generate a configuration UI
         */
        apiV1CameraDeviceConfigConfigMetadataGet(options?: any) {
            return CameraDeviceConfigApiFp.apiV1CameraDeviceConfigConfigMetadataGet(options)(fetch, basePath);
        },
        /** 
         * Returns all the settings from CameraDeviceTypeFleetConfig for the specified fleet, device type and comfig type
         * @param fleetId 
         * @param deviceTypeId Example Value 5 &#x3D; VP210D, 8 &#x3D; VP230D
         * @param configType Example Value 1 &#x3D; Gemini, 2 &#x3D; AppSettings
         */
        apiV1CameraDeviceConfigFleetFleetIdDevicetypeDeviceTypeIdConfigTypeGet(params: {  "fleetId": number; "deviceTypeId": string; "configType": string; }, options?: any) {
            return CameraDeviceConfigApiFp.apiV1CameraDeviceConfigFleetFleetIdDevicetypeDeviceTypeIdConfigTypeGet(params, options)(fetch, basePath);
        },
        /** 
         * Inserts CameraDeviceTypeFleetConfig record for the specified fleet, deviceType, and configType. Optionally removes custom settings for all cameras in the fleet, returning them to the default  Moreover publishes to config/initiate for the specified camera and configType if camera is online
         * @param body 
         */
        apiV1CameraDeviceConfigFleetPut(params: {  "body"?: FleetCameraConfigUpdateRequestModel; }, options?: any) {
            return CameraDeviceConfigApiFp.apiV1CameraDeviceConfigFleetPut(params, options)(fetch, basePath);
        },
    };
};


/**
 * CameraDeviceHealthStatusApi - fetch parameter creator
 */
export const CameraDeviceHealthStatusApiFetchParamCreator = {
    /** 
     * @param serialNumber 
     */
    apiV1CameraDeviceHealthStatusActivateSerialNumberPut(params: {  "serialNumber": string; }, options?: any): FetchArgs {
        // verify required parameter "serialNumber" is set
        if (params["serialNumber"] == null) {
            throw new Error("Missing required parameter serialNumber when calling apiV1CameraDeviceHealthStatusActivateSerialNumberPut");
        }
        const baseUrl = `/api/v1/CameraDeviceHealthStatus/Activate/{serialNumber}`
            .replace(`{${"serialNumber"}}`, `${ params["serialNumber"] }`);
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "PUT" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * 
     * @param cameraSerialNumber 
     * @param startDate 2023-02-24 12:55:55.266 -0600
     * @param endDate 2023-02-24 12:55:55.266 -0600
     */
    apiV1CameraDeviceHealthStatusCamerawhitelistCameraSerialNumberPost(params: {  "cameraSerialNumber": string; "startDate"?: Date; "endDate"?: Date; }, options?: any): FetchArgs {
        // verify required parameter "cameraSerialNumber" is set
        if (params["cameraSerialNumber"] == null) {
            throw new Error("Missing required parameter cameraSerialNumber when calling apiV1CameraDeviceHealthStatusCamerawhitelistCameraSerialNumberPost");
        }
        const baseUrl = `/api/v1/CameraDeviceHealthStatus/camerawhitelist/{cameraSerialNumber}`
            .replace(`{${"cameraSerialNumber"}}`, `${ params["cameraSerialNumber"] }`);
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "startDate": params["startDate"],
            "endDate": params["endDate"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * @param serialNumber 
     */
    apiV1CameraDeviceHealthStatusDeactivateSerialNumberPut(params: {  "serialNumber": string; }, options?: any): FetchArgs {
        // verify required parameter "serialNumber" is set
        if (params["serialNumber"] == null) {
            throw new Error("Missing required parameter serialNumber when calling apiV1CameraDeviceHealthStatusDeactivateSerialNumberPut");
        }
        const baseUrl = `/api/v1/CameraDeviceHealthStatus/Deactivate/{serialNumber}`
            .replace(`{${"serialNumber"}}`, `${ params["serialNumber"] }`);
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "PUT" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * @param body 
     */
    apiV1CameraDeviceHealthStatusPingPost(params: {  "body"?: RequestPingRequest; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/CameraDeviceHealthStatus/ping`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["body"]) {
            fetchOptions.body = JSON.stringify(params["body"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * @param body 
     */
    apiV1CameraDeviceHealthStatusRestartServicePost(params: {  "body"?: RequestRebootRequest; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/CameraDeviceHealthStatus/RestartService`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["body"]) {
            fetchOptions.body = JSON.stringify(params["body"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * @param body 
     */
    apiV1CameraDeviceHealthStatusUpdateCameraFleetPut(params: {  "body"?: UpdateCameraFleetRequest; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/CameraDeviceHealthStatus/UpdateCameraFleet`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "PUT" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["body"]) {
            fetchOptions.body = JSON.stringify(params["body"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
};

/**
 * CameraDeviceHealthStatusApi - functional programming interface
 */
export const CameraDeviceHealthStatusApiFp = {
    /** 
     * @param serialNumber 
     */
    apiV1CameraDeviceHealthStatusActivateSerialNumberPut(params: { "serialNumber": string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = CameraDeviceHealthStatusApiFetchParamCreator.apiV1CameraDeviceHealthStatusActivateSerialNumberPut(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * 
     * @param cameraSerialNumber 
     * @param startDate 2023-02-24 12:55:55.266 -0600
     * @param endDate 2023-02-24 12:55:55.266 -0600
     */
    apiV1CameraDeviceHealthStatusCamerawhitelistCameraSerialNumberPost(params: { "cameraSerialNumber": string; "startDate"?: Date; "endDate"?: Date;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = CameraDeviceHealthStatusApiFetchParamCreator.apiV1CameraDeviceHealthStatusCamerawhitelistCameraSerialNumberPost(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * @param serialNumber 
     */
    apiV1CameraDeviceHealthStatusDeactivateSerialNumberPut(params: { "serialNumber": string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = CameraDeviceHealthStatusApiFetchParamCreator.apiV1CameraDeviceHealthStatusDeactivateSerialNumberPut(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * @param body 
     */
    apiV1CameraDeviceHealthStatusPingPost(params: { "body"?: RequestPingRequest;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = CameraDeviceHealthStatusApiFetchParamCreator.apiV1CameraDeviceHealthStatusPingPost(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * @param body 
     */
    apiV1CameraDeviceHealthStatusRestartServicePost(params: { "body"?: RequestRebootRequest;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = CameraDeviceHealthStatusApiFetchParamCreator.apiV1CameraDeviceHealthStatusRestartServicePost(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * @param body 
     */
    apiV1CameraDeviceHealthStatusUpdateCameraFleetPut(params: { "body"?: UpdateCameraFleetRequest;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = CameraDeviceHealthStatusApiFetchParamCreator.apiV1CameraDeviceHealthStatusUpdateCameraFleetPut(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
};

/**
 * CameraDeviceHealthStatusApi - object-oriented interface
 */
export class CameraDeviceHealthStatusApi extends BaseAPI {
    /** 
     * @param serialNumber 
     */
    apiV1CameraDeviceHealthStatusActivateSerialNumberPut(params: {  "serialNumber": string; }, options?: any) {
        return CameraDeviceHealthStatusApiFp.apiV1CameraDeviceHealthStatusActivateSerialNumberPut(params, options)(this.fetch, this.basePath);
    }
    /** 
     * 
     * @param cameraSerialNumber 
     * @param startDate 2023-02-24 12:55:55.266 -0600
     * @param endDate 2023-02-24 12:55:55.266 -0600
     */
    apiV1CameraDeviceHealthStatusCamerawhitelistCameraSerialNumberPost(params: {  "cameraSerialNumber": string; "startDate"?: Date; "endDate"?: Date; }, options?: any) {
        return CameraDeviceHealthStatusApiFp.apiV1CameraDeviceHealthStatusCamerawhitelistCameraSerialNumberPost(params, options)(this.fetch, this.basePath);
    }
    /** 
     * @param serialNumber 
     */
    apiV1CameraDeviceHealthStatusDeactivateSerialNumberPut(params: {  "serialNumber": string; }, options?: any) {
        return CameraDeviceHealthStatusApiFp.apiV1CameraDeviceHealthStatusDeactivateSerialNumberPut(params, options)(this.fetch, this.basePath);
    }
    /** 
     * @param body 
     */
    apiV1CameraDeviceHealthStatusPingPost(params: {  "body"?: RequestPingRequest; }, options?: any) {
        return CameraDeviceHealthStatusApiFp.apiV1CameraDeviceHealthStatusPingPost(params, options)(this.fetch, this.basePath);
    }
    /** 
     * @param body 
     */
    apiV1CameraDeviceHealthStatusRestartServicePost(params: {  "body"?: RequestRebootRequest; }, options?: any) {
        return CameraDeviceHealthStatusApiFp.apiV1CameraDeviceHealthStatusRestartServicePost(params, options)(this.fetch, this.basePath);
    }
    /** 
     * @param body 
     */
    apiV1CameraDeviceHealthStatusUpdateCameraFleetPut(params: {  "body"?: UpdateCameraFleetRequest; }, options?: any) {
        return CameraDeviceHealthStatusApiFp.apiV1CameraDeviceHealthStatusUpdateCameraFleetPut(params, options)(this.fetch, this.basePath);
    }
};

/**
 * CameraDeviceHealthStatusApi - factory interface
 */
export const CameraDeviceHealthStatusApiFactory = function (fetch?: FetchAPI, basePath?: string) {
    return {
        /** 
         * @param serialNumber 
         */
        apiV1CameraDeviceHealthStatusActivateSerialNumberPut(params: {  "serialNumber": string; }, options?: any) {
            return CameraDeviceHealthStatusApiFp.apiV1CameraDeviceHealthStatusActivateSerialNumberPut(params, options)(fetch, basePath);
        },
        /** 
         * 
         * @param cameraSerialNumber 
         * @param startDate 2023-02-24 12:55:55.266 -0600
         * @param endDate 2023-02-24 12:55:55.266 -0600
         */
        apiV1CameraDeviceHealthStatusCamerawhitelistCameraSerialNumberPost(params: {  "cameraSerialNumber": string; "startDate"?: Date; "endDate"?: Date; }, options?: any) {
            return CameraDeviceHealthStatusApiFp.apiV1CameraDeviceHealthStatusCamerawhitelistCameraSerialNumberPost(params, options)(fetch, basePath);
        },
        /** 
         * @param serialNumber 
         */
        apiV1CameraDeviceHealthStatusDeactivateSerialNumberPut(params: {  "serialNumber": string; }, options?: any) {
            return CameraDeviceHealthStatusApiFp.apiV1CameraDeviceHealthStatusDeactivateSerialNumberPut(params, options)(fetch, basePath);
        },
        /** 
         * @param body 
         */
        apiV1CameraDeviceHealthStatusPingPost(params: {  "body"?: RequestPingRequest; }, options?: any) {
            return CameraDeviceHealthStatusApiFp.apiV1CameraDeviceHealthStatusPingPost(params, options)(fetch, basePath);
        },
        /** 
         * @param body 
         */
        apiV1CameraDeviceHealthStatusRestartServicePost(params: {  "body"?: RequestRebootRequest; }, options?: any) {
            return CameraDeviceHealthStatusApiFp.apiV1CameraDeviceHealthStatusRestartServicePost(params, options)(fetch, basePath);
        },
        /** 
         * @param body 
         */
        apiV1CameraDeviceHealthStatusUpdateCameraFleetPut(params: {  "body"?: UpdateCameraFleetRequest; }, options?: any) {
            return CameraDeviceHealthStatusApiFp.apiV1CameraDeviceHealthStatusUpdateCameraFleetPut(params, options)(fetch, basePath);
        },
    };
};


/**
 * CameraDeviceLogApi - fetch parameter creator
 */
export const CameraDeviceLogApiFetchParamCreator = {
    /** 
     * Retrieves the log for the camera Request Id supplied or the most recent successful log request for the camera  if no CameraRequest Id is provided.
     * @param serialNumber 
     * @param cameraRequestId Optional Paramter- If not applied will return most recent log.
     */
    apiV1CameraDeviceLogSerialNumberLogRequestCameraRequestIdGet(params: {  "serialNumber": string; "cameraRequestId": number; }, options?: any): FetchArgs {
        // verify required parameter "serialNumber" is set
        if (params["serialNumber"] == null) {
            throw new Error("Missing required parameter serialNumber when calling apiV1CameraDeviceLogSerialNumberLogRequestCameraRequestIdGet");
        }
        // verify required parameter "cameraRequestId" is set
        if (params["cameraRequestId"] == null) {
            throw new Error("Missing required parameter cameraRequestId when calling apiV1CameraDeviceLogSerialNumberLogRequestCameraRequestIdGet");
        }
        const baseUrl = `/api/v1/CameraDeviceLog/{serialNumber}/logRequest/{cameraRequestId}`
            .replace(`{${"serialNumber"}}`, `${ params["serialNumber"] }`)
            .replace(`{${"cameraRequestId"}}`, `${ params["cameraRequestId"] }`);
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Creates a Camera Request record with the type of Log  This record will then be picked up by a processor to retrieve the log.
     * @param serialNumber 
     */
    apiV1CameraDeviceLogSerialNumberLogRequestPut(params: {  "serialNumber": string; }, options?: any): FetchArgs {
        // verify required parameter "serialNumber" is set
        if (params["serialNumber"] == null) {
            throw new Error("Missing required parameter serialNumber when calling apiV1CameraDeviceLogSerialNumberLogRequestPut");
        }
        const baseUrl = `/api/v1/CameraDeviceLog/{serialNumber}/logRequest`
            .replace(`{${"serialNumber"}}`, `${ params["serialNumber"] }`);
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "PUT" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Retrieve all Logs for a specific camera based off camera serial number
     * @param serialNumber 
     * @param currentPage 
     * @param itemsPerPage 
     */
    apiV1CameraDeviceLogSerialNumberLogRequestsGet(params: {  "serialNumber": string; "currentPage"?: number; "itemsPerPage"?: number; }, options?: any): FetchArgs {
        // verify required parameter "serialNumber" is set
        if (params["serialNumber"] == null) {
            throw new Error("Missing required parameter serialNumber when calling apiV1CameraDeviceLogSerialNumberLogRequestsGet");
        }
        const baseUrl = `/api/v1/CameraDeviceLog/{serialNumber}/LogRequests`
            .replace(`{${"serialNumber"}}`, `${ params["serialNumber"] }`);
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "currentPage": params["currentPage"],
            "itemsPerPage": params["itemsPerPage"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
};

/**
 * CameraDeviceLogApi - functional programming interface
 */
export const CameraDeviceLogApiFp = {
    /** 
     * Retrieves the log for the camera Request Id supplied or the most recent successful log request for the camera  if no CameraRequest Id is provided.
     * @param serialNumber 
     * @param cameraRequestId Optional Paramter- If not applied will return most recent log.
     */
    apiV1CameraDeviceLogSerialNumberLogRequestCameraRequestIdGet(params: { "serialNumber": string; "cameraRequestId": number;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<string> {
        const fetchArgs = CameraDeviceLogApiFetchParamCreator.apiV1CameraDeviceLogSerialNumberLogRequestCameraRequestIdGet(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Creates a Camera Request record with the type of Log  This record will then be picked up by a processor to retrieve the log.
     * @param serialNumber 
     */
    apiV1CameraDeviceLogSerialNumberLogRequestPut(params: { "serialNumber": string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<number> {
        const fetchArgs = CameraDeviceLogApiFetchParamCreator.apiV1CameraDeviceLogSerialNumberLogRequestPut(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Retrieve all Logs for a specific camera based off camera serial number
     * @param serialNumber 
     * @param currentPage 
     * @param itemsPerPage 
     */
    apiV1CameraDeviceLogSerialNumberLogRequestsGet(params: { "serialNumber": string; "currentPage"?: number; "itemsPerPage"?: number;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<LogRequestResponseIEnumerablePagedResponseBase> {
        const fetchArgs = CameraDeviceLogApiFetchParamCreator.apiV1CameraDeviceLogSerialNumberLogRequestsGet(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
};

/**
 * CameraDeviceLogApi - object-oriented interface
 */
export class CameraDeviceLogApi extends BaseAPI {
    /** 
     * Retrieves the log for the camera Request Id supplied or the most recent successful log request for the camera  if no CameraRequest Id is provided.
     * @param serialNumber 
     * @param cameraRequestId Optional Paramter- If not applied will return most recent log.
     */
    apiV1CameraDeviceLogSerialNumberLogRequestCameraRequestIdGet(params: {  "serialNumber": string; "cameraRequestId": number; }, options?: any) {
        return CameraDeviceLogApiFp.apiV1CameraDeviceLogSerialNumberLogRequestCameraRequestIdGet(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Creates a Camera Request record with the type of Log  This record will then be picked up by a processor to retrieve the log.
     * @param serialNumber 
     */
    apiV1CameraDeviceLogSerialNumberLogRequestPut(params: {  "serialNumber": string; }, options?: any) {
        return CameraDeviceLogApiFp.apiV1CameraDeviceLogSerialNumberLogRequestPut(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Retrieve all Logs for a specific camera based off camera serial number
     * @param serialNumber 
     * @param currentPage 
     * @param itemsPerPage 
     */
    apiV1CameraDeviceLogSerialNumberLogRequestsGet(params: {  "serialNumber": string; "currentPage"?: number; "itemsPerPage"?: number; }, options?: any) {
        return CameraDeviceLogApiFp.apiV1CameraDeviceLogSerialNumberLogRequestsGet(params, options)(this.fetch, this.basePath);
    }
};

/**
 * CameraDeviceLogApi - factory interface
 */
export const CameraDeviceLogApiFactory = function (fetch?: FetchAPI, basePath?: string) {
    return {
        /** 
         * Retrieves the log for the camera Request Id supplied or the most recent successful log request for the camera  if no CameraRequest Id is provided.
         * @param serialNumber 
         * @param cameraRequestId Optional Paramter- If not applied will return most recent log.
         */
        apiV1CameraDeviceLogSerialNumberLogRequestCameraRequestIdGet(params: {  "serialNumber": string; "cameraRequestId": number; }, options?: any) {
            return CameraDeviceLogApiFp.apiV1CameraDeviceLogSerialNumberLogRequestCameraRequestIdGet(params, options)(fetch, basePath);
        },
        /** 
         * Creates a Camera Request record with the type of Log  This record will then be picked up by a processor to retrieve the log.
         * @param serialNumber 
         */
        apiV1CameraDeviceLogSerialNumberLogRequestPut(params: {  "serialNumber": string; }, options?: any) {
            return CameraDeviceLogApiFp.apiV1CameraDeviceLogSerialNumberLogRequestPut(params, options)(fetch, basePath);
        },
        /** 
         * Retrieve all Logs for a specific camera based off camera serial number
         * @param serialNumber 
         * @param currentPage 
         * @param itemsPerPage 
         */
        apiV1CameraDeviceLogSerialNumberLogRequestsGet(params: {  "serialNumber": string; "currentPage"?: number; "itemsPerPage"?: number; }, options?: any) {
            return CameraDeviceLogApiFp.apiV1CameraDeviceLogSerialNumberLogRequestsGet(params, options)(fetch, basePath);
        },
    };
};


/**
 * ConfigApi - fetch parameter creator
 */
export const ConfigApiFetchParamCreator = {
    /** 
     */
    apiV1ConfigGetClientConfigGet(options?: any): FetchArgs {
        const baseUrl = `/api/v1/config/getClientConfig`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
};

/**
 * ConfigApi - functional programming interface
 */
export const ConfigApiFp = {
    /** 
     */
    apiV1ConfigGetClientConfigGet(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ClientConfigResponse> {
        const fetchArgs = ConfigApiFetchParamCreator.apiV1ConfigGetClientConfigGet(options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
};

/**
 * ConfigApi - object-oriented interface
 */
export class ConfigApi extends BaseAPI {
    /** 
     */
    apiV1ConfigGetClientConfigGet(options?: any) {
        return ConfigApiFp.apiV1ConfigGetClientConfigGet(options)(this.fetch, this.basePath);
    }
};

/**
 * ConfigApi - factory interface
 */
export const ConfigApiFactory = function (fetch?: FetchAPI, basePath?: string) {
    return {
        /** 
         */
        apiV1ConfigGetClientConfigGet(options?: any) {
            return ConfigApiFp.apiV1ConfigGetClientConfigGet(options)(fetch, basePath);
        },
    };
};


/**
 * DashboardApi - fetch parameter creator
 */
export const DashboardApiFetchParamCreator = {
    /** 
     * @param midnight 
     */
    apiV1DashboardDataGet(params: {  "midnight"?: Date; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/dashboard/data`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "midnight": params["midnight"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
};

/**
 * DashboardApi - functional programming interface
 */
export const DashboardApiFp = {
    /** 
     * @param midnight 
     */
    apiV1DashboardDataGet(params: { "midnight"?: Date;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AdminDashboardResponse> {
        const fetchArgs = DashboardApiFetchParamCreator.apiV1DashboardDataGet(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
};

/**
 * DashboardApi - object-oriented interface
 */
export class DashboardApi extends BaseAPI {
    /** 
     * @param midnight 
     */
    apiV1DashboardDataGet(params: {  "midnight"?: Date; }, options?: any) {
        return DashboardApiFp.apiV1DashboardDataGet(params, options)(this.fetch, this.basePath);
    }
};

/**
 * DashboardApi - factory interface
 */
export const DashboardApiFactory = function (fetch?: FetchAPI, basePath?: string) {
    return {
        /** 
         * @param midnight 
         */
        apiV1DashboardDataGet(params: {  "midnight"?: Date; }, options?: any) {
            return DashboardApiFp.apiV1DashboardDataGet(params, options)(fetch, basePath);
        },
    };
};


/**
 * DeviceManagementApi - fetch parameter creator
 */
export const DeviceManagementApiFetchParamCreator = {
    /** 
     * activates Camera
     * @param id camera id
     */
    apiV1ActivatecameraPost(params: {  "id"?: number; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/activatecamera`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "id": params["id"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * @param fleetId 
     * @param cameraId 
     */
    apiV1CameraAssociationGet(params: {  "fleetId"?: number; "cameraId"?: number; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/cameraAssociation`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "fleetId": params["fleetId"],
            "cameraId": params["cameraId"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     */
    apiV1CameratypesGet(options?: any): FetchArgs {
        const baseUrl = `/api/v1/cameratypes`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Gets the filter data for the device history grid
     */
    apiV1DeviceHistoryFiltersGet(options?: any): FetchArgs {
        const baseUrl = `/api/v1/deviceHistory/filters`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Get History of a particular device pairing
     * @param id 
     * @param sortBy 
     * @param sortAsc 
     * @param actionFilter 
     */
    apiV1DeviceHistoryGet(params: {  "id"?: number; "sortBy"?: string; "sortAsc"?: boolean; "actionFilter"?: string; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/deviceHistory`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "id": params["id"],
            "sortBy": params["sortBy"],
            "sortAsc": params["sortAsc"],
            "actionFilter": params["actionFilter"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * @param body 
     */
    apiV1DeviceassociationBatchDevicesPost(params: {  "body"?: Array<string>; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/deviceassociation/batchDevices`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["body"]) {
            fetchOptions.body = JSON.stringify(params["body"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Gets the fleet information for a camera.
     * @param cameraSerialNumber Camera serialnumber
     */
    apiV1DeviceassociationCameraFleetGet(params: {  "cameraSerialNumber"?: string; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/deviceassociation/cameraFleet`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "cameraSerialNumber": params["cameraSerialNumber"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Gets the fleet information for a company.
     * @param companyId Company unique Id
     */
    apiV1DeviceassociationCompanyFleetGet(params: {  "companyId"?: string; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/deviceassociation/companyFleet`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "companyId": params["companyId"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * @param body 
     */
    apiV1DeviceassociationPost(params: {  "body"?: CreatePairingRequest; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/deviceassociation`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["body"]) {
            fetchOptions.body = JSON.stringify(params["body"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Updates the fleet setting for a list of cameras.  Cameras will be unpared after moving
     * @param body request to update multiple cameras to a new fleet
     */
    apiV1DeviceassociationUpdateCameraFleetPost(params: {  "body"?: BulkUpdateFleetRequest; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/deviceassociation/updateCameraFleet`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["body"]) {
            fetchOptions.body = JSON.stringify(params["body"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Gets the filter data for the device management page
     */
    apiV1DeviceassociationsFiltersGet(options?: any): FetchArgs {
        const baseUrl = `/api/v1/deviceassociations/filters`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Get all information for a particular pairing
     * @param id 
     */
    apiV1DevicedetailsGet(params: {  "id"?: number; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/devicedetails`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "id": params["id"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Get All Device Camera Pairings
     * @param currentPage 
     * @param itemsPerPage 
     * @param sortBy 
     * @param sortAsc 
     * @param search 
     * @param fleetIds 
     * @param deviceTypes 
     * @param deviceStatuses 
     */
    apiV1DevicesGet(params: {  "currentPage"?: number; "itemsPerPage"?: number; "sortBy"?: string; "sortAsc"?: boolean; "search"?: string; "fleetIds"?: Array<number>; "deviceTypes"?: Array<number>; "deviceStatuses"?: Array<string>; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/devices`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "currentPage": params["currentPage"],
            "itemsPerPage": params["itemsPerPage"],
            "sortBy": params["sortBy"],
            "sortAsc": params["sortAsc"],
            "search": params["search"],
            "fleetIds": params["fleetIds"],
            "deviceTypes": params["deviceTypes"],
            "deviceStatuses": params["deviceStatuses"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Get data usage by iccid
     * @param iccid 
     */
    apiV1IndividualdatausageGet(params: {  "iccid"?: string; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/individualdatausage`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "iccid": params["iccid"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * @param fleetId 
     * @param cameraDeviceType 
     * @param searchString 
     */
    apiV1SearchcamerasGet(params: {  "fleetId"?: number; "cameraDeviceType"?: number; "searchString"?: string; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/searchcameras`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "fleetId": params["fleetId"],
            "cameraDeviceType": params["cameraDeviceType"],
            "searchString": params["searchString"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Suspends Camera
     * @param id camera id
     */
    apiV1SuspendcameraPost(params: {  "id"?: number; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/suspendcamera`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "id": params["id"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * @param fleetId 
     * @param telematicProviderId 
     * @param telematicDeviceId 
     */
    apiV1TelematicDeviceAssociationGet(params: {  "fleetId"?: number; "telematicProviderId"?: string; "telematicDeviceId"?: string; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/telematicDeviceAssociation`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "fleetId": params["fleetId"],
            "telematicProviderId": params["telematicProviderId"],
            "telematicDeviceId": params["telematicDeviceId"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
};

/**
 * DeviceManagementApi - functional programming interface
 */
export const DeviceManagementApiFp = {
    /** 
     * activates Camera
     * @param id camera id
     */
    apiV1ActivatecameraPost(params: { "id"?: number;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = DeviceManagementApiFetchParamCreator.apiV1ActivatecameraPost(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * @param fleetId 
     * @param cameraId 
     */
    apiV1CameraAssociationGet(params: { "fleetId"?: number; "cameraId"?: number;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<DeviceAssociationValidationResponse> {
        const fetchArgs = DeviceManagementApiFetchParamCreator.apiV1CameraAssociationGet(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     */
    apiV1CameratypesGet(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<LookupResponse>> {
        const fetchArgs = DeviceManagementApiFetchParamCreator.apiV1CameratypesGet(options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Gets the filter data for the device history grid
     */
    apiV1DeviceHistoryFiltersGet(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<DropdownMenuItem>> {
        const fetchArgs = DeviceManagementApiFetchParamCreator.apiV1DeviceHistoryFiltersGet(options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Get History of a particular device pairing
     * @param id 
     * @param sortBy 
     * @param sortAsc 
     * @param actionFilter 
     */
    apiV1DeviceHistoryGet(params: { "id"?: number; "sortBy"?: string; "sortAsc"?: boolean; "actionFilter"?: string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<DeviceHistoryResponse>> {
        const fetchArgs = DeviceManagementApiFetchParamCreator.apiV1DeviceHistoryGet(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * @param body 
     */
    apiV1DeviceassociationBatchDevicesPost(params: { "body"?: Array<string>;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<DeviceBatchResponse> {
        const fetchArgs = DeviceManagementApiFetchParamCreator.apiV1DeviceassociationBatchDevicesPost(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Gets the fleet information for a camera.
     * @param cameraSerialNumber Camera serialnumber
     */
    apiV1DeviceassociationCameraFleetGet(params: { "cameraSerialNumber"?: string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<FleetResponse> {
        const fetchArgs = DeviceManagementApiFetchParamCreator.apiV1DeviceassociationCameraFleetGet(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Gets the fleet information for a company.
     * @param companyId Company unique Id
     */
    apiV1DeviceassociationCompanyFleetGet(params: { "companyId"?: string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<FleetResponse> {
        const fetchArgs = DeviceManagementApiFetchParamCreator.apiV1DeviceassociationCompanyFleetGet(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * @param body 
     */
    apiV1DeviceassociationPost(params: { "body"?: CreatePairingRequest;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = DeviceManagementApiFetchParamCreator.apiV1DeviceassociationPost(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Updates the fleet setting for a list of cameras.  Cameras will be unpared after moving
     * @param body request to update multiple cameras to a new fleet
     */
    apiV1DeviceassociationUpdateCameraFleetPost(params: { "body"?: BulkUpdateFleetRequest;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = DeviceManagementApiFetchParamCreator.apiV1DeviceassociationUpdateCameraFleetPost(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Gets the filter data for the device management page
     */
    apiV1DeviceassociationsFiltersGet(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<DeviceManagementFilterDataResponse> {
        const fetchArgs = DeviceManagementApiFetchParamCreator.apiV1DeviceassociationsFiltersGet(options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Get all information for a particular pairing
     * @param id 
     */
    apiV1DevicedetailsGet(params: { "id"?: number;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CameraDeviceResponse> {
        const fetchArgs = DeviceManagementApiFetchParamCreator.apiV1DevicedetailsGet(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Get All Device Camera Pairings
     * @param currentPage 
     * @param itemsPerPage 
     * @param sortBy 
     * @param sortAsc 
     * @param search 
     * @param fleetIds 
     * @param deviceTypes 
     * @param deviceStatuses 
     */
    apiV1DevicesGet(params: { "currentPage"?: number; "itemsPerPage"?: number; "sortBy"?: string; "sortAsc"?: boolean; "search"?: string; "fleetIds"?: Array<number>; "deviceTypes"?: Array<number>; "deviceStatuses"?: Array<string>;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<DeviceHealthResponseIEnumerablePagedResponseBase> {
        const fetchArgs = DeviceManagementApiFetchParamCreator.apiV1DevicesGet(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Get data usage by iccid
     * @param iccid 
     */
    apiV1IndividualdatausageGet(params: { "iccid"?: string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<DataUsageResponse>> {
        const fetchArgs = DeviceManagementApiFetchParamCreator.apiV1IndividualdatausageGet(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * @param fleetId 
     * @param cameraDeviceType 
     * @param searchString 
     */
    apiV1SearchcamerasGet(params: { "fleetId"?: number; "cameraDeviceType"?: number; "searchString"?: string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<CameraResponse>> {
        const fetchArgs = DeviceManagementApiFetchParamCreator.apiV1SearchcamerasGet(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Suspends Camera
     * @param id camera id
     */
    apiV1SuspendcameraPost(params: { "id"?: number;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = DeviceManagementApiFetchParamCreator.apiV1SuspendcameraPost(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * @param fleetId 
     * @param telematicProviderId 
     * @param telematicDeviceId 
     */
    apiV1TelematicDeviceAssociationGet(params: { "fleetId"?: number; "telematicProviderId"?: string; "telematicDeviceId"?: string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<DeviceAssociationValidationResponse> {
        const fetchArgs = DeviceManagementApiFetchParamCreator.apiV1TelematicDeviceAssociationGet(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
};

/**
 * DeviceManagementApi - object-oriented interface
 */
export class DeviceManagementApi extends BaseAPI {
    /** 
     * activates Camera
     * @param id camera id
     */
    apiV1ActivatecameraPost(params: {  "id"?: number; }, options?: any) {
        return DeviceManagementApiFp.apiV1ActivatecameraPost(params, options)(this.fetch, this.basePath);
    }
    /** 
     * @param fleetId 
     * @param cameraId 
     */
    apiV1CameraAssociationGet(params: {  "fleetId"?: number; "cameraId"?: number; }, options?: any) {
        return DeviceManagementApiFp.apiV1CameraAssociationGet(params, options)(this.fetch, this.basePath);
    }
    /** 
     */
    apiV1CameratypesGet(options?: any) {
        return DeviceManagementApiFp.apiV1CameratypesGet(options)(this.fetch, this.basePath);
    }
    /** 
     * Gets the filter data for the device history grid
     */
    apiV1DeviceHistoryFiltersGet(options?: any) {
        return DeviceManagementApiFp.apiV1DeviceHistoryFiltersGet(options)(this.fetch, this.basePath);
    }
    /** 
     * Get History of a particular device pairing
     * @param id 
     * @param sortBy 
     * @param sortAsc 
     * @param actionFilter 
     */
    apiV1DeviceHistoryGet(params: {  "id"?: number; "sortBy"?: string; "sortAsc"?: boolean; "actionFilter"?: string; }, options?: any) {
        return DeviceManagementApiFp.apiV1DeviceHistoryGet(params, options)(this.fetch, this.basePath);
    }
    /** 
     * @param body 
     */
    apiV1DeviceassociationBatchDevicesPost(params: {  "body"?: Array<string>; }, options?: any) {
        return DeviceManagementApiFp.apiV1DeviceassociationBatchDevicesPost(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Gets the fleet information for a camera.
     * @param cameraSerialNumber Camera serialnumber
     */
    apiV1DeviceassociationCameraFleetGet(params: {  "cameraSerialNumber"?: string; }, options?: any) {
        return DeviceManagementApiFp.apiV1DeviceassociationCameraFleetGet(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Gets the fleet information for a company.
     * @param companyId Company unique Id
     */
    apiV1DeviceassociationCompanyFleetGet(params: {  "companyId"?: string; }, options?: any) {
        return DeviceManagementApiFp.apiV1DeviceassociationCompanyFleetGet(params, options)(this.fetch, this.basePath);
    }
    /** 
     * @param body 
     */
    apiV1DeviceassociationPost(params: {  "body"?: CreatePairingRequest; }, options?: any) {
        return DeviceManagementApiFp.apiV1DeviceassociationPost(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Updates the fleet setting for a list of cameras.  Cameras will be unpared after moving
     * @param body request to update multiple cameras to a new fleet
     */
    apiV1DeviceassociationUpdateCameraFleetPost(params: {  "body"?: BulkUpdateFleetRequest; }, options?: any) {
        return DeviceManagementApiFp.apiV1DeviceassociationUpdateCameraFleetPost(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Gets the filter data for the device management page
     */
    apiV1DeviceassociationsFiltersGet(options?: any) {
        return DeviceManagementApiFp.apiV1DeviceassociationsFiltersGet(options)(this.fetch, this.basePath);
    }
    /** 
     * Get all information for a particular pairing
     * @param id 
     */
    apiV1DevicedetailsGet(params: {  "id"?: number; }, options?: any) {
        return DeviceManagementApiFp.apiV1DevicedetailsGet(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Get All Device Camera Pairings
     * @param currentPage 
     * @param itemsPerPage 
     * @param sortBy 
     * @param sortAsc 
     * @param search 
     * @param fleetIds 
     * @param deviceTypes 
     * @param deviceStatuses 
     */
    apiV1DevicesGet(params: {  "currentPage"?: number; "itemsPerPage"?: number; "sortBy"?: string; "sortAsc"?: boolean; "search"?: string; "fleetIds"?: Array<number>; "deviceTypes"?: Array<number>; "deviceStatuses"?: Array<string>; }, options?: any) {
        return DeviceManagementApiFp.apiV1DevicesGet(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Get data usage by iccid
     * @param iccid 
     */
    apiV1IndividualdatausageGet(params: {  "iccid"?: string; }, options?: any) {
        return DeviceManagementApiFp.apiV1IndividualdatausageGet(params, options)(this.fetch, this.basePath);
    }
    /** 
     * @param fleetId 
     * @param cameraDeviceType 
     * @param searchString 
     */
    apiV1SearchcamerasGet(params: {  "fleetId"?: number; "cameraDeviceType"?: number; "searchString"?: string; }, options?: any) {
        return DeviceManagementApiFp.apiV1SearchcamerasGet(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Suspends Camera
     * @param id camera id
     */
    apiV1SuspendcameraPost(params: {  "id"?: number; }, options?: any) {
        return DeviceManagementApiFp.apiV1SuspendcameraPost(params, options)(this.fetch, this.basePath);
    }
    /** 
     * @param fleetId 
     * @param telematicProviderId 
     * @param telematicDeviceId 
     */
    apiV1TelematicDeviceAssociationGet(params: {  "fleetId"?: number; "telematicProviderId"?: string; "telematicDeviceId"?: string; }, options?: any) {
        return DeviceManagementApiFp.apiV1TelematicDeviceAssociationGet(params, options)(this.fetch, this.basePath);
    }
};

/**
 * DeviceManagementApi - factory interface
 */
export const DeviceManagementApiFactory = function (fetch?: FetchAPI, basePath?: string) {
    return {
        /** 
         * activates Camera
         * @param id camera id
         */
        apiV1ActivatecameraPost(params: {  "id"?: number; }, options?: any) {
            return DeviceManagementApiFp.apiV1ActivatecameraPost(params, options)(fetch, basePath);
        },
        /** 
         * @param fleetId 
         * @param cameraId 
         */
        apiV1CameraAssociationGet(params: {  "fleetId"?: number; "cameraId"?: number; }, options?: any) {
            return DeviceManagementApiFp.apiV1CameraAssociationGet(params, options)(fetch, basePath);
        },
        /** 
         */
        apiV1CameratypesGet(options?: any) {
            return DeviceManagementApiFp.apiV1CameratypesGet(options)(fetch, basePath);
        },
        /** 
         * Gets the filter data for the device history grid
         */
        apiV1DeviceHistoryFiltersGet(options?: any) {
            return DeviceManagementApiFp.apiV1DeviceHistoryFiltersGet(options)(fetch, basePath);
        },
        /** 
         * Get History of a particular device pairing
         * @param id 
         * @param sortBy 
         * @param sortAsc 
         * @param actionFilter 
         */
        apiV1DeviceHistoryGet(params: {  "id"?: number; "sortBy"?: string; "sortAsc"?: boolean; "actionFilter"?: string; }, options?: any) {
            return DeviceManagementApiFp.apiV1DeviceHistoryGet(params, options)(fetch, basePath);
        },
        /** 
         * @param body 
         */
        apiV1DeviceassociationBatchDevicesPost(params: {  "body"?: Array<string>; }, options?: any) {
            return DeviceManagementApiFp.apiV1DeviceassociationBatchDevicesPost(params, options)(fetch, basePath);
        },
        /** 
         * Gets the fleet information for a camera.
         * @param cameraSerialNumber Camera serialnumber
         */
        apiV1DeviceassociationCameraFleetGet(params: {  "cameraSerialNumber"?: string; }, options?: any) {
            return DeviceManagementApiFp.apiV1DeviceassociationCameraFleetGet(params, options)(fetch, basePath);
        },
        /** 
         * Gets the fleet information for a company.
         * @param companyId Company unique Id
         */
        apiV1DeviceassociationCompanyFleetGet(params: {  "companyId"?: string; }, options?: any) {
            return DeviceManagementApiFp.apiV1DeviceassociationCompanyFleetGet(params, options)(fetch, basePath);
        },
        /** 
         * @param body 
         */
        apiV1DeviceassociationPost(params: {  "body"?: CreatePairingRequest; }, options?: any) {
            return DeviceManagementApiFp.apiV1DeviceassociationPost(params, options)(fetch, basePath);
        },
        /** 
         * Updates the fleet setting for a list of cameras.  Cameras will be unpared after moving
         * @param body request to update multiple cameras to a new fleet
         */
        apiV1DeviceassociationUpdateCameraFleetPost(params: {  "body"?: BulkUpdateFleetRequest; }, options?: any) {
            return DeviceManagementApiFp.apiV1DeviceassociationUpdateCameraFleetPost(params, options)(fetch, basePath);
        },
        /** 
         * Gets the filter data for the device management page
         */
        apiV1DeviceassociationsFiltersGet(options?: any) {
            return DeviceManagementApiFp.apiV1DeviceassociationsFiltersGet(options)(fetch, basePath);
        },
        /** 
         * Get all information for a particular pairing
         * @param id 
         */
        apiV1DevicedetailsGet(params: {  "id"?: number; }, options?: any) {
            return DeviceManagementApiFp.apiV1DevicedetailsGet(params, options)(fetch, basePath);
        },
        /** 
         * Get All Device Camera Pairings
         * @param currentPage 
         * @param itemsPerPage 
         * @param sortBy 
         * @param sortAsc 
         * @param search 
         * @param fleetIds 
         * @param deviceTypes 
         * @param deviceStatuses 
         */
        apiV1DevicesGet(params: {  "currentPage"?: number; "itemsPerPage"?: number; "sortBy"?: string; "sortAsc"?: boolean; "search"?: string; "fleetIds"?: Array<number>; "deviceTypes"?: Array<number>; "deviceStatuses"?: Array<string>; }, options?: any) {
            return DeviceManagementApiFp.apiV1DevicesGet(params, options)(fetch, basePath);
        },
        /** 
         * Get data usage by iccid
         * @param iccid 
         */
        apiV1IndividualdatausageGet(params: {  "iccid"?: string; }, options?: any) {
            return DeviceManagementApiFp.apiV1IndividualdatausageGet(params, options)(fetch, basePath);
        },
        /** 
         * @param fleetId 
         * @param cameraDeviceType 
         * @param searchString 
         */
        apiV1SearchcamerasGet(params: {  "fleetId"?: number; "cameraDeviceType"?: number; "searchString"?: string; }, options?: any) {
            return DeviceManagementApiFp.apiV1SearchcamerasGet(params, options)(fetch, basePath);
        },
        /** 
         * Suspends Camera
         * @param id camera id
         */
        apiV1SuspendcameraPost(params: {  "id"?: number; }, options?: any) {
            return DeviceManagementApiFp.apiV1SuspendcameraPost(params, options)(fetch, basePath);
        },
        /** 
         * @param fleetId 
         * @param telematicProviderId 
         * @param telematicDeviceId 
         */
        apiV1TelematicDeviceAssociationGet(params: {  "fleetId"?: number; "telematicProviderId"?: string; "telematicDeviceId"?: string; }, options?: any) {
            return DeviceManagementApiFp.apiV1TelematicDeviceAssociationGet(params, options)(fetch, basePath);
        },
    };
};


/**
 * FleetApi - fetch parameter creator
 */
export const FleetApiFetchParamCreator = {
    /** 
     */
    apiV1FleetsGet(options?: any): FetchArgs {
        const baseUrl = `/api/v1/fleets`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     */
    apiV1TelematicprovidersGet(options?: any): FetchArgs {
        const baseUrl = `/api/v1/telematicproviders`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
};

/**
 * FleetApi - functional programming interface
 */
export const FleetApiFp = {
    /** 
     */
    apiV1FleetsGet(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<LookupResponse>> {
        const fetchArgs = FleetApiFetchParamCreator.apiV1FleetsGet(options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     */
    apiV1TelematicprovidersGet(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<LookupResponse>> {
        const fetchArgs = FleetApiFetchParamCreator.apiV1TelematicprovidersGet(options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
};

/**
 * FleetApi - object-oriented interface
 */
export class FleetApi extends BaseAPI {
    /** 
     */
    apiV1FleetsGet(options?: any) {
        return FleetApiFp.apiV1FleetsGet(options)(this.fetch, this.basePath);
    }
    /** 
     */
    apiV1TelematicprovidersGet(options?: any) {
        return FleetApiFp.apiV1TelematicprovidersGet(options)(this.fetch, this.basePath);
    }
};

/**
 * FleetApi - factory interface
 */
export const FleetApiFactory = function (fetch?: FetchAPI, basePath?: string) {
    return {
        /** 
         */
        apiV1FleetsGet(options?: any) {
            return FleetApiFp.apiV1FleetsGet(options)(fetch, basePath);
        },
        /** 
         */
        apiV1TelematicprovidersGet(options?: any) {
            return FleetApiFp.apiV1TelematicprovidersGet(options)(fetch, basePath);
        },
    };
};


/**
 * HealthyApi - fetch parameter creator
 */
export const HealthyApiFetchParamCreator = {
    /** 
     * Create an empty response to allow external actors to verify if application is online
     */
    apiV1HealthAdminuiGet(options?: any): FetchArgs {
        const baseUrl = `/api/v1/health/adminui`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
};

/**
 * HealthyApi - functional programming interface
 */
export const HealthyApiFp = {
    /** 
     * Create an empty response to allow external actors to verify if application is online
     */
    apiV1HealthAdminuiGet(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<HealthCheckResponseResponseBase> {
        const fetchArgs = HealthyApiFetchParamCreator.apiV1HealthAdminuiGet(options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
};

/**
 * HealthyApi - object-oriented interface
 */
export class HealthyApi extends BaseAPI {
    /** 
     * Create an empty response to allow external actors to verify if application is online
     */
    apiV1HealthAdminuiGet(options?: any) {
        return HealthyApiFp.apiV1HealthAdminuiGet(options)(this.fetch, this.basePath);
    }
};

/**
 * HealthyApi - factory interface
 */
export const HealthyApiFactory = function (fetch?: FetchAPI, basePath?: string) {
    return {
        /** 
         * Create an empty response to allow external actors to verify if application is online
         */
        apiV1HealthAdminuiGet(options?: any) {
            return HealthyApiFp.apiV1HealthAdminuiGet(options)(fetch, basePath);
        },
    };
};


/**
 * IdentityApi - fetch parameter creator
 */
export const IdentityApiFetchParamCreator = {
    /** 
     * @param body 
     */
    apiV1AddUserPost(params: {  "body"?: CreateUserRequest; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/addUser`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["body"]) {
            fetchOptions.body = JSON.stringify(params["body"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     */
    apiV1CurrentUserGet(options?: any): FetchArgs {
        const baseUrl = `/api/v1/currentUser`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * @param body 
     */
    apiV1DeleteUserPost(params: {  "body"?: IdentityUser; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/deleteUser`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["body"]) {
            fetchOptions.body = JSON.stringify(params["body"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * @param body 
     */
    apiV1ForgetPasswordPost(params: {  "body"?: string; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/forgetPassword`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["body"]) {
            fetchOptions.body = JSON.stringify(params["body"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     */
    apiV1GetUsersGet(options?: any): FetchArgs {
        const baseUrl = `/api/v1/getUsers`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * @param body 
     */
    apiV1LoginPost(params: {  "body"?: LoginUserRequest; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/login`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["body"]) {
            fetchOptions.body = JSON.stringify(params["body"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     */
    apiV1LogoutPost(options?: any): FetchArgs {
        const baseUrl = `/api/v1/logout`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * @param body 
     */
    apiV1ResetPasswordPost(params: {  "body"?: LoginUserRequest; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/resetPassword`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["body"]) {
            fetchOptions.body = JSON.stringify(params["body"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * @param body 
     */
    apiV1UpdateUserPost(params: {  "body"?: UpdateUserRequest; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/updateUser`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["body"]) {
            fetchOptions.body = JSON.stringify(params["body"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
};

/**
 * IdentityApi - functional programming interface
 */
export const IdentityApiFp = {
    /** 
     * @param body 
     */
    apiV1AddUserPost(params: { "body"?: CreateUserRequest;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = IdentityApiFetchParamCreator.apiV1AddUserPost(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     */
    apiV1CurrentUserGet(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<UserResponse> {
        const fetchArgs = IdentityApiFetchParamCreator.apiV1CurrentUserGet(options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * @param body 
     */
    apiV1DeleteUserPost(params: { "body"?: IdentityUser;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = IdentityApiFetchParamCreator.apiV1DeleteUserPost(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * @param body 
     */
    apiV1ForgetPasswordPost(params: { "body"?: string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = IdentityApiFetchParamCreator.apiV1ForgetPasswordPost(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     */
    apiV1GetUsersGet(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<AdminUserResponse>> {
        const fetchArgs = IdentityApiFetchParamCreator.apiV1GetUsersGet(options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * @param body 
     */
    apiV1LoginPost(params: { "body"?: LoginUserRequest;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = IdentityApiFetchParamCreator.apiV1LoginPost(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     */
    apiV1LogoutPost(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = IdentityApiFetchParamCreator.apiV1LogoutPost(options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * @param body 
     */
    apiV1ResetPasswordPost(params: { "body"?: LoginUserRequest;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = IdentityApiFetchParamCreator.apiV1ResetPasswordPost(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * @param body 
     */
    apiV1UpdateUserPost(params: { "body"?: UpdateUserRequest;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = IdentityApiFetchParamCreator.apiV1UpdateUserPost(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
};

/**
 * IdentityApi - object-oriented interface
 */
export class IdentityApi extends BaseAPI {
    /** 
     * @param body 
     */
    apiV1AddUserPost(params: {  "body"?: CreateUserRequest; }, options?: any) {
        return IdentityApiFp.apiV1AddUserPost(params, options)(this.fetch, this.basePath);
    }
    /** 
     */
    apiV1CurrentUserGet(options?: any) {
        return IdentityApiFp.apiV1CurrentUserGet(options)(this.fetch, this.basePath);
    }
    /** 
     * @param body 
     */
    apiV1DeleteUserPost(params: {  "body"?: IdentityUser; }, options?: any) {
        return IdentityApiFp.apiV1DeleteUserPost(params, options)(this.fetch, this.basePath);
    }
    /** 
     * @param body 
     */
    apiV1ForgetPasswordPost(params: {  "body"?: string; }, options?: any) {
        return IdentityApiFp.apiV1ForgetPasswordPost(params, options)(this.fetch, this.basePath);
    }
    /** 
     */
    apiV1GetUsersGet(options?: any) {
        return IdentityApiFp.apiV1GetUsersGet(options)(this.fetch, this.basePath);
    }
    /** 
     * @param body 
     */
    apiV1LoginPost(params: {  "body"?: LoginUserRequest; }, options?: any) {
        return IdentityApiFp.apiV1LoginPost(params, options)(this.fetch, this.basePath);
    }
    /** 
     */
    apiV1LogoutPost(options?: any) {
        return IdentityApiFp.apiV1LogoutPost(options)(this.fetch, this.basePath);
    }
    /** 
     * @param body 
     */
    apiV1ResetPasswordPost(params: {  "body"?: LoginUserRequest; }, options?: any) {
        return IdentityApiFp.apiV1ResetPasswordPost(params, options)(this.fetch, this.basePath);
    }
    /** 
     * @param body 
     */
    apiV1UpdateUserPost(params: {  "body"?: UpdateUserRequest; }, options?: any) {
        return IdentityApiFp.apiV1UpdateUserPost(params, options)(this.fetch, this.basePath);
    }
};

/**
 * IdentityApi - factory interface
 */
export const IdentityApiFactory = function (fetch?: FetchAPI, basePath?: string) {
    return {
        /** 
         * @param body 
         */
        apiV1AddUserPost(params: {  "body"?: CreateUserRequest; }, options?: any) {
            return IdentityApiFp.apiV1AddUserPost(params, options)(fetch, basePath);
        },
        /** 
         */
        apiV1CurrentUserGet(options?: any) {
            return IdentityApiFp.apiV1CurrentUserGet(options)(fetch, basePath);
        },
        /** 
         * @param body 
         */
        apiV1DeleteUserPost(params: {  "body"?: IdentityUser; }, options?: any) {
            return IdentityApiFp.apiV1DeleteUserPost(params, options)(fetch, basePath);
        },
        /** 
         * @param body 
         */
        apiV1ForgetPasswordPost(params: {  "body"?: string; }, options?: any) {
            return IdentityApiFp.apiV1ForgetPasswordPost(params, options)(fetch, basePath);
        },
        /** 
         */
        apiV1GetUsersGet(options?: any) {
            return IdentityApiFp.apiV1GetUsersGet(options)(fetch, basePath);
        },
        /** 
         * @param body 
         */
        apiV1LoginPost(params: {  "body"?: LoginUserRequest; }, options?: any) {
            return IdentityApiFp.apiV1LoginPost(params, options)(fetch, basePath);
        },
        /** 
         */
        apiV1LogoutPost(options?: any) {
            return IdentityApiFp.apiV1LogoutPost(options)(fetch, basePath);
        },
        /** 
         * @param body 
         */
        apiV1ResetPasswordPost(params: {  "body"?: LoginUserRequest; }, options?: any) {
            return IdentityApiFp.apiV1ResetPasswordPost(params, options)(fetch, basePath);
        },
        /** 
         * @param body 
         */
        apiV1UpdateUserPost(params: {  "body"?: UpdateUserRequest; }, options?: any) {
            return IdentityApiFp.apiV1UpdateUserPost(params, options)(fetch, basePath);
        },
    };
};


/**
 * OtaReleaseApi - fetch parameter creator
 */
export const OtaReleaseApiFetchParamCreator = {
    /** 
     * Gets the current release of the specified software type to a specific camera by serial number
     * @param type Example Value 1 &#x3D; JJKApp, 2 &#x3D; MitacBase, 3 &#x3D; MitacRegion
     * @param serialNumber Serial Number for Camera
     */
    apiV1OtareleaseCameraTypeSerialNumberGet(params: {  "type": string; "serialNumber": string; }, options?: any): FetchArgs {
        // verify required parameter "type" is set
        if (params["type"] == null) {
            throw new Error("Missing required parameter type when calling apiV1OtareleaseCameraTypeSerialNumberGet");
        }
        // verify required parameter "serialNumber" is set
        if (params["serialNumber"] == null) {
            throw new Error("Missing required parameter serialNumber when calling apiV1OtareleaseCameraTypeSerialNumberGet");
        }
        const baseUrl = `/api/v1/otarelease/camera/{type}/{serialNumber}`
            .replace(`{${"type"}}`, `${ params["type"] }`)
            .replace(`{${"serialNumber"}}`, `${ params["serialNumber"] }`);
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Releases a version of the specified software type to a specific camera by serial number
     * @param type Example Value 1 &#x3D; JJKApp, 2 &#x3D; MitacBase, 3 &#x3D; MitacRegion
     * @param serialNumber Serial Number for Camera
     * @param body Example value \&quot;0.2.202209158\&quot;
     */
    apiV1OtareleaseCameraTypeSerialNumberPut(params: {  "type": string; "serialNumber": string; "body"?: DefaultOrCameraJobCreationRequestModel; }, options?: any): FetchArgs {
        // verify required parameter "type" is set
        if (params["type"] == null) {
            throw new Error("Missing required parameter type when calling apiV1OtareleaseCameraTypeSerialNumberPut");
        }
        // verify required parameter "serialNumber" is set
        if (params["serialNumber"] == null) {
            throw new Error("Missing required parameter serialNumber when calling apiV1OtareleaseCameraTypeSerialNumberPut");
        }
        const baseUrl = `/api/v1/otarelease/camera/{type}/{serialNumber}`
            .replace(`{${"type"}}`, `${ params["type"] }`)
            .replace(`{${"serialNumber"}}`, `${ params["serialNumber"] }`);
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "PUT" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["body"]) {
            fetchOptions.body = JSON.stringify(params["body"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Gets the current release job for the given release type and fleet
     * @param type Example Value 1 &#x3D; JJKApp, 2 &#x3D; MitacBase, 3 &#x3D; MitacRegion
     * @param fleetId FleetId for the fleet
     */
    apiV1OtareleaseFleetTypeFleetIdGet(params: {  "type": string; "fleetId": number; }, options?: any): FetchArgs {
        // verify required parameter "type" is set
        if (params["type"] == null) {
            throw new Error("Missing required parameter type when calling apiV1OtareleaseFleetTypeFleetIdGet");
        }
        // verify required parameter "fleetId" is set
        if (params["fleetId"] == null) {
            throw new Error("Missing required parameter fleetId when calling apiV1OtareleaseFleetTypeFleetIdGet");
        }
        const baseUrl = `/api/v1/otarelease/fleet/{type}/{fleetId}`
            .replace(`{${"type"}}`, `${ params["type"] }`)
            .replace(`{${"fleetId"}}`, `${ params["fleetId"] }`);
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Releases a version of the specified software type to the specified fleet&#39;s cameras
     * @param type Example Value 1 &#x3D; JJKApp, 2 &#x3D; MitacBase, 3 &#x3D; MitacRegion
     * @param fleetId FleetId for the fleet
     * @param body 
     */
    apiV1OtareleaseFleetTypeFleetIdPut(params: {  "type": string; "fleetId": number; "body"?: FleetJobCreationRequestModel; }, options?: any): FetchArgs {
        // verify required parameter "type" is set
        if (params["type"] == null) {
            throw new Error("Missing required parameter type when calling apiV1OtareleaseFleetTypeFleetIdPut");
        }
        // verify required parameter "fleetId" is set
        if (params["fleetId"] == null) {
            throw new Error("Missing required parameter fleetId when calling apiV1OtareleaseFleetTypeFleetIdPut");
        }
        const baseUrl = `/api/v1/otarelease/fleet/{type}/{fleetId}`
            .replace(`{${"type"}}`, `${ params["type"] }`)
            .replace(`{${"fleetId"}}`, `${ params["fleetId"] }`);
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "PUT" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["body"]) {
            fetchOptions.body = JSON.stringify(params["body"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Gets the current available software versions for the given release type
     * @param type Example Value 1 &#x3D; JJKApp, 2 &#x3D; MitacBase, 3 &#x3D; MitacRegion
     */
    apiV1OtareleaseVersionsTypeGet(params: {  "type": string; }, options?: any): FetchArgs {
        // verify required parameter "type" is set
        if (params["type"] == null) {
            throw new Error("Missing required parameter type when calling apiV1OtareleaseVersionsTypeGet");
        }
        const baseUrl = `/api/v1/otarelease/versions/{type}`
            .replace(`{${"type"}}`, `${ params["type"] }`);
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
};

/**
 * OtaReleaseApi - functional programming interface
 */
export const OtaReleaseApiFp = {
    /** 
     * Gets the current release of the specified software type to a specific camera by serial number
     * @param type Example Value 1 &#x3D; JJKApp, 2 &#x3D; MitacBase, 3 &#x3D; MitacRegion
     * @param serialNumber Serial Number for Camera
     */
    apiV1OtareleaseCameraTypeSerialNumberGet(params: { "type": string; "serialNumber": string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CameraReleaseResponse> {
        const fetchArgs = OtaReleaseApiFetchParamCreator.apiV1OtareleaseCameraTypeSerialNumberGet(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Releases a version of the specified software type to a specific camera by serial number
     * @param type Example Value 1 &#x3D; JJKApp, 2 &#x3D; MitacBase, 3 &#x3D; MitacRegion
     * @param serialNumber Serial Number for Camera
     * @param body Example value \&quot;0.2.202209158\&quot;
     */
    apiV1OtareleaseCameraTypeSerialNumberPut(params: { "type": string; "serialNumber": string; "body"?: DefaultOrCameraJobCreationRequestModel;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = OtaReleaseApiFetchParamCreator.apiV1OtareleaseCameraTypeSerialNumberPut(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Gets the current release job for the given release type and fleet
     * @param type Example Value 1 &#x3D; JJKApp, 2 &#x3D; MitacBase, 3 &#x3D; MitacRegion
     * @param fleetId FleetId for the fleet
     */
    apiV1OtareleaseFleetTypeFleetIdGet(params: { "type": string; "fleetId": number;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<FleetReleaseResponse> {
        const fetchArgs = OtaReleaseApiFetchParamCreator.apiV1OtareleaseFleetTypeFleetIdGet(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Releases a version of the specified software type to the specified fleet&#39;s cameras
     * @param type Example Value 1 &#x3D; JJKApp, 2 &#x3D; MitacBase, 3 &#x3D; MitacRegion
     * @param fleetId FleetId for the fleet
     * @param body 
     */
    apiV1OtareleaseFleetTypeFleetIdPut(params: { "type": string; "fleetId": number; "body"?: FleetJobCreationRequestModel;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = OtaReleaseApiFetchParamCreator.apiV1OtareleaseFleetTypeFleetIdPut(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Gets the current available software versions for the given release type
     * @param type Example Value 1 &#x3D; JJKApp, 2 &#x3D; MitacBase, 3 &#x3D; MitacRegion
     */
    apiV1OtareleaseVersionsTypeGet(params: { "type": string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<string>> {
        const fetchArgs = OtaReleaseApiFetchParamCreator.apiV1OtareleaseVersionsTypeGet(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
};

/**
 * OtaReleaseApi - object-oriented interface
 */
export class OtaReleaseApi extends BaseAPI {
    /** 
     * Gets the current release of the specified software type to a specific camera by serial number
     * @param type Example Value 1 &#x3D; JJKApp, 2 &#x3D; MitacBase, 3 &#x3D; MitacRegion
     * @param serialNumber Serial Number for Camera
     */
    apiV1OtareleaseCameraTypeSerialNumberGet(params: {  "type": string; "serialNumber": string; }, options?: any) {
        return OtaReleaseApiFp.apiV1OtareleaseCameraTypeSerialNumberGet(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Releases a version of the specified software type to a specific camera by serial number
     * @param type Example Value 1 &#x3D; JJKApp, 2 &#x3D; MitacBase, 3 &#x3D; MitacRegion
     * @param serialNumber Serial Number for Camera
     * @param body Example value \&quot;0.2.202209158\&quot;
     */
    apiV1OtareleaseCameraTypeSerialNumberPut(params: {  "type": string; "serialNumber": string; "body"?: DefaultOrCameraJobCreationRequestModel; }, options?: any) {
        return OtaReleaseApiFp.apiV1OtareleaseCameraTypeSerialNumberPut(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Gets the current release job for the given release type and fleet
     * @param type Example Value 1 &#x3D; JJKApp, 2 &#x3D; MitacBase, 3 &#x3D; MitacRegion
     * @param fleetId FleetId for the fleet
     */
    apiV1OtareleaseFleetTypeFleetIdGet(params: {  "type": string; "fleetId": number; }, options?: any) {
        return OtaReleaseApiFp.apiV1OtareleaseFleetTypeFleetIdGet(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Releases a version of the specified software type to the specified fleet&#39;s cameras
     * @param type Example Value 1 &#x3D; JJKApp, 2 &#x3D; MitacBase, 3 &#x3D; MitacRegion
     * @param fleetId FleetId for the fleet
     * @param body 
     */
    apiV1OtareleaseFleetTypeFleetIdPut(params: {  "type": string; "fleetId": number; "body"?: FleetJobCreationRequestModel; }, options?: any) {
        return OtaReleaseApiFp.apiV1OtareleaseFleetTypeFleetIdPut(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Gets the current available software versions for the given release type
     * @param type Example Value 1 &#x3D; JJKApp, 2 &#x3D; MitacBase, 3 &#x3D; MitacRegion
     */
    apiV1OtareleaseVersionsTypeGet(params: {  "type": string; }, options?: any) {
        return OtaReleaseApiFp.apiV1OtareleaseVersionsTypeGet(params, options)(this.fetch, this.basePath);
    }
};

/**
 * OtaReleaseApi - factory interface
 */
export const OtaReleaseApiFactory = function (fetch?: FetchAPI, basePath?: string) {
    return {
        /** 
         * Gets the current release of the specified software type to a specific camera by serial number
         * @param type Example Value 1 &#x3D; JJKApp, 2 &#x3D; MitacBase, 3 &#x3D; MitacRegion
         * @param serialNumber Serial Number for Camera
         */
        apiV1OtareleaseCameraTypeSerialNumberGet(params: {  "type": string; "serialNumber": string; }, options?: any) {
            return OtaReleaseApiFp.apiV1OtareleaseCameraTypeSerialNumberGet(params, options)(fetch, basePath);
        },
        /** 
         * Releases a version of the specified software type to a specific camera by serial number
         * @param type Example Value 1 &#x3D; JJKApp, 2 &#x3D; MitacBase, 3 &#x3D; MitacRegion
         * @param serialNumber Serial Number for Camera
         * @param body Example value \&quot;0.2.202209158\&quot;
         */
        apiV1OtareleaseCameraTypeSerialNumberPut(params: {  "type": string; "serialNumber": string; "body"?: DefaultOrCameraJobCreationRequestModel; }, options?: any) {
            return OtaReleaseApiFp.apiV1OtareleaseCameraTypeSerialNumberPut(params, options)(fetch, basePath);
        },
        /** 
         * Gets the current release job for the given release type and fleet
         * @param type Example Value 1 &#x3D; JJKApp, 2 &#x3D; MitacBase, 3 &#x3D; MitacRegion
         * @param fleetId FleetId for the fleet
         */
        apiV1OtareleaseFleetTypeFleetIdGet(params: {  "type": string; "fleetId": number; }, options?: any) {
            return OtaReleaseApiFp.apiV1OtareleaseFleetTypeFleetIdGet(params, options)(fetch, basePath);
        },
        /** 
         * Releases a version of the specified software type to the specified fleet&#39;s cameras
         * @param type Example Value 1 &#x3D; JJKApp, 2 &#x3D; MitacBase, 3 &#x3D; MitacRegion
         * @param fleetId FleetId for the fleet
         * @param body 
         */
        apiV1OtareleaseFleetTypeFleetIdPut(params: {  "type": string; "fleetId": number; "body"?: FleetJobCreationRequestModel; }, options?: any) {
            return OtaReleaseApiFp.apiV1OtareleaseFleetTypeFleetIdPut(params, options)(fetch, basePath);
        },
        /** 
         * Gets the current available software versions for the given release type
         * @param type Example Value 1 &#x3D; JJKApp, 2 &#x3D; MitacBase, 3 &#x3D; MitacRegion
         */
        apiV1OtareleaseVersionsTypeGet(params: {  "type": string; }, options?: any) {
            return OtaReleaseApiFp.apiV1OtareleaseVersionsTypeGet(params, options)(fetch, basePath);
        },
    };
};


/**
 * SplitIoApi - fetch parameter creator
 */
export const SplitIoApiFetchParamCreator = {
    /** 
     * @param body 
     */
    apiV1SplitioFeaturetogglePost(params: {  "body"?: SplitTreatmentOptions; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/splitio/featuretoggle`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["body"]) {
            fetchOptions.body = JSON.stringify(params["body"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
};

/**
 * SplitIoApi - functional programming interface
 */
export const SplitIoApiFp = {
    /** 
     * @param body 
     */
    apiV1SplitioFeaturetogglePost(params: { "body"?: SplitTreatmentOptions;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<SplitFeatureToggle> {
        const fetchArgs = SplitIoApiFetchParamCreator.apiV1SplitioFeaturetogglePost(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
};

/**
 * SplitIoApi - object-oriented interface
 */
export class SplitIoApi extends BaseAPI {
    /** 
     * @param body 
     */
    apiV1SplitioFeaturetogglePost(params: {  "body"?: SplitTreatmentOptions; }, options?: any) {
        return SplitIoApiFp.apiV1SplitioFeaturetogglePost(params, options)(this.fetch, this.basePath);
    }
};

/**
 * SplitIoApi - factory interface
 */
export const SplitIoApiFactory = function (fetch?: FetchAPI, basePath?: string) {
    return {
        /** 
         * @param body 
         */
        apiV1SplitioFeaturetogglePost(params: {  "body"?: SplitTreatmentOptions; }, options?: any) {
            return SplitIoApiFp.apiV1SplitioFeaturetogglePost(params, options)(fetch, basePath);
        },
    };
};


/**
 * SupportPortalApi - fetch parameter creator
 */
export const SupportPortalApiFetchParamCreator = {
    /** 
     */
    apiV1SupportPortalTestGet(options?: any): FetchArgs {
        const baseUrl = `/api/v1/SupportPortal/test`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
};

/**
 * SupportPortalApi - functional programming interface
 */
export const SupportPortalApiFp = {
    /** 
     */
    apiV1SupportPortalTestGet(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = SupportPortalApiFetchParamCreator.apiV1SupportPortalTestGet(options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
};

/**
 * SupportPortalApi - object-oriented interface
 */
export class SupportPortalApi extends BaseAPI {
    /** 
     */
    apiV1SupportPortalTestGet(options?: any) {
        return SupportPortalApiFp.apiV1SupportPortalTestGet(options)(this.fetch, this.basePath);
    }
};

/**
 * SupportPortalApi - factory interface
 */
export const SupportPortalApiFactory = function (fetch?: FetchAPI, basePath?: string) {
    return {
        /** 
         */
        apiV1SupportPortalTestGet(options?: any) {
            return SupportPortalApiFp.apiV1SupportPortalTestGet(options)(fetch, basePath);
        },
    };
};


/**
 * TelematicDeviceApi - fetch parameter creator
 */
export const TelematicDeviceApiFetchParamCreator = {
    /** 
     * Get telematic device by id
     * @param id 
     */
    apiV1TelematicDeviceGet(params: {  "id"?: string; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/telematicDevice`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "id": params["id"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Get all telematic devices
     * @param fleetId 
     * @param telematicProviderId 
     */
    apiV1TelematicdevicesGet(params: {  "fleetId"?: number; "telematicProviderId"?: string; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/telematicdevices`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "fleetId": params["fleetId"],
            "telematicProviderId": params["telematicProviderId"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
};

/**
 * TelematicDeviceApi - functional programming interface
 */
export const TelematicDeviceApiFp = {
    /** 
     * Get telematic device by id
     * @param id 
     */
    apiV1TelematicDeviceGet(params: { "id"?: string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<TelematicsDeviceResponse> {
        const fetchArgs = TelematicDeviceApiFetchParamCreator.apiV1TelematicDeviceGet(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Get all telematic devices
     * @param fleetId 
     * @param telematicProviderId 
     */
    apiV1TelematicdevicesGet(params: { "fleetId"?: number; "telematicProviderId"?: string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<TelematicDeviceAdminResponse>> {
        const fetchArgs = TelematicDeviceApiFetchParamCreator.apiV1TelematicdevicesGet(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
};

/**
 * TelematicDeviceApi - object-oriented interface
 */
export class TelematicDeviceApi extends BaseAPI {
    /** 
     * Get telematic device by id
     * @param id 
     */
    apiV1TelematicDeviceGet(params: {  "id"?: string; }, options?: any) {
        return TelematicDeviceApiFp.apiV1TelematicDeviceGet(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Get all telematic devices
     * @param fleetId 
     * @param telematicProviderId 
     */
    apiV1TelematicdevicesGet(params: {  "fleetId"?: number; "telematicProviderId"?: string; }, options?: any) {
        return TelematicDeviceApiFp.apiV1TelematicdevicesGet(params, options)(this.fetch, this.basePath);
    }
};

/**
 * TelematicDeviceApi - factory interface
 */
export const TelematicDeviceApiFactory = function (fetch?: FetchAPI, basePath?: string) {
    return {
        /** 
         * Get telematic device by id
         * @param id 
         */
        apiV1TelematicDeviceGet(params: {  "id"?: string; }, options?: any) {
            return TelematicDeviceApiFp.apiV1TelematicDeviceGet(params, options)(fetch, basePath);
        },
        /** 
         * Get all telematic devices
         * @param fleetId 
         * @param telematicProviderId 
         */
        apiV1TelematicdevicesGet(params: {  "fleetId"?: number; "telematicProviderId"?: string; }, options?: any) {
            return TelematicDeviceApiFp.apiV1TelematicdevicesGet(params, options)(fetch, basePath);
        },
    };
};

