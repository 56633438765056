import { FreezerService, managedAjaxUtil, IAjaxState, _ } from '../Imports';
import {
    DeviceManagementApiFactory,
    TelematicsDeviceResponse,
    VehicleCameraPairingResponse,
    DeviceHealthResponseIEnumerablePagedResponseBase,
    CameraDeviceResponse,
    DeviceHealthResponse,
    DataUsageResponse,
    DeviceBatchResponse,
    FleetResponse,
    BulkUpdateFleetRequest,
} from '$Generated/api';
import { IFilterState } from './DeviceManagementFilterFreezerService';
import { IBatchDeviceBaseResponse } from '$Pages/DeviceBulk/DeviceBulk';

const InjectedPropName = 'deviceManagement';

export interface IDeviceManagementState {
    DeviceManagementResults: IAjaxState<DeviceHealthResponseIEnumerablePagedResponseBase>;
    DeviceManagementResultsFinal: DeviceHealthResponse[];
    DeviceDetailsResult: IAjaxState<CameraDeviceResponse>;
    DataUsageResult: IAjaxState<DataUsageResponse[]>;
    hasError: boolean;
    hasInfiniteScrollError: boolean;
    BatchDevicesResult: IAjaxState<IBatchDeviceBaseResponse>;
    deviceFleetResult: IAjaxState<FleetResponse>;
    companyFleetResult: IAjaxState<FleetResponse>;
    bulkUpdateFleetResult: IAjaxState<any>;
}

class DeviceManagementFreezerService extends FreezerService<IDeviceManagementState, typeof InjectedPropName> {
    constructor() {
        super(
            {
                DeviceManagementResults: managedAjaxUtil.createInitialState(),
                DeviceManagementResultsFinal: [] as TelematicsDeviceResponse[],
                DeviceDetailsResult: managedAjaxUtil.createInitialState(),
                DataUsageResult: managedAjaxUtil.createInitialState(),
                hasError: false,
                hasInfiniteScrollError: false,
                BatchDevicesResult: managedAjaxUtil.createInitialState(),
                deviceFleetResult: managedAjaxUtil.createInitialState(),
                companyFleetResult: managedAjaxUtil.createInitialState(),
                bulkUpdateFleetResult: managedAjaxUtil.createInitialState(),
            },
            InjectedPropName,
        );
    }

    public async getFilteredDevices(filters: IFilterState): Promise<void | DeviceHealthResponseIEnumerablePagedResponseBase> {
        return await this.getDevices(
            filters.CurrentPage,
            filters.ItemsPerPage,
            filters.SortBy,
            filters.SortAsc,
            filters.Filters.searchSelectedValue,
            filters.Filters.selectedClients,
            filters.Filters.selectedCameraDevices,
            filters.Filters.selectedStatuses,
        );
    }

    public async postBatchDevices(devices: string[]): Promise<DeviceBatchResponse> {
        try {
            await this._batchDevices(devices);

            const result = this.freezer.get().BatchDevicesResult.data;

            return Promise.resolve(result as DeviceBatchResponse);
        } catch (error) {
            console.debug('Error on Device Bulk: ', error);

            return Promise.reject(new Error('There was an error processing the request, please try again later'));
        }
    }

    async _batchDevices(devices: string[]): Promise<void | DeviceBatchResponse> {
        return managedAjaxUtil.fetchResults({
            ajaxStateProperty: 'BatchDevicesResult',
            freezer: this.freezer,
            params: {
                body: devices,
            },
            onExecute: (apiOptions, params) => {
                if (params === undefined) {
                    throw new Error('Parameters is undefined');
                }

                const AdministrativeApi = DeviceManagementApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return AdministrativeApi.apiV1DeviceassociationBatchDevicesPost(params);
            },
            onOk: (data: DeviceBatchResponse) => {
                if (data !== undefined) {
                    this.freezer.get().set({ hasError: false });
                }

                return data;
            },
            onError: () => {
                this.freezer.get().set({ hasError: true });
            },
        });
    }

    public async getDevices(
        currentPage: number,
        itemsPerPage?: number,
        sortBy?: string,
        sortAsc?: boolean,
        search?: string,
        fleetIds?: Array<number>,
        deviceTypes?: Array<number>,
        deviceStatuses?: Array<string>,
    ): Promise<void | DeviceHealthResponseIEnumerablePagedResponseBase> {
        return managedAjaxUtil.fetchResults({
            ajaxStateProperty: 'DeviceManagementResults',
            freezer: this.freezer,
            params: {
                currentPage,
                itemsPerPage,
                sortBy,
                sortAsc,
                search,
                fleetIds,
                deviceTypes,
                deviceStatuses,
            },
            onExecute: (apiOptions, params) => {
                if (params === undefined) {
                    throw new Error('Parameters is undefined');
                }

                const AdministrativeApi = DeviceManagementApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return AdministrativeApi.apiV1DevicesGet(params);
            },
            onOk: (data: DeviceHealthResponseIEnumerablePagedResponseBase) => {
                if (data.data !== undefined && data.success) {
                    this.freezer.get().set({ hasError: false, hasInfiniteScrollError: false });
                } else {
                    this.freezer.get().set({ hasError: true, hasInfiniteScrollError: true });
                }

                const freezer1 = this.freezer.get().DeviceManagementResultsFinal;

                let array: any = [];
                if (freezer1.length > 0) {
                    array = _.clone(freezer1);
                }
                if (data.data) {
                    if (currentPage > 1) {
                        for (const element of data.data) {
                            array.push(element);
                        }
                        this.freezer.get().set({ DeviceManagementResultsFinal: array });
                    } else {
                        this.freezer.get().set({ DeviceManagementResultsFinal: data.data });
                    }
                }
                return data;
            },
            onError: () => {
                this.freezer.get().set({ hasError: true, hasInfiniteScrollError: true });
            },
        });
    }

    public clearDevices(): void {
        this.freezer.get().DeviceManagementResults.set({ data: null });
        this.freezer.get().set({ DeviceManagementResultsFinal: [] });
    }

    public async getDevice(id: number): Promise<void | VehicleCameraPairingResponse> {
        return managedAjaxUtil.fetchResults({
            ajaxStateProperty: 'DeviceDetailsResult',
            freezer: this.freezer,
            onExecute: (apiOptions, params) => {
                if (params === undefined) {
                    throw new Error('Parameters is undefined');
                }

                const AdministrativeApi = DeviceManagementApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return AdministrativeApi.apiV1DevicedetailsGet(params);
            },
            params: {
                id: id,
            },
            onOk: (data: VehicleCameraPairingResponse) => {
                if (data !== undefined) {
                    this.freezer.get().set({ hasError: false });
                }

                return data;
            },
            onError: () => {
                this.freezer.get().set({ hasError: true });
            },
        });
    }

    public async suspendCamera(id?: number): Promise<any> {
        return managedAjaxUtil.fetchResults({
            ajaxStateProperty: 'suspendCameraResult',
            freezer: this.freezer,
            onExecute: (apiOptions, params) => {
                if (params === undefined) {
                    throw new Error('Parameters is undefined');
                }

                const AdministrativeApi = DeviceManagementApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return AdministrativeApi.apiV1SuspendcameraPost(params);
            },
            params: {
                id: id,
            },
            onOk: (data: VehicleCameraPairingResponse) => {
                if (data !== undefined) {
                    this.freezer.get().set({ hasError: false });
                }

                return data;
            },
            onError: () => {
                this.freezer.get().set({ hasError: true });
            },
        });
    }

    public async activateCamera(id?: number): Promise<any> {
        return managedAjaxUtil.fetchResults({
            ajaxStateProperty: 'activateCameraResult',
            freezer: this.freezer,
            onExecute: (apiOptions, params) => {
                if (params === undefined) {
                    throw new Error('Parameters is undefined');
                }

                const AdministrativeApi = DeviceManagementApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return AdministrativeApi.apiV1ActivatecameraPost(params);
            },
            params: {
                id: id,
            },
            onOk: (data: VehicleCameraPairingResponse) => {
                if (data !== undefined) {
                    this.freezer.get().set({ hasError: false });
                }

                return data;
            },
            onError: () => {
                this.freezer.get().set({ hasError: true });
            },
        });
    }

    public async getIndividualDataUsage(iccid: string): Promise<void | DataUsageResponse[]> {
        return managedAjaxUtil.fetchResults({
            ajaxStateProperty: 'DataUsageResult',
            freezer: this.freezer,
            onExecute: (apiOptions, params) => {
                if (params === undefined) {
                    throw new Error('Parameters is undefined');
                }

                const AdministrativeApi = DeviceManagementApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return AdministrativeApi.apiV1IndividualdatausageGet(params);
            },
            params: {
                iccid: iccid,
            },
            onOk: (data: DataUsageResponse[]) => {
                if (data !== undefined) {
                    this.freezer.get().set({ hasError: false });
                }

                return data;
            },
            onError: () => {
                this.freezer.get().set({ hasError: true });
            },
        });
    }

    public async getDeviceFleet(serialNumber: string): Promise<void | FleetResponse> {
        return managedAjaxUtil.fetchResults({
            ajaxStateProperty: 'deviceFleetResult',
            freezer: this.freezer,
            onExecute: (apiOptions, params) => {
                if (params === undefined) {
                    throw new Error('Parameters is undefined');
                }

                const AdministrativeApi = DeviceManagementApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return AdministrativeApi.apiV1DeviceassociationCameraFleetGet(params);
            },
            params: {
                cameraSerialNumber: serialNumber,
            },
            onOk: (data: FleetResponse) => {
                if (data !== undefined) {
                    this.freezer.get().set({ hasError: false });
                }

                return data;
            },
            onError: () => {
                this.freezer.get().set({ hasError: true });
            },
        });
    }

    public async getCompanyFleet(companyId: string): Promise<void | FleetResponse> {
        return managedAjaxUtil.fetchResults({
            ajaxStateProperty: 'companyFleetResult',
            freezer: this.freezer,
            onExecute: (apiOptions, params) => {
                if (params === undefined) {
                    throw new Error('Parameters is undefined');
                }

                const AdministrativeApi = DeviceManagementApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return AdministrativeApi.apiV1DeviceassociationCompanyFleetGet(params);
            },
            params: {
                companyId: companyId,
            },
            onOk: (data: FleetResponse) => {
                if (data !== undefined) {
                    this.freezer.get().set({ hasError: false });
                }

                return data;
            },
            onError: () => {
                this.freezer.get().set({ hasError: true });
            },
        });
    }

    public async bulkUpdateCameraFleet(bulkUpdateFleetRequest: BulkUpdateFleetRequest): Promise<any> {
        return managedAjaxUtil.fetchResults({
            ajaxStateProperty: 'bulkUpdateFleetResult',
            freezer: this.freezer,
            onExecute: (apiOptions, params) => {
                if (params === undefined) {
                    throw new Error('Parameters is undefined');
                }
                const AdministrativeApi = DeviceManagementApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return AdministrativeApi.apiV1DeviceassociationUpdateCameraFleetPost(params);
            },
            params: {
                body: bulkUpdateFleetRequest,
            },
            onOk: (data: FleetResponse) => {
                if (data !== undefined) {
                    this.freezer.get().set({ hasError: false });
                }

                return data;
            },
            onError: () => {
                this.freezer.get().set({ hasError: true });
            },
        });
    }
}

export const DeviceManagementService = new DeviceManagementFreezerService();
export type IDeviceManagementServiceInjectedProps = ReturnType<DeviceManagementFreezerService['getPropsForInjection']>;
