/* eslint-disable prefer-const */
import { React, bind } from '../../Imports';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions } from '../../MaterialUIComponents';
import { AdminUserResponse } from '$Generated/api';

const styles = require('./EditUserDialog.scss') as {
    main: string;
    actionButton: string;
    dialogContent: string;
};

interface IConfirmDeleteDialogProps {
    onDeleteUser: (user: AdminUserResponse) => void;
    visible: boolean;
    toggleDialog: (user: AdminUserResponse) => void;
    userToDelete: AdminUserResponse;
}

class _ConfirmDeleteDialog extends React.Component<IConfirmDeleteDialogProps, {}> {
    @bind
    closeDialog(): void {
        this.props.toggleDialog({
            id: '',
            userName: '',
            normalizedUserName: '',
            email: '',
            normalizedEmail: '',
            emailConfirmed: false,
            passwordHash: '',
            securityStamp: '',
            concurrencyStamp: '',
            phoneNumber: '',
            phoneNumberConfirmed: false,
            twoFactorEnabled: false,
            lockoutEnd: undefined,
            lockoutEnabled: false,
            accessFailedCount: 0,
            firstName: '',
            lastName: '',
            lastLogin: undefined,
            isActive: false,
        } as AdminUserResponse);
    }

    @bind
    cancelDeleteUser(): void {
        this.closeDialog();
    }

    @bind
    deleteUser(): void {
        this.props.onDeleteUser(this.props.userToDelete);
        this.closeDialog();
    }

    render(): JSX.Element {
        return (
            <div className={styles.main}>
                <Dialog open={this.props.visible}>
                    <DialogTitle>Add User</DialogTitle>
                    <DialogContent className={styles.dialogContent}>
                        Are you sure you want to delete {this.props.userToDelete.firstName} {this.props.userToDelete.lastName}?
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.cancelDeleteUser} className={styles.actionButton}>
                            Cancel
                        </Button>
                        <Button onClick={this.deleteUser} className={styles.actionButton}>
                            Delete
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

export const ConfirmDeleteDialog = _ConfirmDeleteDialog;
