import { FreezerService, managedAjaxUtil, IAjaxState, _ } from '../Imports';
import {
    LookupResponse, FleetApiFactory
} from '$Generated/api';

const InjectedPropName = 'fleetService';

interface IFleetState {
    FleetResults: IAjaxState<LookupResponse[]>;
}

class FleetFreezerService extends FreezerService<IFleetState, typeof InjectedPropName> {
    
    constructor() {
        super(
            {
                FleetResults: managedAjaxUtil.createInitialState(),
            },
            InjectedPropName,
        );
    }

    public async getAllFleets(): Promise<void | LookupResponse[]> {
        return managedAjaxUtil.fetchResults({
            ajaxStateProperty: 'FleetResults',
            freezer: this.freezer,
            params: {},
            onExecute: (apiOptions, params) => {
                if (params === undefined) {
                    throw new Error('Parameters is undefined');
                }

                const fleetApi = FleetApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return fleetApi.apiV1FleetsGet();
            },
            onOk: (data: LookupResponse[]) => {
                return data;
            },
            onError: () => {
                //NOOP
            },
        });
    }
}

export const FleetService = new FleetFreezerService();
export type IFleetServiceInjectedProps = ReturnType<FleetFreezerService['getPropsForInjection']>;