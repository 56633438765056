import { FreezerService as BaseFreezerService } from '@videoplatform/ts-freezer';

class ViperFreezerService<DataType, InjectedPropName extends string = 'service', EventTypes = {}> extends BaseFreezerService<
    DataType,
    InjectedPropName,
    EventTypes
> {
    getState(): DataType {
        return this.freezer.get() as DataType;
    }
}

export const FreezerService = ViperFreezerService;
