/* eslint-disable prefer-const */
import { React, _, bind } from '../../Imports';
import { VPTextbox } from '$Components/Shared/VPTextbox';
import { VPDropdown } from '$Components/Shared/VPDropdown';
import { Button } from '../../MaterialUIComponents';
import {
    DeviceManagementFilterService,
    IDeviceManagementFilterServiceInjectedProps,
    IFilterBarValues,
} from '$State/DeviceManagementFilterFreezerService';
import { DeviceManagementFilterDataResponse } from '$Generated/api';
import { convertDropdownType } from '$Utilities/dataModelUtilities';

const styles = require('./FilterBar.scss') as {
    main: string;
    button: string;
};

interface IFilterBarProps {
    onSearch: () => void;
    onDisplayAddForm: () => void;
    onMoveDevice: () => void;
    isEncompass: boolean;
}

interface IFilterBarState {
    selectedValues: IFilterBarValues;
}

class _FilterBar extends React.Component<IFilterBarProps & IDeviceManagementFilterServiceInjectedProps, IFilterBarState> {
    state = {
        selectedValues: {} as IFilterBarValues,
    };

    @bind
    handleOnClear(): void {
        let newState: IFilterBarState = _.clone(this.state);
        newState.selectedValues = { selectedStatuses: [], selectedCameraDevices: [], selectedClients: [] } as IFilterBarValues;
        this.setState(newState);
        // Save filters to freezer
        this.props.deviceManagementFilter.updateFilterOptions(newState.selectedValues);
        // Search
        this.props.onSearch();
    }

    @bind
    handleValueChange(value: IFilterBarValues): void {
        let newState: IFilterBarState = _.clone(this.state);
        newState.selectedValues = value;
        this.setState(newState);
    }

    @bind
    handleSearchBoxChange(value: string | undefined): void {
        let newState: IFilterBarState = _.clone(this.state);
        let searchValue = _.clone(this.state.selectedValues);
        searchValue.searchSelectedValue = value;
        newState.selectedValues = searchValue;
        this.setState(newState);
    }

    @bind
    handleSearch(): void {
        // Save filters to freezer
        const values: IFilterBarValues = _.clone(this.state.selectedValues);
        this.props.deviceManagementFilter.updateFilterOptions(values);
        // Search
        this.props.onSearch();
    }

    async componentDidMount(): Promise<void> {
        const freezer = this.props.deviceManagementFilter.getState();
        const { Filters } = freezer;
        this.setState({ selectedValues: Filters });
        await this.props.deviceManagementFilter.getFilterData();
    }

    render(): JSX.Element {
        const freezer = this.props.deviceManagementFilter.getState();
        const { DeviceManagementFilters } = freezer;
        const filterData: DeviceManagementFilterDataResponse = DeviceManagementFilters.data ? DeviceManagementFilters.data : {};

        const selectedClients = this.state.selectedValues.selectedClients ?? [];
        const selectedDevices = this.state.selectedValues.selectedCameraDevices ?? [];
        const selectedStatuses = this.state.selectedValues.selectedStatuses ?? [];

        return (
            <div className={styles.main}>
                {/* Free Search */}
                <VPTextbox
                    name={'Search'}
                    placeholder={'Serial #, ICCID'}
                    selectedValue={this.state.selectedValues.searchSelectedValue ?? ''}
                    onSelectedItemChange={this.handleSearchBoxChange}
                    onEnterKey={this.handleSearch}
                />

                {/* Camera Device, Client, Status */}
                <VPDropdown
                    name={'Client'}
                    items={filterData ? convertDropdownType(filterData.clients) : []}
                    selectedValues={selectedClients}
                    onSelectedItemsChange={(values: any[]): void => {
                        let cloneSelectedValues: IFilterBarValues = _.clone(this.state.selectedValues);
                        cloneSelectedValues.selectedClients = values;
                        this.handleValueChange(cloneSelectedValues);
                    }}
                    isCombined={true}
                />

                <VPDropdown
                    name={'Camera Device'}
                    items={filterData ? convertDropdownType(filterData.cameras) : []}
                    selectedValues={selectedDevices}
                    onSelectedItemsChange={(values: any[]): void => {
                        let cloneSelectedValues: IFilterBarValues = _.clone(this.state.selectedValues);
                        cloneSelectedValues.selectedCameraDevices = values;
                        this.handleValueChange(cloneSelectedValues);
                    }}
                    isCombined={true}
                />

                <VPDropdown
                    name={'Status'}
                    items={filterData ? convertDropdownType(filterData.status) : []}
                    selectedValues={selectedStatuses}
                    onSelectedItemsChange={(values: any[]): void => {
                        let cloneSelectedValues: IFilterBarValues = _.clone(this.state.selectedValues);
                        cloneSelectedValues.selectedStatuses = values;
                        this.handleValueChange(cloneSelectedValues);
                    }}
                    isCombined={true}
                />

                {/*Search Button */}
                <Button className={styles.button} onClick={(): void => this.handleSearch()}>
                    Search
                </Button>

                {/*Clear Button */}
                <Button className={styles.button} onClick={(): void => this.handleOnClear()}>
                    Clear
                </Button>

                {this.props.isEncompass && (
                    <Button className={styles.button} onClick={(): void => this.props.onMoveDevice()}>
                        Move Device
                    </Button>
                )}
            </div>
        );
    }
}

export const FilterBar = DeviceManagementFilterService.inject(_FilterBar);
