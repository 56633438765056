import { React, moment } from '../../Imports';
import * as DateFormatter from '../Shared/DateFormatter';
import { CSVLink } from 'react-csv';
import { GetAppIcon } from '../../MaterialUIIcons';
import { DeviceHistoryResponse } from '$Generated/api';

interface IHistoryDownloadProps {
    data: DeviceHistoryResponse[];
    csvTitle: string;
}

const styles = require('./HistoryDownload.scss') as {
    fileDownload: string;
    headerIcon: string;
};

const csvHeaders = [
    { label: 'Date', key: 'CreatedOn' },
    { label: 'By', key: 'CreatedBy' },
    { label: 'Action', key: 'Action' },
    { label: 'Status of Action', key: 'StatusOfAction' },
];

export class HistoryDownload extends React.PureComponent<IHistoryDownloadProps> {
    render(): JSX.Element {
        const csvData: any[] = this.props.data.map((val) => {
            const date = moment(val.createdOn) || moment();
            return {
                CreatedOn: DateFormatter.formatCsvTime(date, false),
                CreatedBy: val.createdBy,
                Action: val.action,
                StatusOfAction: val.statusOfAction,
            };
        });
        return (
            <CSVLink
                data={csvData}
                headers={csvHeaders}
                className={styles.fileDownload}
                filename={this.props.csvTitle + '.csv'}
            >
                <GetAppIcon className={styles.headerIcon} />
            </CSVLink>
        );
    }
}
