import { Dialog, DialogTitle, DialogContent, Button, Autocomplete, MenuItem, InputLabel, CircularProgress } from 'MaterialUIComponents';
import { CompareArrows } from '../../MaterialUIIcons';
import { React, bind, IAjaxState, moment } from '../../Imports';
import { IFleetServiceInjectedProps, FleetService } from '$State/FleetFreezerService';
import { ITelematicProviderServiceInjectedProps, TelematicProviderService } from '$State/TelematicProviderFreezerService';
import { ITelematicDeviceServiceInjectedProps, TelematicDeviceService } from '$State/TelematicDeviceFreezerService';
import { ICameraServiceInjectedProps, CameraService } from '$State/CameraFreezerService';
import { ICameraTypeServiceInjectedProps, CameraTypeService } from '$State/CameraTypeFreezerService';
import { IDeviceAssociationServiceInjectedProps, DeviceAssociationService } from '$State/DeviceAssociationFreezerService';
import { ValidatorForm, TextValidator, SelectValidator } from 'react-material-ui-form-validator';
import {
    LookupResponse,
    CameraResponse,
    TelematicDeviceAdminResponse,
    DeviceAssociationValidationResponse,
    TelematicProviderIdEnum,
} from '$Generated/api';
import * as DateFormatter from '../Shared/DateFormatter';

interface IDeviceAssociationBaseProps {
    showDialog: boolean;
    onDialogCancel: () => void;
    onAssociationCreated: () => void;
}

type IDeviceAssociationProps = IDeviceAssociationBaseProps &
    IFleetServiceInjectedProps &
    ITelematicProviderServiceInjectedProps &
    ICameraServiceInjectedProps &
    ITelematicDeviceServiceInjectedProps &
    ICameraTypeServiceInjectedProps &
    IDeviceAssociationServiceInjectedProps;

interface IDeviceAssociationState {
    fleetId?: number;
    telematicProviderId?: TelematicProviderIdEnum;
    cameraId?: number;
    telematicDeviceId?: string;
    cameraTypeId?: number;
    isSaving: boolean;
    errorMessage: string;
}

const styles = require('./DeviceAssociationDialog.scss') as {
    associationForm: string;
    fleetRow: string;
    fleetSection: string;
    deviceRow: string;
    telematicDeviceSection: string;
    associateIconSection: string;
    cameraDeviceSection: string;
    remoteValidationMessage: string;
    cancelButton: string;
    associateButton: string;
    associationDisclaimer: string;
    loadingMask: string;
    buttonProgress: string;
    errorMessages: string;
};

class _DeviceAssociationDialog extends React.Component<IDeviceAssociationProps, IDeviceAssociationState> {
    state: IDeviceAssociationState = {
        fleetId: undefined,
        telematicProviderId: undefined,
        cameraId: 0,
        telematicDeviceId: '',
        cameraTypeId: undefined,
        isSaving: false,
        errorMessage: '',
    };

    async componentDidMount(): Promise<void> {
        //Load lookup lists if they have not already been pulled
        if (!this.props.fleetService.getState().FleetResults.isFetching && !this.props.fleetService.getState().FleetResults.hasFetched) {
            await this.props.fleetService.getAllFleets();
        }

        if (
            !this.props.telematicProviderService.getState().TelematicProviderResults.isFetching &&
            !this.props.telematicProviderService.getState().TelematicProviderResults.hasFetched
        ) {
            await this.props.telematicProviderService.getAllTelematicProviders();
        }

        if (
            !this.props.cameraTypeService.getState().CameraTypeResults.isFetching &&
            !this.props.cameraTypeService.getState().CameraTypeResults.hasFetched
        ) {
            await this.props.cameraTypeService.getCameraTypes();
        }
    }

    clearState() {
        this.setState({
            fleetId: undefined,
            telematicProviderId: undefined,
            cameraId: 0,
            telematicDeviceId: '',
            cameraTypeId: undefined,
            isSaving: false,
        });
        this.props.deviceAssociationService.clearTelematicAssociationValidation();
        this.props.deviceAssociationService.clearCameraAssociationValidation();
    }

    @bind
    getSafeLookupList(items: LookupResponse[] | null | undefined) {
        const itemList = items ? (items as LookupResponse[]) : [];
        return itemList;
    }

    @bind
    handleSubmitAssociation() {
        //Ensure remote validation has returned for both devices before proceeding
        if (
            this.props.deviceAssociationService.getState().CameraValidationResult.hasFetched &&
            this.props.deviceAssociationService.getState().CameraValidationResult.hasFetched
        ) {
            const list = this.props.telematicsDeviceManagement.getState().TelematicDeviceResults.data as TelematicDeviceAdminResponse[];
            const telematicDevice = list.find((x) => x.sourceId == this.state.telematicDeviceId);

            this.setState({ isSaving: true });
            this.props.deviceAssociationService
                .saveDeviceAssociation(
                    this.state.fleetId as number,
                    this.state.telematicProviderId!,
                    telematicDevice as TelematicDeviceAdminResponse,
                    this.state.cameraId as number,
                )
                .then(
                    (data) => {
                        //Saving succeeded, clear the form and let the parent page know it finished
                        this.clearState();
                        this.props.onAssociationCreated();
                    },
                    (reason) => {
                        this.setState({ isSaving: false });
                        alert('Save failed, please contact tech support if the problem persists.');
                    },
                );
        }
    }

    @bind
    handleFleetChange(e: any) {
        this.setState({ fleetId: e.target.value });
    }

    @bind
    handleProviderChange(e: any) {
        this.setState({ telematicProviderId: e.target.value });
        this.props.telematicsDeviceManagement.getAllTelematicDevices(this.state.fleetId as number, e.target.value as string).catch((ex) => {
            ex.text().then((error: string) => {
                this.setState({ errorMessage: error });
            });
        });
        this.setState({ telematicDeviceId: undefined });
    }

    @bind
    handleCameraTypeChange(e: any) {
        this.setState({ cameraTypeId: e.target.value });
        this.props.cameraService.getCameras(this.state.fleetId as number, e.target.value, '');
        this.setState({ cameraId: undefined });
    }

    @bind
    handleCameraChange(e: any, value: CameraResponse | null, reason: any, details: any) {
        const cId = value ? value.id : undefined;
        this.setState({ cameraId: cId });
        if (value !== null) {
            this.props.deviceAssociationService.checkCurrentCameraAssociation(
                this.state.fleetId as number,
                (value as CameraResponse).id as number,
            );
        } else {
            this.props.deviceAssociationService.clearCameraAssociationValidation();
        }
    }

    @bind
    handleTelematicDeviceChange(e: any, value: TelematicDeviceAdminResponse | null, reason: any, details: any) {
        const tId = value ? value.sourceId : undefined;
        this.setState({ telematicDeviceId: tId });

        if (value !== null) {
            this.props.deviceAssociationService.checkCurrentTelematicDeviceAssociation(
                this.state.fleetId as number,
                this.state.telematicProviderId || '',
                (value as TelematicDeviceAdminResponse).sourceId as string,
            );
        } else {
            this.props.deviceAssociationService.clearTelematicAssociationValidation();
        }
    }

    @bind
    getRemoteValidationMessage(model: IAjaxState<DeviceAssociationValidationResponse>): string {
        if (model.hasFetched && model.data && model.data.isCurrentlyAssociated) {
            return (
                'Associated with ' +
                model.data.associatedWithType +
                ': ' +
                model.data.associatedWithIdentifier +
                ' since ' +
                DateFormatter.date(moment(model.data.associatedSince))
            );
        }

        return '';
    }

    @bind
    getDisclaimer(left: IAjaxState<DeviceAssociationValidationResponse>, right: IAjaxState<DeviceAssociationValidationResponse>): string {
        if (
            (left.hasFetched && left.data && left.data.isCurrentlyAssociated) ||
            (right.hasFetched && right.data && right.data.isCurrentlyAssociated)
        ) {
            return "Note: All current associations will be ended if you click 'Associate";
        }

        return '';
    }

    @bind
    handleFormCancel() {
        //If they cancel clear any validations
        this.clearState();
        this.props.onDialogCancel();
    }

    render(): JSX.Element {
        const errorMessages = this.state.errorMessage;
        return (
            <Dialog open={this.props.showDialog} fullWidth={true}>
                <DialogTitle>Associate camera with TSP device</DialogTitle>
                <DialogContent>
                    {errorMessages.length ? <div className={styles.errorMessages}>{errorMessages}</div> : null}
                    <ValidatorForm onSubmit={this.handleSubmitAssociation}>
                        <div className={styles.associationForm} style={{ position: 'relative' }}>
                            <div className={styles.fleetRow}>
                                <div style={{ paddingBottom: '20px' }}>First select a client for this assocation, then choose devices.</div>
                                <InputLabel shrink={true} htmlFor="device-association-form-fleet">
                                    Client
                                </InputLabel>
                                <SelectValidator
                                    name="device-association-form-fleet"
                                    inputProps={{
                                        name: 'device-association-form-fleet',
                                        id: 'device-association-form-fleet',
                                        shrink: 'true',
                                    }}
                                    def={'true'}
                                    fullWidth={true}
                                    value={this.state.fleetId ?? ''}
                                    validators={['required']}
                                    errorMessages={['You must select a fleet']}
                                    onChange={this.handleFleetChange}
                                >
                                    {this.getSafeLookupList(this.props.fleetService.getState().FleetResults.data).map((item, idx) => {
                                        return (
                                            <MenuItem value={item.id} key={idx}>
                                                {item.displayName}
                                            </MenuItem>
                                        );
                                    })}
                                </SelectValidator>
                            </div>
                            <div className={styles.deviceRow}>
                                <div className={styles.telematicDeviceSection}>
                                    <fieldset>
                                        <legend>TSP Device</legend>
                                        <InputLabel shrink={true} htmlFor="device-association-form-provider">
                                            Telematic Provider
                                        </InputLabel>
                                        <SelectValidator
                                            name={'telematic provider'}
                                            disabled={!this.state.fleetId}
                                            inputProps={{
                                                name: 'device-association-form-provider',
                                                id: 'device-association-form-provider',
                                                shrink: 'true',
                                            }}
                                            fullWidth={true}
                                            value={this.state.telematicProviderId ?? ''}
                                            validators={['required']}
                                            errorMessages={['You must select a provider']}
                                            onChange={this.handleProviderChange}
                                        >
                                            {this.getSafeLookupList(
                                                this.props.telematicProviderService.getState().TelematicProviderResults.data,
                                            ).map((item, idx) => {
                                                return (
                                                    <MenuItem value={item.id} key={idx}>
                                                        {item.displayName}
                                                    </MenuItem>
                                                );
                                            })}
                                        </SelectValidator>
                                        <Autocomplete
                                            key={this.state.telematicProviderId}
                                            disabled={!this.state.telematicProviderId}
                                            fullWidth={true}
                                            onChange={this.handleTelematicDeviceChange}
                                            options={
                                                (this.props.telematicsDeviceManagement.getState().TelematicDeviceResults
                                                    .data as TelematicDeviceAdminResponse[]) ?? []
                                            }
                                            getOptionLabel={(option) => option.name as string}
                                            renderOption={(props, option) => (
                                                <React.Fragment>
                                                    <span>{option.name}</span>({option.serialNumber})
                                                </React.Fragment>
                                            )}
                                            renderInput={(params) => (
                                                <TextValidator
                                                    name=""
                                                    value={(params.inputProps as any).value ?? ''}
                                                    {...params}
                                                    label="telematic device"
                                                    margin="normal"
                                                    validators={['required']}
                                                    errorMessages={['You must select a tsp device']}
                                                />
                                            )}
                                        />
                                        <span className={styles.remoteValidationMessage}>
                                            {this.getRemoteValidationMessage(
                                                this.props.deviceAssociationService.getState().TelematicValidationResult,
                                            )}
                                        </span>
                                    </fieldset>
                                </div>
                                <div className={styles.associateIconSection}>
                                    <CompareArrows />
                                </div>
                                <div className={styles.cameraDeviceSection}>
                                    <fieldset>
                                        <legend>Camera</legend>
                                        <InputLabel shrink={true} htmlFor="device-association-form-camera-type">
                                            Camera Type
                                        </InputLabel>
                                        <SelectValidator
                                            name={'camera type'}
                                            disabled={!this.state.fleetId}
                                            inputProps={{
                                                name: 'device-association-form-camera-type',
                                                id: 'device-association-form-camera-type',
                                                shrink: 'true',
                                            }}
                                            fullWidth={true}
                                            value={this.state.cameraTypeId ?? ''}
                                            validators={['required']}
                                            errorMessages={['You must select a camera type']}
                                            onChange={this.handleCameraTypeChange}
                                        >
                                            {this.getSafeLookupList(this.props.cameraTypeService.getState().CameraTypeResults.data).map(
                                                (item, idx) => {
                                                    return (
                                                        <MenuItem value={item.id} key={idx}>
                                                            {item.displayName}
                                                        </MenuItem>
                                                    );
                                                },
                                            )}
                                        </SelectValidator>
                                        <Autocomplete
                                            key={this.state.cameraTypeId} //Set key to cameraTypeId so it rerenders when cameratype is changed
                                            disabled={!this.state.cameraTypeId}
                                            fullWidth={true}
                                            onChange={this.handleCameraChange}
                                            options={(this.props.cameraService.getState().CameraResults.data as CameraResponse[]) ?? []}
                                            getOptionLabel={(option) => option.cameraSerialNumber as string}
                                            renderOption={(props, option) => (
                                                <React.Fragment>
                                                    <span>
                                                        {!option.isActive ? 'inactive:' : ''}
                                                        {option.cameraSerialNumber}
                                                    </span>
                                                    ({option.cameraImei})
                                                </React.Fragment>
                                            )}
                                            renderInput={(params) => (
                                                <TextValidator
                                                    name=""
                                                    value={(params.inputProps as any).value ?? ''}
                                                    {...params}
                                                    validators={['required']}
                                                    errorMessages={['You must select a camera']}
                                                    label="camera"
                                                    margin="normal"
                                                />
                                            )}
                                        />
                                        <span className={styles.remoteValidationMessage}>
                                            {this.getRemoteValidationMessage(
                                                this.props.deviceAssociationService.getState().CameraValidationResult,
                                            )}
                                        </span>
                                    </fieldset>
                                </div>
                            </div>
                            <div className={styles.remoteValidationMessage + ' ' + styles.associationDisclaimer}>
                                {this.getDisclaimer(
                                    this.props.deviceAssociationService.getState().TelematicValidationResult,
                                    this.props.deviceAssociationService.getState().CameraValidationResult,
                                )}
                            </div>

                            <div>
                                <Button onClick={this.handleFormCancel} className={styles.cancelButton} color={'secondary'}>
                                    CANCEL
                                </Button>

                                <Button className={styles.associateButton} type="submit" color={'primary'}>
                                    ASSOCIATE
                                </Button>
                            </div>

                            {this.state.isSaving && (
                                <div className={styles.loadingMask}>
                                    <CircularProgress size={40} className={styles.buttonProgress} />
                                </div>
                            )}
                        </div>
                    </ValidatorForm>
                </DialogContent>
            </Dialog>
        );
    }
}

export const DeviceAssociationDialog = DeviceAssociationService.inject(
    CameraTypeService.inject(
        TelematicDeviceService.inject(CameraService.inject(TelematicProviderService.inject(FleetService.inject(_DeviceAssociationDialog)))),
    ),
);
