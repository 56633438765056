import { React, bind } from '../../Imports';
import { TextField, withStyles, FormControl } from '../../MaterialUIComponents';
import { ChangeEvent } from 'react';

interface IVPTextboxProps {
    name: string;
    placeholder?: string;
    selectedValue?: string;
    onSelectedItemChange?: (value: string | undefined) => void;
    onEnterKey?: () => void;
    error?: boolean;
}

interface IVPTextboxState {
    selectedValue?: string;
}

// override the input styles of the material ui theme
const muiStyles = (): {} => ({
    input: {
        display: 'flex',
        padding: 0,
        height: 'auto',
    },
});

class _VPTextbox extends React.PureComponent<IVPTextboxProps, IVPTextboxState> {
    state = {
        selectedValue: this.props.selectedValue,
    };

    @bind
    handleChange(event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void {
        const { onSelectedItemChange } = this.props;
        const value: string | undefined = event.target.value;
        if (onSelectedItemChange != undefined) {
            onSelectedItemChange(value);
        }
    }

    render(): JSX.Element {
        // Note that 'classes' is injected by material ui
        const { name, selectedValue } = this.props;
        return (
            <FormControl style={{ padding: '10px', float: 'left' }}>
                <TextField
                    label={name}
                    placeholder={this.props.placeholder}
                    value={selectedValue ? selectedValue : ''}
                    onChange={this.handleChange}
                    onKeyDown={(e) => (e.key === 'Enter' ? (this.props.onEnterKey != undefined ? this.props.onEnterKey() : null) : null)}
                />
            </FormControl>
        );
    }
}

export const VPTextbox = withStyles(muiStyles, { withTheme: true })(_VPTextbox);
