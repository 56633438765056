import React = require('react');
import { useState, useCallback } from 'react';
import {
    Dialog,
    TextField,
    Button,
    Typography,
    DialogTitle,
    DialogContent,
    CircularProgress,
    DialogActions,
    Box,
} from 'MaterialUIComponents';
import DeleteIcon from '@mui/icons-material/Delete';
import Add from '@mui/icons-material/Add';
import { DeviceManagementService } from '$State/DeviceManagementFreezerService';
import { DeviceUpdateFleetRequest, BulkUpdateFleetRequest } from '$Generated/api';
import { AlertColor } from '@mui/material';
import WarningIcon from '@mui/icons-material/Warning';
import StyledAlert from '../Shared/MaterialUI/Components/Alert/StyledAlert';
import { StyledRoundedSubmitButton, StyledRoundedCancelButton } from '../Shared/MaterialUI/Controls/StyledButtons';

interface Device {
    serialNumber: string;
    currentFleetName: string;
    newFleetName: string;
    newCompanyId: string;
    currentFleetHasError: boolean;
    newFleetHasError: boolean;
}

interface MoveDeviceModalProps {
    onClose: () => void;
    showAlert: (message: string, type: AlertColor) => void;
}

const styles = require('./MoveDeviceModal.scss') as {
    containerBox: string;
    innerBox: string;
    button: string;
};

const MoveDeviceModal: React.FC<MoveDeviceModalProps> = ({ onClose, showAlert }: MoveDeviceModalProps) => {
    const [devices, setDevices] = useState<Device[]>([
        {
            serialNumber: '',
            currentFleetName: '',
            newFleetName: '',
            newCompanyId: '',
            currentFleetHasError: false,
            newFleetHasError: false,
        },
    ]);

    const [isLoading, setIsLoading] = useState<boolean>(false);

    const handleAddDevice = () => {
        if (devices.length < 3) {
            setDevices([
                ...devices,
                {
                    serialNumber: '',
                    currentFleetName: '',
                    newFleetName: '',
                    newCompanyId: '',
                    currentFleetHasError: false,
                    newFleetHasError: false,
                },
            ]);
        }
    };

    const handleRemoveDevice = (index: number) => {
        if (devices.length > 1) {
            const newDevices = devices.filter((_, i) => i !== index);
            setDevices(newDevices);
        }
    };

    const handleInputChange = useCallback(
        (value: string, index: number, key: keyof Device) => {
            const newDevices: any[] = [...devices];
            newDevices[index][key] = value;
            setDevices(newDevices);
        },
        [devices],
    );

    const handleInputUpdate = useCallback(
        async (index: number, key: keyof Device, value: string) => {
            setIsLoading(true);
            const newDevices: any[] = [...devices];
            try {
                switch (key) {
                    case 'serialNumber':
                        const isSerialNumberDuplicate = devices.some((device, idx) => idx !== index && device.serialNumber === value);
                        if (isSerialNumberDuplicate) {
                            throw new Error('Serial number already exists');
                        }
                        await DeviceManagementService.getDeviceFleet(value);
                        const freezerDeviceFleet = DeviceManagementService.getState();
                        const { deviceFleetResult } = freezerDeviceFleet;
                        newDevices[index] = {
                            ...newDevices[index],
                            currentFleetName: deviceFleetResult?.data?.name ?? '',
                            currentFleetHasError: false,
                        };
                        break;
                    case 'newCompanyId':
                        await DeviceManagementService.getCompanyFleet(value);
                        const freezerCompanyFleet = DeviceManagementService.getState();
                        const { companyFleetResult } = freezerCompanyFleet;
                        newDevices[index] = {
                            ...newDevices[index],
                            newFleetName: companyFleetResult?.data?.name ?? '',
                            newFleetHasError: false,
                        };
                        break;
                }
            } catch (error) {
                if (key === 'serialNumber') {
                    newDevices[index] = {
                        ...newDevices[index],
                        currentFleetName: 'Serial number is not valid',
                        currentFleetHasError: true,
                    };
                } else if (key === 'newCompanyId') {
                    newDevices[index] = {
                        ...newDevices[index],
                        newFleetName: 'CompanyId is not valid',
                        newFleetHasError: true,
                    };
                }
            }

            newDevices[index][key] = value;
            setDevices(newDevices);
            setIsLoading(false);
        },
        [isLoading, devices],
    );

    const handleConfirm = async () => {
        try {
            const deviceUpdateRequest: DeviceUpdateFleetRequest[] = devices.map((device) => ({
                serialNumber: device.serialNumber,
                newFleetName: device.newFleetName,
                newCompanyId: device.newCompanyId,
                currentFleetName: device.currentFleetName,
            }));

            const bulkRequest: BulkUpdateFleetRequest = {
                deviceUpdateFleets: deviceUpdateRequest,
            };
            await DeviceManagementService.bulkUpdateCameraFleet(bulkRequest);
            DeviceManagementService.getState();
            showAlert('Device(s) moved successfully', 'success');
        } catch (error) {
            console.error(error);
            showAlert('Device(s) unable to be moved', 'error');
        } finally {
            onClose();
        }
    };

    return (
        <Dialog open={true} onClose={onClose} maxWidth="lg">
            <DialogTitle>
                <strong>Move Device</strong>
            </DialogTitle>
            <DialogContent>
                <StyledAlert
                    backgroundColor="#FDE5E5"
                    iconColor="#9F0D0D"
                    message="Any device that is currently paired to a vehicle will be unpaired as part of the move."
                    muiIcon={WarningIcon}
                    textColor="#721c24"
                    isVisible={true}
                    isBold={false}
                    borderRadius="5px"
                ></StyledAlert>
                {devices.map((device, index) => (
                    <div key={index}>
                        <Box className={styles.containerBox} marginTop={2}>
                            <Box className={styles.innerBox}>
                                <Typography variant="body2">
                                    <strong>Device to be moved:</strong>
                                </Typography>
                                <TextField
                                    autoComplete="off"
                                    label="Enter Device Serial #"
                                    value={device.serialNumber}
                                    onChange={(e) => handleInputChange(e.target.value, index, 'serialNumber')}
                                    onBlur={(e) => handleInputUpdate(index, 'serialNumber', e.target.value)}
                                    InputProps={{ placeholder: 'Enter Device Serial #' }}
                                    fullWidth
                                    tabIndex={devices.length - 1}
                                />
                            </Box>
                            <Box className={styles.innerBox}>
                                {devices.length > 1 && (
                                    <Button
                                        onClick={() => handleRemoveDevice(index)}
                                        startIcon={<DeleteIcon />}
                                        size="small"
                                        className={styles.button}
                                        tabIndex={-1}
                                        sx={{ float: 'right', textTransform: 'none' }}
                                    >
                                        Remove
                                    </Button>
                                )}
                                <Typography variant="body2" />
                                <TextField
                                    label="Current fleet"
                                    value={device.currentFleetName}
                                    InputProps={{ readOnly: true, endAdornment: isLoading && <CircularProgress size={15} /> }}
                                    disabled={true}
                                    fullWidth
                                    error={device.currentFleetHasError}
                                />
                            </Box>
                        </Box>

                        <Box className={styles.containerBox}>
                            <Box className={styles.innerBox}>
                                <Typography variant="body2">
                                    <strong>Move device to this fleet:</strong>
                                </Typography>
                                <TextField
                                    label="Enter Company ID"
                                    value={device.newCompanyId}
                                    autoComplete="off"
                                    onChange={(e) => handleInputChange(e.target.value, index, 'newCompanyId')}
                                    onBlur={(e) => handleInputUpdate(index, 'newCompanyId', e.target.value)}
                                    InputProps={{ placeholder: 'Enter Company ID' }}
                                    fullWidth
                                    tabIndex={devices.length}
                                />
                            </Box>
                            <Box className={styles.innerBox}>
                                <div style={{ height: '20px' }}></div>
                                <TextField
                                    label="New fleet"
                                    value={device.newFleetName}
                                    InputProps={{ readOnly: true, endAdornment: isLoading && <CircularProgress size={15} /> }}
                                    disabled={true}
                                    fullWidth
                                    error={device.newFleetHasError}
                                />
                            </Box>
                        </Box>
                    </div>
                ))}

                <Button
                    onClick={handleAddDevice}
                    disabled={
                        devices.length >= 3 ||
                        devices.some(
                            (device) =>
                                !device.serialNumber || !device.newCompanyId || device.currentFleetHasError || device.newFleetHasError,
                        )
                    }
                    startIcon={<Add />}
                    size="small"
                    style={{ textTransform: 'none', marginTop: '10px' }}
                >
                    Add Another
                </Button>

                <DialogActions>
                    <StyledRoundedCancelButton onClick={onClose}>Cancel</StyledRoundedCancelButton>
                    <StyledRoundedSubmitButton
                        onClick={() => handleConfirm()}
                        disabled={devices.some(
                            (device) =>
                                !device.serialNumber || !device.newCompanyId || device.currentFleetHasError || device.newFleetHasError,
                        )}
                    >
                        Confirm
                    </StyledRoundedSubmitButton>
                </DialogActions>
            </DialogContent>
        </Dialog>
    );
};

export default MoveDeviceModal;
