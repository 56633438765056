import { React } from '../../Imports';
import { StatusCard } from '../../components/DeviceDetails/StatusCard';
import { DeviceCard } from '../../components/DeviceDetails/DeviceCard';
import { TelematicsCard } from '../../components/DeviceDetails/TelematicsCard';
import { SystemLogsCard } from '../../components/DeviceDetails/SystemLogsCard';
import { HistoryCard } from '../../components/DeviceDetails/HistoryCard';
import { CameraDeviceResponse } from '../../generated/api';
import { 
    IDeviceManagementServiceInjectedProps, 
    DeviceManagementService 
} from '../../state/DeviceManagementFreezerService';
import { PageHeader } from '../../components/Shared/PageHeader';
import { Button } from '../../MaterialUIComponents';

interface IDeviceDetailsBaseProps {
    deviceId: number;
}

type IDeviceDetailsProps = IDeviceDetailsBaseProps & IDeviceManagementServiceInjectedProps;

const styles = require('./DeviceDetails.scss') as {
    main: string;
    buttonRow: string;
    button: string;
    flexContainer: string;
    leftContainer: string;
    centerContainer: string;
    rightContainer: string;
    upperCenterContainer: string;
    lowerCenterContainer: string;
};

//TODO: Restructure this page to be camera focused rather than pairing focused
class _DeviceDetails extends React.Component<IDeviceDetailsProps, {}> {
    constructor(props: IDeviceDetailsProps) {
        super(props);
    }

    async componentDidMount(): Promise<void> {
        await this.props.deviceManagement.getDevice(this.props.deviceId);
    }

    render(): JSX.Element {
        const freezer = this.props.deviceManagement.getState();
        const { DeviceDetailsResult } = freezer;
        const data: CameraDeviceResponse = DeviceDetailsResult.data ? DeviceDetailsResult.data : {};
        const loading = DeviceDetailsResult.state === 'fetching';

        return (
            <div>
                {DeviceDetailsResult.state === "ok" && data.id !== 0 ?
                (
                    <div className={styles.main}>
                        <PageHeader pageTitle={'Device Management Details'} />
                        <div className={styles.flexContainer}>
                            <div className={styles.leftContainer}>
                                <DeviceCard cameraId={data.id} />
                            </div>
                            <div className={styles.centerContainer}>
                                <div className={styles.upperCenterContainer}>
                                    <SystemLogsCard />
                                    <HistoryCard deviceId={data.id} />
                                </div>
                                <div className={styles.lowerCenterContainer}>
                                    <StatusCard device={data} />
                                </div>
                            </div>
                            <div className={styles.rightContainer}>
                                <TelematicsCard device={data.currentPairing ?? {}} />
                            </div>
                        </div>
                        <div className={styles.buttonRow}>
                            <Button className={styles.button}>Deprovision</Button>
                        </div>
                    </div>  
                ) :
                (
                    <div>Loading...</div>
                )}
                
            </div>
        );
    }
}

export const DeviceDetailsPage = DeviceManagementService.inject(_DeviceDetails);
