import { _, FreezerService, managedAjaxUtil, IAjaxState } from '../Imports';
import { DeviceManagementApiFactory, DeviceManagementFilterDataResponse } from '$Generated/api';

export const ITEMS_PER_PAGE = 25;

export interface IFilterState {
    Filters: IFilterBarValues;
    CurrentPage: number;
    SortBy: string;
    SortAsc: boolean;
    ItemsPerPage: number;
    DeviceManagementFilters: IAjaxState<DeviceManagementFilterDataResponse>;
    hasError: boolean;
}

export interface IFilterBarValues {
    searchSelectedValue?: string;
    selectedCameraDevices?: Array<number>;
    selectedClients?: Array<number>;
    selectedStatuses?: Array<string>;
}

const InjectedPropName = 'deviceManagementFilter';

class DeviceManagementFilterFreezerService extends FreezerService<IFilterState, typeof InjectedPropName> {
    constructor() {
        super(
            {
                Filters: { selectedStatuses: [], selectedCameraDevices: [], selectedClients: [] },
                SortBy: 'startdate',
                SortAsc: false,
                CurrentPage: 1,
                ItemsPerPage: ITEMS_PER_PAGE,
                DeviceManagementFilters: managedAjaxUtil.createInitialState(),
                hasError: false,
            },
            InjectedPropName,
        );
    }

    public getDeviceManagementFilter(): IFilterState {
        //clone so that the object can be manipulated by the caller
        //Otherwise the state object is immutable(read-only)
        return _.clone(this.getState());
    }

    public setPageNumber(pageNumber: number): void {
        this.freezer.get().set({ CurrentPage: pageNumber });
    }

    public setSortFields(sortBy: string): void {
        const currentFilter = this.getState();

        let isAsc = true;
        if (sortBy == currentFilter.SortBy) {
            isAsc = !currentFilter.SortAsc;
        }

        this.freezer.get().set({ SortBy: sortBy, SortAsc: isAsc, CurrentPage: 1 });
    }

    public updateFilterOptions(filters: IFilterBarValues): void {
        const currentFilter = this.getState();

        const updatedFilter: IFilterState = {
            Filters: filters,
            CurrentPage: 1, //reset to 1 after changing filters
            SortAsc: currentFilter.SortAsc,
            SortBy: currentFilter.SortBy,
            ItemsPerPage: currentFilter.ItemsPerPage,
            hasError: currentFilter.hasError,
            DeviceManagementFilters: currentFilter.DeviceManagementFilters,
        };

        this.freezer.get().set(updatedFilter);
    }

    public clearFilters(): void {
        const currentFilter = this.getState();

        const updatedFilter: IFilterState = {
            Filters: {} as IFilterBarValues,
            CurrentPage: 1, //reset to 1 after changing filters
            SortAsc: currentFilter.SortAsc,
            SortBy: currentFilter.SortBy,
            ItemsPerPage: currentFilter.ItemsPerPage,
            hasError: currentFilter.hasError,
            DeviceManagementFilters: currentFilter.DeviceManagementFilters,
        };

        this.freezer.get().set(updatedFilter);
    }

    public async getFilterData(): Promise<void | DeviceManagementFilterDataResponse> {
        return managedAjaxUtil.fetchResults({
            ajaxStateProperty: 'DeviceManagementFilters',
            freezer: this.freezer,
            onExecute: (apiOptions) => {
                const AdministrativeApi = DeviceManagementApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return AdministrativeApi.apiV1DeviceassociationsFiltersGet();
            },
            onOk: (data: DeviceManagementFilterDataResponse) => {
                if (data !== undefined) {
                    this.freezer.get().set({ hasError: false });
                }

                return data;
            },
            onError: () => {
                this.freezer.get().set({ hasError: true });
            },
        });
    }
}

export const DeviceManagementFilterService = new DeviceManagementFilterFreezerService();
export type IDeviceManagementFilterServiceInjectedProps = ReturnType<
    DeviceManagementFilterFreezerService['getPropsForInjection']
>;
