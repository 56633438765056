import { React, moment, bind } from '../../Imports';
import { Card, Table, TableBody, TableRow, TableCell } from '../../MaterialUIComponents';
import { TelematicsDeviceResponse, VehicleCameraPairingResponse } from '$Generated/api';
import * as DateFormatter from '../Shared/DateFormatter';
import { RefreshIcon } from '../../MaterialUIIcons';
import {
    ITelematicDeviceServiceInjectedProps,
    TelematicDeviceService,
} from '../../state/TelematicDeviceFreezerService';

const styles = require('./DeviceCard.scss') as {
    card: string;
    menuHeader: string;
    headerIcon: string;
};

interface ITelematicsCardBaseProps {
    device: VehicleCameraPairingResponse;
}

type ITelematicsCardProps = ITelematicsCardBaseProps & ITelematicDeviceServiceInjectedProps;

class _TelematicsCard extends React.Component<ITelematicsCardProps, {}> {
    constructor(props: ITelematicsCardProps) {
        super(props);
    }

    async componentDidMount(): Promise<void> {
        this.props.telematicsDeviceManagement.clearDeviceDetailsResult();
        await this.props.telematicsDeviceManagement.getDevice(this.props.device.vehicle?.telematicDevice?.id?.toString());
    }

    @bind
    async onRefreshCard(): Promise<void> {
        await this.props.telematicsDeviceManagement.getDevice(this.props.device.vehicle?.telematicDevice?.id?.toString());
    }

    render(): JSX.Element {
        const freezer = this.props.telematicsDeviceManagement.getState();
        const { DeviceDetailsResult } = freezer;
        const telematic: TelematicsDeviceResponse = DeviceDetailsResult.data ? DeviceDetailsResult.data : {};
        return (
            <Card className={styles.card}>
                <div className={styles.menuHeader}>
                    Telematics Device
                    <RefreshIcon className={styles.headerIcon} onClick={this.onRefreshCard} />
                </div>
                {telematic.id ? (
                    <Table>
                        <TableBody>
                            <TableRow key={'provider'}>
                                <TableCell>Provider:</TableCell>
                                <TableCell>{telematic.provider}</TableCell>
                            </TableRow>
                            <TableRow key={'serial'}>
                                <TableCell>Serial #:</TableCell>
                                <TableCell>{telematic.serialNumber}</TableCell>
                            </TableRow>
                            <TableRow key={'name'}>
                                <TableCell>Name:</TableCell>
                                <TableCell>{telematic.name}</TableCell>
                            </TableRow>
                            <TableRow key={'type'}>
                                <TableCell>Device Type:</TableCell>
                                <TableCell>{telematic.type}</TableCell>
                            </TableRow>
                            <TableRow key={'gps'}>
                                <TableCell>Last GPS Location:</TableCell>
                                <TableCell>
                                    {telematic.latitude && telematic.longitude
                                        ? '(' + telematic.latitude + ', ' + telematic.longitude + ')'
                                        : ''}
                                    <br />
                                    {telematic.location}
                                </TableCell>
                            </TableRow>
                            <TableRow key={'ignition'}>
                                <TableCell>Ignition On:</TableCell>
                                <TableCell>{telematic.ignitionOn ? 'Yes' : 'No'}</TableCell>
                            </TableRow>
                            <TableRow key={'refreshDate'}>
                                <TableCell>Last Refreshed On:</TableCell>
                                <TableCell>{DateFormatter.dateAndTimezone(moment(telematic.refreshDate))}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                ) : (
                    'No device is currently assigned.'
                )}
            </Card>
        );
    }
}

export const TelematicsCard = TelematicDeviceService.inject(_TelematicsCard);
