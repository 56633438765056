import {
    TableBody,
    TableCell,
    TableHead,
    Table,
    TableRow,
    TableSortLabel,
    Grid,
    Dialog,
    DialogTitle,
    Paper,
    DialogContent,
    IconButton,
    CircularProgress,
    DialogActions,
    Snackbar,
    Alert,
} from 'MaterialUIComponents';
import { CloseIcon } from 'MaterialUIIcons';
import { React, moment, bind } from '../../Imports';
import { VehicleCameraPairingResponse, DeviceHealthResponse, DataUsageResponse } from '$Generated/api';
import * as DateFormatter from '$Components/Shared/DateFormatter';
import { IDeviceManagementServiceInjectedProps, DeviceManagementService } from '$State/DeviceManagementFreezerService';
import {
    IDeviceManagementFilterServiceInjectedProps,
    DeviceManagementFilterService,
    IFilterState,
} from '$State/DeviceManagementFilterFreezerService';
import { PageHeader } from '$Components/Shared/PageHeader';
import { FilterBar } from '$Components/DeviceManagement/FilterBar';
import * as InfiniteScroll from 'react-infinite-scroller';

import { DeviceAssociationDialog } from '../../components/DeviceManagement/DeviceAssociationDialog';
import { ConfigService } from '$State/ConfigFreezerService';
import MoveDeviceModal from '../../components/DeviceManagement/MoveDeviceModal';
import { AlertColor } from '@mui/material';

interface IDeviceManagementBaseProps {
    onSelectDevice: (id: number) => void;
}

type IDeviceManagementProps = IDeviceManagementBaseProps &
    IDeviceManagementServiceInjectedProps &
    IDeviceManagementFilterServiceInjectedProps;

interface IDeviceManagementState {
    currentPageNumber: number;
    showPairing: boolean;
    openDataUsage: boolean;
    individualDataUsage: DataUsageResponse[];
    dataUsageIsFetching: boolean;
    dataUsageError: string;
    showMoveDeviceDialog: boolean;
    snackbarOpen: boolean;
    snackbarMessage: string;
    snackbarType: AlertColor;
}

const styles = require('./DeviceManagement.scss') as {
    main: string;
    buttonContainer: string;
    addButton: string;
    card: string;
    sortLabel: string;
    endPairing: string;
    pickerContent: string;
    pickerLabel: string;
    select: string;
    greenStatus: string;
    redStatus: string;
    scrollWindow: string;
    lastDataUsage: string;
    onlineStreamaxStatus: string;
};

const rowHeaders = [
    {
        id: 'cameraserial',
        label: 'Serial #',
        isSortable: true,
    },
    {
        id: 'client',
        label: 'Fleet ID',
        isSortable: true,
    },
    {
        id: 'cameraiccid',
        label: 'ICCID',
        isSortable: true,
    },
    {
        id: 'camerastatus',
        label: 'Current Camera Status',
    },
    {
        id: 'simstatus',
        label: 'SIM Status',
    },
    {
        id: 'lastevent',
        label: 'Last Event',
        isSortable: true,
    },
    {
        id: 'lastgpscommunication',
        label: 'Last GPS communication received',
    },
    {
        id: 'lastheartbeat',
        label: 'Last Heartbeat Time',
        isSortable: true,
    },
    {
        id: 'last7days',
        label: 'Last 7 days of data usage',
    },
];

class _DeviceManagementPage extends React.Component<IDeviceManagementProps, IDeviceManagementState> {
    state: IDeviceManagementState = {
        currentPageNumber: 1,
        showPairing: false,
        openDataUsage: false,
        individualDataUsage: [] as DataUsageResponse[],
        dataUsageIsFetching: false,
        dataUsageError: '',
        showMoveDeviceDialog: false,
        snackbarOpen: false,
        snackbarMessage: '',
        snackbarType: 'info' as AlertColor,
    };

    private scrollParentRef2: any;

    async componentDidMount(): Promise<void> {
        this.props.deviceManagementFilter.setPageNumber(1);
        DeviceManagementService.clearDevices();
        await this.getDevices();
    }

    @bind
    async loadNextPage(): Promise<void> {
        if (!this.props.deviceManagement.getState().DeviceManagementResults.isFetching) {
            const filters = this.props.deviceManagementFilter.getDeviceManagementFilter();
            const currentPage = filters.CurrentPage;
            if (currentPage && currentPage !== undefined) {
                let newPage = currentPage;
                newPage++;
                this.props.deviceManagementFilter.setPageNumber(newPage);
                await this.getDevices();
            }
        }
    }

    @bind
    async _onPageNumberChanged(newPage: number): Promise<void> {
        this.props.deviceManagementFilter.setPageNumber(newPage);
        const filter = this.props.deviceManagementFilter.getDeviceManagementFilter();
        await this.props.deviceManagement.getFilteredDevices(filter);
    }

    @bind
    async handleSearch(): Promise<void> {
        this.props.deviceManagementFilter.setPageNumber(1);
        DeviceManagementService.clearDevices();
        await this.getDevices();
    }

    @bind
    async handleRequestSort(field: string): Promise<void> {
        this.props.deviceManagementFilter.setSortFields(field);
        DeviceManagementService.clearDevices();
        await this.getDevices();
    }

    @bind
    async getDevices(): Promise<void> {
        const freezer = this.props.deviceManagement.getState();
        const { DeviceManagementResults } = freezer;
        if (!DeviceManagementResults.isFetching) {
            const filters: IFilterState = this.props.deviceManagementFilter.getDeviceManagementFilter();
            await this.props.deviceManagement.getDevices(
                filters.CurrentPage,
                filters.ItemsPerPage,
                filters.SortBy,
                filters.SortAsc,
                filters.Filters.searchSelectedValue,
                filters.Filters.selectedClients,
                filters.Filters.selectedCameraDevices,
                filters.Filters.selectedStatuses,
            );
        }
    }

    @bind
    handleAsscoiationDialogCancel() {
        //hide add association form//TODO: move this to
        this.setState({ showPairing: false });
        this.props.deviceManagementFilter.setPageNumber(1);
    }

    @bind
    handleAssociatedCreated() {
        //DO STUFF
        this.setState({ showPairing: false });
        this.props.deviceManagementFilter.setPageNumber(1);
        DeviceManagementService.clearDevices();
        this.getDevices();
    }

    @bind
    handleDisplayAddForm(): void {
        //show add form
        this.setState({ showPairing: true });
    }

    @bind
    async handleDataUsageOnClick(iccid: string): Promise<void> {
        if (iccid) {
            this.setState({ openDataUsage: true, dataUsageIsFetching: true, dataUsageError: '' });

            try {
                await DeviceManagementService.getIndividualDataUsage(iccid);
                this.setState({
                    individualDataUsage: DeviceManagementService.getState().DataUsageResult.data ?? [],
                    dataUsageIsFetching: false,
                });
            } catch (e) {
                this.setState({
                    dataUsageIsFetching: false,
                    dataUsageError: 'Unexpected error occurred while loading the Last 7 days of data usage.',
                });
            }
        }
    }

    @bind
    handleDataUsageDialogClose(): void {
        this.setState({
            openDataUsage: false,
            individualDataUsage: [] as DataUsageResponse[],
            dataUsageIsFetching: false,
            dataUsageError: '',
        });
    }

    @bind
    handleMoveDevice(): void {
        this.setState({ showMoveDeviceDialog: true });
    }

    @bind
    showAlert(message: string, type: AlertColor): void {
        this.setState({ snackbarMessage: message, snackbarOpen: true, snackbarType: type });
    }

    render(): JSX.Element {
        const freezer = this.props.deviceManagement.getState();
        const { DeviceManagementResults, DeviceManagementResultsFinal } = freezer;
        const data: VehicleCameraPairingResponse[] = DeviceManagementResultsFinal.length > 0 ? DeviceManagementResultsFinal : [];
        const filters = this.props.deviceManagementFilter.getDeviceManagementFilter();
        const sortOrder = filters.SortAsc ? 'asc' : 'desc';
        const dataUsage = this.state.individualDataUsage;
        const hasMorePagesToLoad =
            !freezer.hasInfiniteScrollError &&
            (DeviceManagementResults.data?.currentPage ?? 0) < (DeviceManagementResults.data?.totalPages ?? 0);
        const platform = ConfigService.getIntegrationPlatform();

        return (
            <div>
                {this.state.snackbarOpen && (
                    <Snackbar
                        open={this.state.snackbarOpen}
                        onClose={() => this.setState({ snackbarOpen: false })}
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                    >
                        <Alert
                            onClose={() => this.setState({ snackbarOpen: false })}
                            severity={this.state.snackbarType}
                            sx={{ width: '100%' }}
                        >
                            {this.state.snackbarMessage}
                        </Alert>
                    </Snackbar>
                )}

                {this.state.showMoveDeviceDialog && (
                    <MoveDeviceModal onClose={() => this.setState({ showMoveDeviceDialog: false })} showAlert={this.showAlert} />
                )}
                <div className={styles.main}>
                    <PageHeader pageTitle={'Device Associations'} />
                    <FilterBar
                        onSearch={this.handleSearch}
                        onDisplayAddForm={this.handleDisplayAddForm}
                        onMoveDevice={this.handleMoveDevice}
                        isEncompass={platform === 'encompass'}
                    />
                    <div className={styles.scrollWindow} ref={(ref): any => (this.scrollParentRef2 = ref)}>
                        <InfiniteScroll
                            pageStart={filters.CurrentPage}
                            loadMore={this.loadNextPage}
                            hasMore={hasMorePagesToLoad}
                            useWindow={false}
                            loader={
                                <div className="loader" key={0}>
                                    Loading ...
                                </div>
                            }
                            getScrollParent={(): void => this.scrollParentRef2}
                        >
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        {rowHeaders.map((head) => {
                                            return (
                                                <TableCell key={head.id} sortDirection={filters.SortBy === head.id ? sortOrder : false}>
                                                    <TableSortLabel
                                                        active={filters.SortBy === head.id}
                                                        direction={sortOrder}
                                                        onClick={async (): Promise<void> => await this.handleRequestSort(head.id)}
                                                        className={styles.sortLabel}
                                                        disabled={!head.isSortable}
                                                    >
                                                        {head.label}
                                                    </TableSortLabel>
                                                </TableCell>
                                            );
                                        })}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {data.map((camera: DeviceHealthResponse, idx) => {
                                        const cameraDevice = camera || {};
                                        return (
                                            <TableRow key={idx}>
                                                <TableCell>{cameraDevice.serialNumber}</TableCell>
                                                <TableCell>{cameraDevice.clientName}</TableCell>
                                                <TableCell>{cameraDevice.iccid}</TableCell>
                                                <TableCell
                                                    className={cameraDevice.deviceStatus == 'Online' ? styles.onlineStreamaxStatus : ''}
                                                >
                                                    {cameraDevice.deviceStatus}
                                                </TableCell>
                                                <TableCell
                                                    className={cameraDevice.simStatus === 'active' ? styles.greenStatus : styles.redStatus}
                                                >
                                                    {cameraDevice.simStatus}
                                                </TableCell>
                                                <TableCell>
                                                    {cameraDevice.lastProcessedEventTime
                                                        ? DateFormatter.dateAndTimezone(moment(cameraDevice.lastProcessedEventTime))
                                                        : ''}
                                                </TableCell>
                                                <TableCell>
                                                    {cameraDevice.lastKnownLocation?.latitude !== 0 &&
                                                    cameraDevice.lastKnownLocation?.longitude !== 0 ? (
                                                        <>
                                                            <div>
                                                                {DateFormatter.dateAndTimezone(moment(cameraDevice.lastGPSCommunication))}
                                                            </div>
                                                            <a
                                                                href={
                                                                    'https://www.google.com/maps/place/' +
                                                                    cameraDevice.lastKnownLocation?.latitude +
                                                                    ',' +
                                                                    cameraDevice.lastKnownLocation?.longitude
                                                                }
                                                                target="_blank"
                                                                rel="noreferrer"
                                                            >
                                                                {'Lat ' +
                                                                    cameraDevice.lastKnownLocation?.latitude +
                                                                    ' - Long ' +
                                                                    cameraDevice.lastKnownLocation?.longitude}
                                                            </a>
                                                        </>
                                                    ) : (
                                                        ''
                                                    )}
                                                </TableCell>
                                                <TableCell>
                                                    {cameraDevice.lastHeartbeatTime
                                                        ? DateFormatter.dateAndTimezone(moment(cameraDevice.lastHeartbeatTime))
                                                        : ''}
                                                </TableCell>
                                                <TableCell>
                                                    <div
                                                        className={styles.lastDataUsage}
                                                        onClick={() => this.handleDataUsageOnClick(cameraDevice.iccid ?? '')}
                                                    >
                                                        {cameraDevice.lastDataUsage}
                                                    </div>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </InfiniteScroll>
                    </div>
                </div>
                <DeviceAssociationDialog
                    showDialog={this.state.showPairing}
                    onDialogCancel={this.handleAsscoiationDialogCancel}
                    onAssociationCreated={this.handleAssociatedCreated}
                />

                <Dialog onClose={this.handleDataUsageDialogClose} open={this.state.openDataUsage} maxWidth="md">
                    <DialogTitle>
                        Last 7 days of data usage
                        {this.handleDataUsageDialogClose ? (
                            <IconButton onClick={this.handleDataUsageDialogClose} style={{ float: 'right' }} size="large">
                                <CloseIcon />
                            </IconButton>
                        ) : null}
                    </DialogTitle>
                    <DialogContent dividers>
                        {dataUsage.length > 0 ? (
                            <Grid container spacing={3}>
                                {dataUsage.map((data, idx) => (
                                    <Grid key={idx} item xs={3}>
                                        <Paper>
                                            <div>{data.usageDate}</div>
                                            <div>
                                                {data.quantity}
                                                {data.unit}
                                            </div>
                                        </Paper>
                                    </Grid>
                                ))}
                            </Grid>
                        ) : (
                            <Grid container>
                                <Grid item xs={12}>
                                    {this.state.dataUsageIsFetching ? <CircularProgress /> : this.state.dataUsageError}
                                </Grid>
                            </Grid>
                        )}
                    </DialogContent>
                    <DialogActions></DialogActions>
                </Dialog>
            </div>
        );
    }
}

export const DeviceManagementPage = DeviceManagementService.inject(DeviceManagementFilterService.inject(_DeviceManagementPage));
