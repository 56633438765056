import { Alert, AlertTitle, Box, Button, TextField, Typography } from 'MaterialUIComponents';
import { DeviceBatchError, DeviceBatchResponse } from '$Generated/api';
import { DeviceManagementService, IDeviceManagementServiceInjectedProps } from '$State/DeviceManagementFreezerService';
import React = require('react');
import { useEffect, useState } from 'react';
import * as XLSX from 'xlsx';

const styles = require('./DeviceBulk.scss') as {
    formContainer: string;
    innerBox: string;
    labelBox: string;
    textField: string;
    fileUploadBox: string;
    button: string;
};

interface IJsonData {
    [key: string]: string;
}

interface IDeviceBulkErrorItem {
    msg: string;
    uniqueId: string;
}

export interface IBatchDeviceBaseResponse {
    data: IDeviceBulkErrorItem[] | null;
    success: boolean;
    code: number;
    message: string | null;
}

class NoDataError extends Error {
    constructor() {
        super('No data in file');
        this.name = 'NoDataError';
    }
}

class UnsupportedFileTypeError extends Error {
    constructor() {
        super('Unsupported file type, it has to be either an Excel file (.xslx, .xls) or a CSV file (.csv)');
        this.name = 'UnsupportedFileTypeError';
    }
}

type DeviceBulkProps = IDeviceManagementServiceInjectedProps;

const DeviceBulkPage = (props: DeviceBulkProps) => {
    const [selectedFile, setSelectedFile] = useState<File | undefined>();
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [devicesNotAdded, setDevicesNotAdded] = useState<DeviceBatchError[]>([]);
    const [successMessage, setSuccessMessage] = useState<string>('');
    const [successfulOperation, setSuccessfulOperation] = useState<boolean>(false);
    const [attributeName, setAttributeName] = useState<string>('Serial Num');

    useEffect(() => {
        setSuccessMessage('');
        setErrorMessage('');
        setDevicesNotAdded([]);
        setSuccessfulOperation(false);
    }, [selectedFile, attributeName]);

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedFile(event.target.files?.[0]);
    };

    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault();
        try {
            if (selectedFile && attributeName) {
                const fileType = selectedFile.name.split('.').pop();
                const processingFunction = getProcessingFunction(fileType);
                const json = await processingFunction(selectedFile);
                const values = getValuesFromJson(json);
                const attributeValues = values[attributeName];
                if (!attributeValues) {
                    throw new Error(`Invalid column name: ${attributeName}`);
                }

                DeviceManagementService.postBatchDevices(attributeValues).then(handleSuccess).catch(handleError);
            } else {
                throw new Error('No file selected or attribute name not set');
            }
        } catch (error) {
            console.debug('Error on Device Bulk: ', error);
            setErrorMessage(`${(error as Error).message}`);
        }
    };

    const handleSuccess = (response: void | DeviceBatchResponse) => {
        if (response) {
            setSuccessfulOperation(true);
            if (response.addedDevices !== response.processedDevices && response.addedDevices !== 0) {
                setSuccessMessage(`A total of ${response.addedDevices} of ${response.processedDevices} devices were added successfully`);
            }

            if (response.errors && response.errors.length > 0) {
                setDevicesNotAdded(response.errors);
            }
        }
    };

    const handleError = (error: any) => {
        setErrorMessage(`An error ocurred: ${(error as Error).message}`);
    };

    const processFile =
        (readFunction: (file: File, reader: FileReader) => void, toJson: (data: string) => IJsonData[]) =>
        (file: File): Promise<IJsonData[]> => {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onload = (event: ProgressEvent<FileReader>) => {
                    const data = event.target?.result;
                    if (!data) {
                        reject(new NoDataError());
                        return;
                    }
                    resolve(toJson(data.toString()));
                };
                readFunction(file, reader);
            });
        };

    const processExcelFile = processFile(
        (file: File, reader: FileReader) => reader.readAsBinaryString(file),
        (binaryString: string) => {
            const workbook = XLSX.read(binaryString, { type: 'binary' });
            const firstSheetName = workbook.SheetNames[0];
            const firstSheet = workbook.Sheets[firstSheetName];
            const csvData = XLSX.utils.sheet_to_csv(firstSheet);
            return csvToJson(csvData);
        },
    );

    const getProcessingFunction = (fileType: string | undefined): ((file: File) => Promise<IJsonData[]>) => {
        if (fileType === 'xlsx' || fileType === 'xls') {
            return processExcelFile;
        } else if (fileType === 'csv') {
            return processCsvFile;
        } else {
            throw new UnsupportedFileTypeError();
        }
    };

    const csvToJson = (csv: string): IJsonData[] => {
        const lines = csv.split('\n');
        const result: IJsonData[] = [];
        const headers = lines[0].split(',');

        for (let i = 1; i < lines.length; i++) {
            const obj: IJsonData = {};
            const currentLine = lines[i].split(',');

            for (let j = 0; j < headers.length; j++) {
                obj[headers[j]] = currentLine[j];
            }
            result.push(obj);
        }

        return result;
    };

    const processCsvFile = processFile((file: File, reader: FileReader) => reader.readAsText(file), csvToJson);

    const getValuesFromJson = (json: IJsonData[]): { [key: string]: string[] } => {
        const result: { [key: string]: string[] } = {};
        json.forEach((row) => {
            Object.keys(row).forEach((key) => {
                if (row[key] && row[key].trim() !== '') {
                    if (!result[key]) {
                        result[key] = [];
                    }
                    result[key].push(row[key]);
                }
            });
        });
        return result;
    };

    return (
        <Box component="form" onSubmit={handleSubmit} noValidate className={styles.formContainer}>
            <Typography variant="body1">
                This page allows you to upload either an Excel (.xlsx, .xls) or a CSV file to bulk add devices to the Streamax Server. The
                file should contain a column with the device&apos;s serial number.
            </Typography>

            <Box className={styles.innerBox}>
                <Box className={styles.labelBox}>
                    <label>Enter the serial number&apos;s column name (Case Sensitive):</label>
                    <TextField
                        id="attributeName"
                        value={attributeName}
                        onChange={(event) => setAttributeName(event.target.value)}
                        className={styles.textField}
                        placeholder='e.g. "Serial Number" or "Serial Num"'
                    />
                </Box>

                <Box className={styles.fileUploadBox}>
                    <Button className={styles.button} variant="contained" component="label">
                        Upload File
                        <input type="file" hidden onChange={handleFileChange} />
                    </Button>

                    {selectedFile && <Typography variant="body1">Selected file: {selectedFile?.name}</Typography>}
                </Box>
            </Box>

            <Button type="submit" variant="contained" className={styles.button}>
                Submit
            </Button>

            {successfulOperation && (
                <Alert
                    severity="success"
                    sx={{
                        borderRadius: '16px',
                        width: 'fit-content',
                        margin: '1rem auto',
                    }}
                >
                    <AlertTitle>The devices were processed successfully</AlertTitle>
                    {successMessage}
                </Alert>
            )}

            {devicesNotAdded.length > 0 && (
                <Alert
                    severity="warning"
                    sx={{
                        borderRadius: '16px',
                        width: 'fit-content',
                        margin: '1rem auto',
                    }}
                >
                    <AlertTitle>The following devices were not added to Striker: </AlertTitle>
                    <ul>
                        {devicesNotAdded.map((device) => (
                            <li key={device.uniqueId}>
                                {device.uniqueId}: {device.msg}
                            </li>
                        ))}
                    </ul>
                </Alert>
            )}

            {errorMessage && (
                <Alert
                    severity="error"
                    sx={{
                        borderRadius: '16px',
                        width: 'fit-content',
                        margin: '1rem auto',
                    }}
                >
                    <AlertTitle>An error ocurred:</AlertTitle>
                    {errorMessage}
                </Alert>
            )}
        </Box>
    );
};

export default DeviceManagementService.inject(DeviceBulkPage);
