import { React, moment, bind } from '../../Imports';
import {
    Card,
    Table,
    TableBody,
    TableRow,
    TableCell,
    Button,
    Dialog,
    DialogTitle,
    DialogActions
} from '../../MaterialUIComponents';
import { Edit } from '../../MaterialUIIcons';
import * as DateFormatter from '../Shared/DateFormatter';
import { IDeviceManagementServiceInjectedProps, DeviceManagementService } from '$State/DeviceManagementFreezerService';

const styles = require('./DeviceCard.scss') as {
    card: string;
    menuHeader: string;
    headerIcon: string;
    button: string;
    buttonRow: string;
};

interface IDeviceCardBaseProps {
    cameraId: number | undefined;
}

type PopupType = "suspend" | "activate" | "reboot" | "takePicture" | undefined;

interface IDeviceCardState {
    popupType: PopupType;
    deviceStatus: string;
}

type IDeviceCardProps = IDeviceCardBaseProps & IDeviceManagementServiceInjectedProps

export class _DeviceCard extends React.PureComponent<IDeviceCardProps, IDeviceCardState> {
    state: IDeviceCardState = {
        popupType: undefined,
        deviceStatus: "",
    };

    componentDidMount() {
        this.props.deviceManagement.on("update", (currentState) => {
            const { DeviceDetailsResult } = currentState;
            const camera = DeviceDetailsResult.data ? DeviceDetailsResult.data : {};
            const deviceStatus: any = camera.deviceStatus;

            if (deviceStatus !== this.state.deviceStatus) {
                this.setState({ deviceStatus });
            }
        })
    }

    @bind
    getPopupTitle(): string {
        const { popupType } = this.state;
        switch (popupType) {
            case "takePicture":
                return "Take Picture with Camera?"
            case "suspend":
                return "Suspend Camera?"
            case "reboot":
                return "Reboot Camera?"
            case "activate":
                return "Activate Camera?"
            default:
                return "";
        }
    }

    @bind
    continuePopup() {
        const { popupType } = this.state;
        switch (popupType) {
            case "takePicture":
                this.takePicture();
                break;
            case "suspend":
                this.suspend();
                break;
            case "reboot":
                this.reboot();
                break;
            case "activate":
                this.activate();
                break;
            default:
                break;
        }

        this.togglePopup();
    }

    @bind
    async suspend() {
        await this.props.deviceManagement.suspendCamera(this.props.cameraId);
        this.props.deviceManagement.getDevice(this.props.cameraId || 0);
    }

    @bind
    async activate() {
        await this.props.deviceManagement.activateCamera(this.props.cameraId);
        this.props.deviceManagement.getDevice(this.props.cameraId || 0);
    }

    @bind
    reboot() {

    }

    @bind
    takePicture() {

    }

    @bind
    toggleSuspendPopup() {
        this.togglePopup("suspend");
    }

    @bind
    toggleActivatePopup() {
        this.togglePopup("activate");
    }

    @bind
    toggleRebootPopup() {
        this.togglePopup("reboot");
    }

    @bind
    toggleTakePicturePopup() {
        this.togglePopup("takePicture");
    }

    @bind
    togglePopup(which: PopupType = undefined) {
        let { popupType } = this.state;

        if (popupType === undefined) {
            popupType = which;
        } else {
            popupType = undefined;
        }

        this.setState({ popupType });
    }

    @bind
    cancelPopup() {
        this.togglePopup();
    }

    render(): JSX.Element {
        // get the device from the freezer instead of the input prop so we can get updates to it's status 
        const freezer = this.props.deviceManagement.getState();
        const { DeviceDetailsResult } = freezer;
        const camera = DeviceDetailsResult.data ? DeviceDetailsResult.data : {};
        const { deviceStatus } = this.state;

        return (
            <Card className={styles.card}>
                <div className={styles.menuHeader}>
                    Camera Device
                    <Edit className={styles.headerIcon} />
                </div>
                <Table>
                    <TableBody>
                        <TableRow key={'heartbeat'}>
                            <TableCell>Last Heartbeat:</TableCell>
                            <TableCell>{DateFormatter.dateAndTimezone(moment(camera?.lastHeartbeatTime) || moment())}</TableCell>
                        </TableRow>
                        <TableRow key={'status'}>
                            <TableCell>Status:</TableCell>
                            <TableCell>{deviceStatus}</TableCell>
                        </TableRow>
                        <TableRow key={'imei'}>
                            <TableCell>IMEI #:</TableCell>
                            <TableCell>{camera?.imei}</TableCell>
                        </TableRow>
                        <TableRow key={'serial'}>
                            <TableCell>Serial #:</TableCell>
                            <TableCell>{camera?.serialNumber}</TableCell>
                        </TableRow>
                        <TableRow key={'iccid'}>
                            <TableCell>ICCID #:</TableCell>
                            <TableCell>{camera?.iccid}</TableCell>
                        </TableRow>
                        <TableRow key={'type'}>
                            <TableCell>Device Type:</TableCell>
                            <TableCell>{camera?.deviceType}</TableCell>
                        </TableRow>
                        <TableRow key={'start'}>
                            <TableCell>Start Date:</TableCell>
                            <TableCell>
                                {DateFormatter.dateAndTimezone(
                                    moment(
                                        camera?.currentPairing
                                            ? camera?.currentPairing.startDate
                                            : '',
                                    ),
                                )}
                            </TableCell>
                        </TableRow>
                        <TableRow key={'firmware'}>
                            <TableCell>Device Firmware:</TableCell>
                            <TableCell>{'xxxx'}</TableCell>
                        </TableRow>
                        <TableRow key={'configVersion'}>
                            <TableCell>Device config version:</TableCell>
                            <TableCell>{'xxxx'}</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
                <div className={styles.buttonRow}>
                    {deviceStatus === "Active" &&
                        <Button className={styles.button} onClick={this.toggleSuspendPopup}>Suspend</Button>
                    }
                    {deviceStatus === "Suspended" &&
                        <Button className={styles.button} onClick={this.toggleActivatePopup}>Activate</Button>
                    }
                    <Button className={styles.button} onClick={this.toggleRebootPopup}>Reboot</Button>
                    <Button className={styles.button} onClick={this.toggleTakePicturePopup}>Take Picture</Button>
                </div>
                <Dialog open={this.state.popupType !== undefined} >
                    <DialogTitle>{this.getPopupTitle()}</DialogTitle>
                    <DialogActions>
                        <Button onClick={this.continuePopup}>Continue</Button>
                        <Button onClick={this.cancelPopup}>Cancel</Button>
                    </DialogActions>
                </Dialog>
            </Card>
        );
    }
}

export const DeviceCard = DeviceManagementService.inject(_DeviceCard);