import { _, FreezerService, managedAjaxUtil, IAjaxState } from 'Imports';
import { IdentityApiFactory, LoginUserRequest } from '$Generated/api';

const InjectedPropName = 'forgotPassword';

interface IForgotPasswordState {
    setPasswordResult: IAjaxState<any>;
    passwordResetResult: IAjaxState<any>;
    hasError: boolean;
}

class ForgotPasswordFreezerService extends FreezerService<IForgotPasswordState, typeof InjectedPropName> {
    constructor() {
        super(
            {
                passwordResetResult: managedAjaxUtil.createInitialState(),
                hasError: false,
                setPasswordResult: managedAjaxUtil.createInitialState(),
            },
            InjectedPropName,
        );
    }

    public async sendPasswordResetLink(username: string): Promise<void | any> {
        return managedAjaxUtil.fetchResults({
            ajaxStateProperty: 'passwordResetResult',
            freezer: this.freezer,
            onExecute: (apiOptions, params, options) => {
                if (params === undefined) {
                    throw new Error('Parameters is undefined');
                }

                const identityApi = IdentityApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return identityApi.apiV1ForgetPasswordPost(params);
            },
            params: {
                body: username,
            },
            onOk: (data: any) => {
                if (data !== undefined) {
                    this.freezer.get().set({ hasError: false });

                    if (data.status === 200) {
                    } else {
                    }
                }

                return data;
            },
            onError: () => {
                this.freezer.get().set({ hasError: true });
            },
        });
    }

    public async setPassword(userObj: LoginUserRequest): Promise<void | any> {
        return managedAjaxUtil.fetchResults({
            ajaxStateProperty: 'setPasswordResult',
            freezer: this.freezer,
            onExecute: (apiOptions, params, options) => {
                if (params === undefined) {
                    throw new Error('Parameters is undefined');
                }

                const identityApi = IdentityApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return identityApi.apiV1ResetPasswordPost(params);
            },
            params: {
                body: userObj,
            },
            onOk: (data: any) => {
                if (data !== undefined) {
                    this.freezer.get().set({ hasError: false });

                    if (data.status !== 200) {
                        this.freezer.get().set({ hasError: true });
                    }
                }

                return data;
            },
            onError: () => {
                this.freezer.get().set({ hasError: true });
            },
        });
    }
}

export const ForgotPasswordService = new ForgotPasswordFreezerService();
export type IForgotPasswordServiceInjectedProps = ReturnType<ForgotPasswordFreezerService['getPropsForInjection']>;
