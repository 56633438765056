import { FreezerService, managedAjaxUtil, IAjaxState, _ } from '../Imports';
import {
    LookupResponse, DeviceManagementApiFactory
} from '$Generated/api';

const InjectedPropName = 'cameraTypeService';

interface ICameraTypeState {
    CameraTypeResults: IAjaxState<LookupResponse[]>;
}

class CameraTypeFreezerService extends FreezerService<ICameraTypeState, typeof InjectedPropName> {
    
    constructor() {
        super(
            {
                CameraTypeResults: managedAjaxUtil.createInitialState(),
            },
            InjectedPropName,
        );
    }

    public async getCameraTypes(): Promise<void | LookupResponse[]> {
        return managedAjaxUtil.fetchResults({
            ajaxStateProperty: 'CameraTypeResults',
            freezer: this.freezer,
            params: {},
            onExecute: (apiOptions, params) => {
                if (params === undefined) {
                    throw new Error('Parameters is undefined');
                }

                const deviceManagementApi = DeviceManagementApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return deviceManagementApi.apiV1CameratypesGet();
            },
            onOk: (data: LookupResponse[]) => {
                return data;
            },
            onError: () => {
                //NOOP
            },
        });
    }
}

export const CameraTypeService = new CameraTypeFreezerService();
export type ICameraTypeServiceInjectedProps = ReturnType<CameraTypeFreezerService['getPropsForInjection']>;