import { FreezerService, managedAjaxUtil, IAjaxState, _ } from '../Imports';
import { LookupResponse, FleetApiFactory } from '$Generated/api';

const InjectedPropName = 'telematicProviderService';

interface ITelematicProviderState {
    TelematicProviderResults: IAjaxState<LookupResponse[]>;
}

class TelematicProviderFreezerService extends FreezerService<ITelematicProviderState, typeof InjectedPropName> {
    
    constructor() {
        super(
            {
                TelematicProviderResults: managedAjaxUtil.createInitialState(),
            },
            InjectedPropName,
        );
    }

    public async getAllTelematicProviders(): Promise<void | LookupResponse[]> {
        return managedAjaxUtil.fetchResults({
            ajaxStateProperty: 'TelematicProviderResults',
            freezer: this.freezer,
            params: {},
            onExecute: (apiOptions, params) => {
                if (params === undefined) {
                    throw new Error('Parameters is undefined');
                }

                const fleetApi = FleetApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return fleetApi.apiV1TelematicprovidersGet();
            },
            onOk: (data: LookupResponse[]) => {
                return data;
            },
            onError: () => {
                //NOOP
            },
        });
    }
}

export const TelematicProviderService = new TelematicProviderFreezerService();
export type ITelematicProviderServiceInjectedProps = ReturnType<TelematicProviderFreezerService['getPropsForInjection']>;