/* eslint-disable prefer-const */
import { React, _ } from '../../Imports';
import { Button } from '../../MaterialUIComponents';

const styles = require('./FilterBar.scss') as {
    main: string;
};

interface IFilterBarProps {
    onCreateUser: () => void;
}

class _FilterBar extends React.Component<IFilterBarProps, {}> {
    render(): JSX.Element {
        return (
            <div className={styles.main}>
                {/*Create Button */}
                <Button onClick={(): void => this.props.onCreateUser()}>Create</Button>
            </div>
        );
    }
}

export const FilterBar = _FilterBar;
