import { FreezerService, managedAjaxUtil, IAjaxState } from '../Imports';
import { IdentityApiFactory, AdminUserResponse, CreateUserRequest, UpdateUserRequest } from '$Generated/api';

const InjectedPropName = 'userManagement';

export interface ILoginState {
    getUserResult: IAjaxState<AdminUserResponse[]>;
    createUserResult: IAjaxState<any>;
    editUserResult: IAjaxState<any>;
    deleteUserResult: IAjaxState<any>;
    hasError: boolean;
    errorString: string;
}

class UserManagementFreezerService extends FreezerService<ILoginState, typeof InjectedPropName> {
    constructor() {
        super(
            {
                getUserResult: managedAjaxUtil.createInitialState(),
                createUserResult: managedAjaxUtil.createInitialState(),
                editUserResult: managedAjaxUtil.createInitialState(),
                deleteUserResult: managedAjaxUtil.createInitialState(),
                hasError: false,
                errorString: '',
            },
            InjectedPropName,
        );
    }

    public async getUsers(): Promise<void | AdminUserResponse[]> {
        return managedAjaxUtil.fetchResults({
            ajaxStateProperty: 'getUserResult',
            freezer: this.freezer,
            onExecute: (apiOptions, params) => {
                if (params === undefined) {
                    throw new Error('Parameters is undefined');
                }

                const identityApi = IdentityApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return identityApi.apiV1GetUsersGet(params);
            },
            params: {
                body: null,
            },
            onOk: (data: AdminUserResponse[]) => {
                if (data !== undefined) {
                    this.freezer.get().set({ hasError: false });
                }
                return data;
            },
            onError: () => {
                this.freezer.get().set({ hasError: true });
            },
        });
    }

    public async createUser(userToCreate: CreateUserRequest): Promise<any> {
        return managedAjaxUtil.fetchResults({
            ajaxStateProperty: 'createUserResult',
            freezer: this.freezer,
            onExecute: (apiOptions, params) => {
                if (params === undefined) {
                    throw new Error('Parameters is undefined');
                }

                const identityApi = IdentityApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return identityApi.apiV1AddUserPost(params);
            },
            params: {
                body: userToCreate,
            },
            onOk: (data: AdminUserResponse[]) => {
                if (data !== undefined) {
                    this.freezer.get().set({ hasError: false });
                }
                return data;
            },
            onError: (err: managedAjaxUtil.IErrorMessage<any>, errorString: string) => {
                this.freezer.get().set({ hasError: true, errorString: errorString });
            },
        });
    }

    public async editUser(editUser: UpdateUserRequest): Promise<any> {
        return managedAjaxUtil.fetchResults({
            ajaxStateProperty: 'editUserResult',
            freezer: this.freezer,
            onExecute: (apiOptions, params) => {
                if (params === undefined) {
                    throw new Error('Parameters is undefined');
                }

                const identityApi = IdentityApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return identityApi.apiV1UpdateUserPost(params);
            },
            params: {
                body: editUser,
            },
            onOk: (data: AdminUserResponse[]) => {
                if (data !== undefined) {
                    this.freezer.get().set({ hasError: false });
                }
                return data;
            },
            onError: () => {
                this.freezer.get().set({ hasError: true });
            },
        });
    }

    public async deleteUser(deleteUser: AdminUserResponse): Promise<any> {
        return managedAjaxUtil.fetchResults({
            ajaxStateProperty: 'deleteUserResult',
            freezer: this.freezer,
            onExecute: (apiOptions, params) => {
                if (params === undefined) {
                    throw new Error('Parameters is undefined');
                }

                const identityApi = IdentityApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return identityApi.apiV1DeleteUserPost(params);
            },
            params: {
                body: deleteUser,
            },
            onOk: (data: AdminUserResponse[]) => {
                if (data !== undefined) {
                    this.freezer.get().set({ hasError: false });
                }
                return data;
            },
            onError: () => {
                this.freezer.get().set({ hasError: true });
            },
        });
    }
}

export const UserManagementService = new UserManagementFreezerService();
export type IUserManagementServiceInjectedProps = ReturnType<UserManagementFreezerService['getPropsForInjection']>;
